import React from "react";
import OortClou2 from "../../Images/PlutoImg1.jpg";
import OortClou3 from "../../Images/PlutoImg2.jpg";
import OortClou4 from "../../Images/PlutoImg3.jpg";

const PlutoOverview = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Pluto Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Pluto is a dwarf planet in the Kuiper belt, a ring of bodies
                  beyond the orbit of Neptune.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Pluto is the ninth-largest and tenth-most-massive known object
                  to directly orbit the Sun. It is the largest known
                  trans-Neptunian object by volume, by a small margin, but is
                  slightly less massive than Eris (another Dwarf planet).
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Like other Kuiper belt objects, Pluto is made primarily of ice
                  and rock and is much smaller than the inner planets. Pluto has
                  only one sixth the mass of Earth's moon, and one third its
                  volume. Pluto was recognized as a planet until 2006.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Pluto has a moderately eccentric and inclined orbit, ranging
                  from 30 to 49 astronomical units (4.5 to 7.3 billion
                  kilometers; 2.8 to 4.6 billion miles) from the Sun. Light from
                  the Sun takes 5.5 hours to reach Pluto at its orbital distance
                  of 39.5 AU (5.91 billion km; 3.67 billion mi). Pluto's
                  eccentric orbit periodically brings it closer to the Sun than
                  Neptune, but a stable orbital resonance prevents them from
                  colliding.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Pluto has five known moons: Charon, the largest, whose
                  diameter is just over half that of Pluto, Styx, Nix, Kerberos,
                  and Hydra. Pluto and Charon are sometimes considered a binary
                  system because the barycenter of their orbits does not lie
                  within either body, and they are tidally locked.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Pluto was discovered in 1930 by Clyde W. Tombaugh, the first
                  object in the Kuiper belt. It was immediately hailed as the
                  ninth planet, but it was always the odd object out and its
                  planetary status was questioned when it was found to be much
                  smaller than expected. These doubts increased following the
                  discovery of additional objects in the Kuiper belt starting in
                  the 1990s, and
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  particularly the more massive scattered disk object Eris in
                  2005. In 2006, the International Astronomical Union (IAU)
                  formally redefined the term planet to exclude dwarf planets
                  such as Pluto.
                </span>
              </div>
              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Pluto Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  Pluto is the name of the Roman god of the Underworld,
                  equivalent to the Greek Hades. However, the Greek name
                  "Plouton" (from which the Romans derived their name "Pluto")
                  was also occasionally used as an alternative name for Hades.
                  But Pluto is definitely the Roman spelling.
                </span>
                <span className="asteroids-main-span">
                  The name 'Pluto' was mythologically appropriate. The god Pluto
                  was one of six surviving children of Saturn, and the others
                  had already all been chosen as names of major or minor planets
                  (his brothers Jupiter and Neptune, and his sisters Ceres, Juno
                  and Vesta). Both the god and the planet inhabited "gloomy"
                  regions, and the god was able to make himself invisible, as
                  the planet had been for so long…
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              With a radius of 715 miles (1,151 kilometers), Pluto is about 1/6
              the width of Earth.
            </span>
            <div className="mt-4">
              <span className="asteroids-main-span">
                From an average distance of 3.7 billion miles (5.9 billion
                kilometers), Pluto is 39 astronomical units away from the Sun
                (One astronomical unit is the distance from the Sun to Earth).
                From this distance, it takes sunlight 5.5 hours to travel from
                the Sun to Pluto.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlutoOverview;
