import React, { useEffect, useRef, useState } from "react";
import "../NavBar/Navbar.css";
import Hamburger from "hamburger-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  let menuref = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
    };
    // document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  });
  const Navigate = useNavigate();
  return (
    <>
      <section className="nave-section">
        <div className="ham-burgur-nav">
          <Hamburger toggle={() => setIsOpen(!isOpen)} toggled={isOpen} />
        </div>
        <div className="nave-div-main" ref={menuref}>
          <div className={isOpen ? "" : "nav-bar-2-span"}>
            <div className="drop-dawon-nav">
              <div class="dropdown">
                <button
                  onClick={() => Navigate("/SolarSystemExploration")}
                  class="dropbtn"
                >
                  Our Solar System{" "}
                </button>
                <div class="dropdown-content">
                  <Link to="/" href="#">
                    Home
                  </Link>
                  <Link to="/SunSystem" href="#">
                    {" "}
                    The Sun
                  </Link>
                  <Link to="/KuiperBeltSystem" href="#">
                    Kuiper Belt
                  </Link>
                  <Link to="/OortCloud" href="#">
                    Oort Cloud
                  </Link>
                </div>
              </div>
              <div class="dropdown">
                <button class="dropbtn">Planets</button>
                <div class="dropdown-content">
                  <Link to="/" href="#">
                    Home
                  </Link>
                  <Link to="/planets" href="#">
                    About Planets
                  </Link>
                  <Link to="/Mercury" href="#">
                    Mercury
                  </Link>
                  <Link to="/Venus" href="#">
                    Venus
                  </Link>
                  <Link to="/Earth" href="#">
                    Earth
                  </Link>
                  <Link to="/Mars" href="#">
                    Mars
                  </Link>
                  <Link to="/Jupiter" href="#">
                    Jupiter
                  </Link>
                  <Link to="/Saturn" href="#">
                    Saturn
                  </Link>
                  <Link to="/Uranus" href="#">
                    Uranus
                  </Link>
                  <Link to="/Neptune" href="#">
                    Neptune
                  </Link>
                  <Link to="/Pluto" href="#">
                    Pluto
                  </Link>
                  <Link to="/DwarfPlanets" href="#">
                    {" "}
                    DwarfPlanets
                  </Link>
                </div>
              </div>
              <div class="dropdown">
                <button class="dropbtn">Moons</button>
                <div class="dropdown-content">
                  <Link to="/" href="#">
                    Home
                  </Link>
                  <Link to="/MoonsOfThaSolarSystum" href="#">
                    About Moons
                  </Link>
                  <Link to="/EarthMoon" href="#">
                    Earth Moon
                  </Link>
                  <Link to="/MersMoon" href="#">
                    Mers Moon{" "}
                  </Link>
                  <Link to="/JupiterMoon" href="#">
                    {" "}
                    Jupiter Moon
                  </Link>
                  <Link to="/SaturnMoon" href="#">
                    Saturn Moon
                  </Link>
                  <Link to="/UranusMoon" href="#">
                    Uranus Moon
                  </Link>
                  <Link to="/NeptuneMoon" href="#">
                    Neptune Moon
                  </Link>
                  <Link to="/PlutoMoon" href="#">
                    Pluto Moon
                  </Link>
                </div>
              </div>
              <div class="dropdown">
                <button class="dropbtn">Asteroids & Comets</button>
                <div class="dropdown-content">
                  <Link to="/" href="#">
                    Home
                  </Link>
                  <Link to="/Asteroids" href="#">
                    Asteroids, Comets and Meteors{" "}
                  </Link>
                  <Link to="/AsteroidsMain" href="#">
                    Asteroids
                  </Link>
                  <Link to="/Comets" href="#">
                    Comets
                  </Link>
                  <Link to="/MeteorsandMeteorites" href="#">
                    Meteors and Meteorites
                  </Link>
                </div>
              </div>
              <div class="dropdown">
                <button class="dropbtn">Universe</button>
                <div class="dropdown-content">
                  <Link to="/" href="#">
                    Home
                  </Link>
                  <Link to="/WhatisUniverse" href="#">
                    What is Universe
                  </Link>
                  <Link to="/NormalMatter" href="#">
                    Normal Matter
                  </Link>
                  <Link to="/DarkMatterandDarkEnergy" href="#">
                    Dark Matter and Dark Energy
                  </Link>
                  <Link to="/BlackHoles" href="#">
                    Black Holes
                  </Link>
                  <Link to="/Wormholes" href="#">
                    Worm Holes
                  </Link>
                  <Link to="/Galaxies" href="#">
                    Galaxies
                  </Link>
                  <Link to="/Starlight" href="#">
                    Star
                  </Link>
                  <Link to="/Exoplanet" href="#">
                    Exoplanet
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
