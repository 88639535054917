import React, { useState, useEffect } from "react";
import "../Css/LargestFive.css";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";

const LargestFive = () => {
  const [AirlineData, setAirlineData] = useState([]);
  console.log(AirlineData, "AirlineData");
  const countrydata = async () => {
    // setloading(true);

    try {
      let Flagyy = {
        url: "Largest_airlines_in_the_world_By_Scheduled_tonne",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons);
          setAirlineData(respons.data);
        });
    } catch (error) {
      console.log("userap...", error);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);
  return (
    <>
      <section className="List-of-largest-airlines-main-sec">
        <div className="List-of-largest-airlines-main-div ">
          <div className="List-of-largest-tha-Thard-div">
            <span className="busiest-btn-one">
              List Of Largest Airlines In Africa
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="Five-and-sund-data"
            >
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">Airline</span>
              </div>
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">Country</span>
              </div>
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">2021</span>
              </div>
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">2020</span>
              </div>
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">2018</span>
              </div>
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">2017</span>
              </div>
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">2016</span>
              </div>
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">2015</span>
              </div>
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">2014</span>
              </div>
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">2013</span>
              </div>
              <div className="airlines-main-largest-1">
                <span className="airport-all-span">2012</span>
              </div>
            </div>
            {AirlineData?.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="Five-and-sund-data"
                >
                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">{itm.airline}</span>
                  </div>
                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">{itm.country}</span>
                  </div>
                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">
                      {itm?.passengers[0]?.passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">
                      {itm?.passengers[1]?.passanger}
                    </span>
                  </div>

                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">
                      {itm?.passengers[2]?.passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">
                      {itm?.passengers[3]?.passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">
                      {itm?.passengers[4]?.passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">
                      {itm?.passengers[5]?.passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">
                      {itm?.passengers[6]?.passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">
                      {itm?.passengers[7]?.passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-1">
                    <span className="main-airpot-span">
                      {itm?.passengers[8]?.passanger}
                    </span>
                  </div>
                </div>
              );
            })}
            {/* {ContryDataSec ? (
              <>
                {sortedData.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="List-of-highest-mountain"
                  >
                    <div className="mountains-name-logoc-img-1">
                      <span className="main-airpot-span">{item.country}</span>
                    </div>
                    <div className="mountains-name-logoc-img-2">
                      <span className="main-airpot-span">
                        {item.mountain_name}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-3">
                      <span className="main-airpot-span">{item.range}</span>
                    </div>
                    <div className="mountains-name-logoc-img-4">
                      <span className="main-airpot-span">
                        {item.height_meter}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-5">
                      <span className="main-airpot-span">
                        {item.prominence_feet}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-6">
                      <span className="main-airpot-span">
                        {item.prominence_meter}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-7">
                      <span className="main-airpot-span">
                        {item.parent_mountain}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-8">
                      <span className="main-airpot-span">
                        {item.height_feet}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {ContryData.map((item) => (
                  <div
                    key={item.airport}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="List-of-highest-mountain "
                  >
                    <div className="mountains-name-logoc-img-1">
                      <span className="main-airpot-span">{item.country}</span>
                    </div>
                    <div className="mountains-name-logoc-img-2">
                      <span className="main-airpot-span">
                        {item.mountain_name}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-3">
                      <span className="main-airpot-span">{item.range}</span>
                    </div>
                    <div className="mountains-name-logoc-img-4">
                      <span className="main-airpot-span">
                        {item.height_meter}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-5">
                      <span className="main-airpot-span">
                        {item.prominence_feet}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-6">
                      <span className="main-airpot-span">
                        {item.prominence_meter}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-7">
                      <span className="main-airpot-span">
                        {item.parent_mountain}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-8">
                      <span className="main-airpot-span">
                        {item.height_feet}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )} */}
          </div>
        </div>
      </section>
    </>
  );
};

export default LargestFive;
