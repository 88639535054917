import React from "react";
import planet from "../../../Images/Mersmoonimg.jpg";
import sedo from "../../../Images/dark_layer@3x.png";
import MersMoonOverView from "../MersMoonOverView/MersMoonOverView";
import Navbar from "../../../NavBar/Navbar";
const MersMoon = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Ceres-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Moon Of Mars</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MersMoonOverView />
    </>
  );
};

export default MersMoon;
