import React, { useState, useEffect } from "react";
import "../Css/ListoflargestairlinesinNorthAmerica.css";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import Home from "./Home";
import Tweet from "../Images/Tweetlogo.png";
import back from "../Images/back_arrow.png";
import Like from "../Images/Likelogo.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ListoflargestairlinesinNorthAmerica = () => {
  const [AirlineData, setAirlineData] = useState([]);

  const [Frequency, setFrequency] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Fleet, setFleet] = useState([]);
  const [Passangers, setPassanger] = useState([]);

  console.log(Passangers, "Passangers");
  const countrydata = async () => {
    setLoading(true);

    try {
      let Flagyy = {
        url: "get_List_of_largest_airlines_in_North_America",

        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons);
          setAirlineData(respons.data.countries_by_destination);
          setFrequency(respons.data.countries_by_frequency);
          setFleet(respons.data.fleet);
          setPassanger(respons.data.passanger);
          setLoading(false);
        });
    } catch (error) {
      console.log("userap...", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}
          <div>
            <span className="Google-span-ha-Views">
              {" "}
              Countries by Destination
            </span>
          </div>
          <div className="list-savane-conrty-div">
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/ListoflargestairlinesinNorthAmerica")}
            >
              List of largest airlines in North America
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/Listoflargestairlinesineurope")}
            >
              List of largest airlines in Europe
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/ListoflargestairlinesinCentralAmerica")}
            >
              List of largest airlines in Central America and the Caribbean
            </span>

            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/Listoflargestairlinesinafrica")}
            >
              List of largest airlines in Africa
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/Listoflargestairlinesinasia")}
            >
              List of largest airlines in Asia{" "}
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/Listoflargestairlinesinsouthamerica")}
            >
              List of largest airlines in South America
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/Listoflargestairlinesinoceania")}
            >
              List of largest airlines in Oceania
            </span>
          </div>
        </div>
      </div>
      <section className="List-of-largest-airlines-main-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="List-of-largest-airlines-main-div ">
          <div className="List-of-largest-tha-Thard-div">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="Countries-all-Destination-data"
            >
              <div className="airlines-main-largest">
                <span className="airport-all-span">Airline</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">Fleet size</span>
              </div>
            </div>
            {AirlineData.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="Countries-all-Destination-data"
                >
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">{itm.Airline}</span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">{itm.fleet_size}</span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="List-of-largest-tha-Thard-div mt-5">
            <span className="busiest-btn-one">Countries by Frequency</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="Countries-all-Destination-data"
            >
              <div className="airlines-main-largest">
                <span className="airport-all-span">Airline</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span"> Daily Departures</span>
              </div>
            </div>
            {Frequency.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="Countries-all-Destination-data"
                >
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">{itm.Airline}</span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.daily_departures}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="List-of-largest-tha-Thard-div mt-5">
            <span className="busiest-btn-one">Fleet</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="Countries-all-Destination-data"
            >
              <div className="airlines-main-largest">
                <span className="airport-all-span">Airline</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">Fleet Size</span>
              </div>
            </div>
            {Fleet.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="Countries-all-Destination-data"
                >
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">{itm.Airline}</span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">{itm.fleet_size}</span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="Countries-thord-Countries-div mt-5">
            <span className="busiest-btn-one">
              Countries By Passengers Carried
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="passanger-all-passanger-data"
            >
              <div className="airlines-main-largest">
                <span className="airport-all-span">Rank</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">Airline</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">Fleet Size</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2022</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2021</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2020</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2019</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2018</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2017</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2016</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2015</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2014</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2013</span>
              </div>
              <div className="airlines-main-largest">
                <span className="airport-all-span">2012</span>
              </div>
            </div>
            {Passangers.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="passanger-all-passanger-data"
                >
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">{itm.rank}</span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">{itm.airline}</span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">{itm.country}</span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[0].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[1].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[2].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[3].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[4].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[5].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[6].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[7].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[8].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[9].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest">
                    <span className="main-airpot-span">
                      {itm.data[10].passanger}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default ListoflargestairlinesinNorthAmerica;
