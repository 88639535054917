import React from "react";
import OortClou2 from "../../Images/Jupiterradius1.jpg";
import OortClou3 from "../../Images/Jupiterradius2.jpg";
import OortClou4 from "../../Images/Jupiterradius3.avif";

const JupiterOverveiwe = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Jupiter Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Jupiter is the fifth planet from the Sun and the largest in
                  the Solar System. It is a gas giant with a mass more than two
                  and a half times that of all the other planets in the Solar
                  System combined, and slightly less than one one-thousandth the
                  mass of the Sun.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Jupiter orbits the Sun at a distance of 5.20 astronomical
                  units (778.5 Gm) with an orbital period of 11.86 years (4,333
                  Earth days) and it has the shortest day in the solar system.
                  One day on Jupiter takes only about 10 hours (the time it
                  takes for Jupiter to rotate or spin around once),
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Jupiter is the third brightest natural object in the Earth's
                  night sky after the Moon and Venus, and it has been observed
                  since prehistoric times.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Jupiter was the first planet to form, and its inward migration
                  during the primordial Solar System impacted much of the
                  formation history of the other planets.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Jupiter is primarily composed of hydrogen (90% by volume),
                  followed by helium, which makes up a quarter of its mass and a
                  tenth of its volume. The ongoing contraction of Jupiter's
                  interior generates more heat than the planet receives from the
                  Sun. Its internal structure is believed to comprise an outer
                  mantle of liquid metallic hydrogen, and a diffuse inner core
                  of denser material. Because of its rapid rotation rate of 1
                  rotation per 10 hours, Jupiter's shape is an oblate spheroid:
                  it has a slight but noticeable bulge around the equator. The
                  outer atmosphere is divided into a series of latitudinal
                  bands, with turbulence and storms along their interacting
                  boundaries. The most obvious result of this is the Great Red
                  Spot, a giant storm which has been observed since 1831 and
                  possibly earlier.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Jupiter is surrounded by a faint planetary ring system and has
                  a powerful magnetosphere, the second largest contiguous
                  structure in the Solar System (after the heliosphere).
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Moreover, Jupiter forms a system of 95 known moons and
                  probably many more, including the four large moons discovered
                  by Galileo Galilei in 1610: Io, Europa, Ganymede, and
                  Callisto. Ganymede, the largest of the four, is larger than
                  the planet Mercury. Callisto is the second largest; Io and
                  Europa are approximately the size of Earth's Moon.
                </span>
              </div>

              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Jupiter Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  Jupiter gets its name from both the ancient Greek and Roman
                  civilizations. As the biggest planet, Jupiter was named after
                  the chief god of the divine pantheon: Zeus to the Greeks and
                  Jupiter to the Romans.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              With a radius of 43,440.7 miles (69,911 kilometers), Jupiter is 11
              times wider than Earth.
            </span>
            <span className="asteroids-main-span">
              From an average distance of 484 million miles (778 million
              kilometers), Jupiter is 5.2 astronomical units away from the Sun
              (One astronomical unit is the distance from the Sun to Earth).
              From this distance, it takes sunlight 43 minutes to travel from
              the Sun to Jupiter.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default JupiterOverveiwe;
