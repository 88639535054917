import React from "react";
import OortClou2 from "../../Images/Ssk1.jpg";
import OortClou3 from "../../Images/ssk.jpg";
import OortClou4 from "../../Images/Saturnsrings2 .jpg";
import SaturnRings from "../../Images/SaturnRings.jpg";
import "../SaturnOverviewe/SaturnOverviewe.css";
const SaturnOverviewe = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Saturn Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Saturn is the sixth planet from the Sun and the second-largest
                  planet in our solar system. Like fellow gas giant Jupiter,
                  Saturn is a massive ball made mostly of hydrogen and helium.
                  Saturn is not the only planet to have rings, but none are as
                  spectacular or as complex as Saturn's. Saturn also has dozens
                  of moons.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Saturn has the second-shortest day in the solar system. One
                  day on Saturn takes only 10.7 hours (the time it takes for
                  Saturn to rotate or spin around once), and Saturn makes a
                  complete orbit around the Sun in about 29.4 Earth years
                  (10,756 Earth days).
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Saturn is a gas giant with an average radius of about
                  nine-and-a-half times that of Earth. It has only one-eighth
                  the average density of Earth, but is over 95 times more
                  massive.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Saturn's interior is thought to be composed of a rocky core,
                  surrounded by a deep layer of metallic hydrogen, an
                  intermediate layer of liquid hydrogen and liquid helium, and
                  finally, a gaseous outer layer.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Saturn has a pale-yellow hue due to ammonia crystals in its
                  upper atmosphere.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  An electrical current within the metallic hydrogen layer is
                  thought to give rise to Saturn's planetary magnetic field,
                  which is weaker than Earth's, but which has a magnetic moment
                  580 times that of Earth due to Saturn's larger size.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Saturn's magnetic field strength is around one-twentieth of
                  Jupiter's. The outer atmosphere is generally bland and lacking
                  in contrast, although long-lived features can appear. Wind
                  speeds on Saturn can reach 1,800 kilometres per hour (1,100
                  miles per hour).
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The planet has a prominent ring system, which is composed
                  mainly of ice particles, with a smaller amount of rocky debris
                  and dust. At least 146 moons are known to orbit the planet, of
                  which 63 are officially named. This does not include the
                  hundreds of moonlets in its rings.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Titan, Saturn's largest moon and the second largest in the
                  Solar System, is larger (while less massive) than the planet
                  Mercury and is the only moon in the Solar System to have a
                  substantial atmosphere.
                </span>
              </div>

              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Saturn Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  The planet is named for the Roman god of agriculture and
                  wealth, who was also the father of Jupiter.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              With a radius of 36,183.7 miles (58,232 kilometers), Saturn is 9
              times wider than Earth.
            </span>
            <div className="mt-4">
              <span className="asteroids-main-span">
                From an average distance of 886 million miles (1.4 billion
                kilometers), Saturn is 9.5 astronomical units away from the Sun
                ( One astronomical unit is thedistance from the Sun to Earth).
                From this distance, it takes sunlight 80 minutes to travel from
                the Sun to Saturn.
              </span>
            </div>
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Saturn’s Rings</span>
            </div>
            <div className="mb-5">
              <img className="SaturnRings-main-img" src={SaturnRings} alt="" />
            </div>
            <span className="asteroids-main-span">
              Saturn is probably best known for the system of planetary rings
              that makes it visually unique.
            </span>
            <div className="mt-4">
              <span className="asteroids-main-span">
                Saturn's rings are thought to be pieces of comets, asteroids, or
                shattered moons that broke up before they reached the planet,
                torn apart by Saturn's powerful gravity. They are made of
                billions of small chunks of ice and rock coated with other
                materials such as dust. The ring particles mostly range from
                tiny, dust-sized icy grains to chunks as big as a house. A few
                particles are as large as mountains. The rings would look mostly
                white if you looked at them from the cloud tops of Saturn, and
                interestingly, each ring orbits at a different speed around the
                planet.
              </span>
            </div>
            <div className="mt-4">
              <span className="asteroids-main-span">
                Saturn's ring system extends up to 175,000 miles (282,000
                kilometers) from the planet, yet the vertical height is
                typically about 30 feet (10 meters) in the main rings. Named
                alphabetically in the order they were discovered, the rings are
                relatively close to each other, with the exception of a gap
                measuring 2,920 miles (4,700 kilometers) in width called the
                Cassini Division that separates Rings A and B. The main rings
                are A, B, and C. Rings D, E, F, and G are fainter and more
                recently discovered.
              </span>
            </div>
            <div className="mt-4">
              <span className="asteroids-main-span">
                Starting at Saturn and moving outward, there is the D ring, C
                ring, B ring, Cassini Division, A ring, F ring, G ring, and
                finally, the E ring. Much farther out, there is the very faint
                Phoebe ring in the orbit of Saturn's moon Phoebe. E, F, and G
                are fainter and more recently discovered.
              </span>
            </div>
          </div>

          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">
                Why Saturn’s rings disappear
              </span>
            </div>

            <span className="asteroids-main-span">
              The answer is that they don’t disappear. Saturn’s rings are just
              not invisible from Earth every 13 to 16 years for some months due
              to the planetary alignment.
            </span>
            <div className="mt-4">
              <span className="asteroids-main-span">
                Saturn’s rings are so thin that they seemingly vanish when
                viewed edge-on. As Earth and Saturn travel around the sun on
                their respective orbital paths, our planet reaches this
                particular vantage point like clockwork, roughly every 13 to 16
                years.
              </span>
            </div>
            <div className="mt-4">
              <span className="asteroids-main-span">
                As Saturn completes its orbit over approximately 29.4 Earth
                years, it leans at an angle of 26.7 degrees. This means that our
                view of Saturn toggles between the upper side of its rings when
                it is tilted toward us and the lower side when it is tilted
                away. We get the special, ringless view of the planet when Earth
                transitions between each of these perspectives and passes
                through Saturn’s “ring plane,” essentially, any area of space
                that’s in line with the edge of its rings.
              </span>
            </div>
            <div className="mt-4">
              <span className="asteroids-main-span">
                From that angle, they reflect very little light, making them
                essentially invisible.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SaturnOverviewe;
