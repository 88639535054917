import React, { useState, useEffect } from "react";
import "../Css/ContinentsOfTheWorld.css";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import back from "../Images/back_arrow.png";
import Home from "./Home";
import Tweet from "../Images/Tweetlogo.png";
import Like from "../Images/Likelogo.png";
const ContinentsOfTheWorldOthar = () => {
  let router = useLocation();
  const CountryName = router.state.Name;
  console.log(CountryName, "jhgfdfghjkl");

  const [AirlineData, setAirlineData] = useState([]);
  //   console.log(AirlineData[0].flag, "AirlineData");
  const countrydata = async () => {
    // setloading(true);

    try {
      let Flagyy = {
        url: `/countries_by_continent?continent=${CountryName}`, //192.168.1.10:3223///countries_by_continent?continent=Europe
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons);
          setAirlineData(respons.data);
        });
    } catch (error) {
      console.log("userap...", error);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views">The {CountryName} </span>
            <span className="Sphere-span-ha-Photo">Continents</span>
          </div>
        </div>
      </div>
      <section className="List-of-largest-airlines-main-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="List-of-largest-airlines-main-div ">
          <div className="Savon-of-largest-tha-Thard-div">
            <span className="busiest-btn-one">The seven continents</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="Savon-and-sund-data"
            >
              <div className="Six-name-logoc-img-rank">
                <span className="airport-all-span">Flag</span>
              </div>
              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Country Name</span>
              </div>
              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Land Area</span>
              </div>

              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Population</span>
              </div>

              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Population Percentage</span>
              </div>
            </div>
            {AirlineData?.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="Savon-and-sund-data"
                >
                  <div className="Six-name-logoc-img-rank">
                    <img className="main-airpot-span" src={itm.flag} />
                  </div>
                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.country_name}</span>
                  </div>
                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.land_area}</span>
                  </div>
                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.population}</span>
                  </div>

                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">
                      {itm.population_percentage}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContinentsOfTheWorldOthar;

// const handlShow = () => {
//   Navigate("/ContriesDetails", {
//     state: {
//       ContryData,
//     },
//   });
// };
