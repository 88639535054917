import React, { useState, useRef } from "react";
import "../Css/GuidetoCountry.css";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import { useNavigate } from "react-router-dom";
import Tweet from "../Images/Tweetlogo.png";

const GuidetoCountry = () => {
  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [selectedValue, setSelectedValue] = useState("");

  // Function to handle changes in the dropdown

  const GeographyRef = useRef(null);
  const PeopleRef = useRef(null);
  const EnvironmentRef = useRef(null);
  const EconomyRef = useRef(null);
  const EnergyRef = useRef(null);
  const CommunicationsRef = useRef(null);
  const TransportationRef = useRef(null);
  const MilitaryRef = useRef(null);

  const handleDropdownChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);

    // Scroll to the appropriate ref based on the selected value
    switch (newValue) {
      case "option1":
        scrollToRef(GeographyRef);
        break;
      case "option2":
        scrollToRef(PeopleRef);
        break;
      case "option3":
        scrollToRef(EnvironmentRef);
        break;
      case "option4":
        scrollToRef(EconomyRef);
        break;
      case "option5":
        scrollToRef(EnergyRef);
        break;
      case "option6":
        scrollToRef(CommunicationsRef);
        break;
      case "option7":
        scrollToRef(TransportationRef);
        break;
      case "option8":
        scrollToRef(MilitaryRef);
        break;
      default:
        // Do nothing or handle the default case as needed
        break;
    }
  };
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views"> Guide to Country</span>
            <span className="Sphere-span-ha-Photo"> Comparisons</span>
          </div>
        </div>
      </div>
      <section className="Saide-of-tha-world-sec">
        <div className="Saedbar-div-main">
          <div className="People-all-saidebar">
            <span
              onClick={() => scrollToRef(GeographyRef)}
              className="Geography-side-sapn"
            >
              Geography
            </span>
            <span
              onClick={() => scrollToRef(PeopleRef)}
              className="Geography-side-sapn"
            >
              People and Society
            </span>
            <span
              onClick={() => scrollToRef(EnvironmentRef)}
              className="Geography-side-sapn"
            >
              Environment
            </span>
            <span
              onClick={() => scrollToRef(EconomyRef)}
              className="Geography-side-sapn"
            >
              Economy
            </span>
            <span
              onClick={() => scrollToRef(EnergyRef)}
              className="Geography-side-sapn"
            >
              Energy
            </span>
            <span
              onClick={() => scrollToRef(CommunicationsRef)}
              className="Geography-side-sapn"
            >
              Communications
            </span>
            <span
              onClick={() => scrollToRef(TransportationRef)}
              className="Geography-side-sapn"
            >
              Transportation
            </span>
            <span
              onClick={() => scrollToRef(MilitaryRef)}
              className="Geography-side-sapn"
            >
              Military and Security
            </span>
          </div>
          <div className="selectedValue-drop-main">
            <select
              id="dropdown"
              value={selectedValue}
              onChange={handleDropdownChange}
            >
              <option value="">Select an option</option>
              <option value="option1">Geography</option>
              <option value="option2">People and Society</option>
              <option value="option3">Environment</option>
              <option value="option4">Economy</option>
              <option value="option5">Energy</option>
              <option value="option6">Communications</option>
              <option value="option7">Transportation</option>
              <option value="option8">Military and Security</option>
            </select>
          </div>

          <div className="line-div-siddeme"></div>

          <div className="main-contain-div-go">
            <div ref={GeographyRef} className="contain-of-Geography">
              <span className="Geography-Area-sapn">Geography</span>
              <span
                onClick={() => Navigate("/Area")}
                className="Area-main-span"
              >
                Area
              </span>
            </div>
            <div ref={PeopleRef} className="contain-of-Geography">
              <span className="Geography-Area-sapn"> People and Society</span>
              <span
                onClick={() => Navigate("/Population")}
                className="Area-main-span"
              >
                {" "}
                Population
              </span>
              <span
                onClick={() => Navigate("/MedianAge")}
                className="Area-main-span"
              >
                Median Age
              </span>
              <span
                onClick={() => Navigate("/PopulationGrowthRate")}
                className="Area-main-span"
              >
                Population Growth Rate
              </span>
              <span
                onClick={() => Navigate("/Birthrate")}
                className="Area-main-span"
              >
                {" "}
                Birth Rate
              </span>
              <span
                onClick={() => Navigate("/Deathrate")}
                className="Area-main-span"
              >
                Death Rate
              </span>
              <span
                onClick={() => Navigate("/Netmigrationrate")}
                className="Area-main-span"
              >
                Net Migration Rate{" "}
              </span>
              <span
                onClick={() => Navigate("/Maternalmortalityratio")}
                className="Area-main-span"
              >
                Maternal Mortality Ratio{" "}
              </span>
              <span
                onClick={() => Navigate("/Infantmortalityrate")}
                className="Area-main-span"
              >
                Infant Mortality Rate
              </span>
              <span
                onClick={() => Navigate("/Lifeexpectancyatbirth")}
                className="Area-main-span"
              >
                Life Expectancy At Birth
              </span>
              <span
                onClick={() => Navigate("/Totalfertilityrate")}
                className="Area-main-span"
              >
                Total Fertility Rate{" "}
              </span>
              <span
                onClick={() => Navigate("/Obesity")}
                className="Area-main-span"
              >
                {" "}
                Obesity-Adult Prevalence Rate
              </span>
              <span
                onClick={() => Navigate("/Alcoholconsumptionpercapita")}
                className="Area-main-span"
              >
                Alcohol Consumption Per Capita
              </span>
              <span
                onClick={() => Navigate("/Tobaccouse")}
                className="Area-main-span"
              >
                Tobacco use
              </span>
              <span
                onClick={() => Navigate("/Childrenunder")}
                className="Area-main-span"
              >
                Children Under the Age Of 5 Years underweight
              </span>
              <span
                onClick={() => Navigate("/Educationexpenditures")}
                className="Area-main-span"
              >
                Education Expenditures
              </span>
              <span
                onClick={() => Navigate("/Youthunemployment")}
                className="Area-main-span"
              >
                Youth Unemployment Rate (Ages 15-24)
              </span>
            </div>
            <div ref={EnvironmentRef} className="contain-of-Geography">
              <span className="Geography-Area-sapn">Environment</span>
              <span
                onClick={() => Navigate("/RevenueFrom")}
                className="Area-main-span"
              >
                Revenue From Forest Resources
              </span>
              <span
                onClick={() => Navigate("/Revenuefromcoal")}
                className="Area-main-span"
              >
                Revenue From Coal
              </span>
            </div>
            <div ref={EconomyRef} className="contain-of-Geography">
              <span className="Geography-Area-sapn">Economy</span>
              <span
                onClick={() => Navigate("/RealGDP")}
                className="Area-main-span"
              >
                Real GDP (Purchasing Power Parity)
              </span>
              <span
                onClick={() => Navigate("/RealGDPgrowthrate")}
                className="Area-main-span"
              >
                Real GDP Growth Rate
              </span>
              <span
                onClick={() => Navigate("/RealGDPpercapita")}
                className="Area-main-span"
              >
                Real GDP Per Capita
              </span>
              <span
                onClick={() => Navigate("/Inflationrate")}
                className="Area-main-span"
              >
                Inflation Rate (Consumer Prices)
              </span>
              <span
                onClick={() => Navigate("/Laborforce")}
                className="Area-main-span"
              >
                Labor Force
              </span>
              <span
                onClick={() => Navigate("/Unemploymentrate")}
                className="Area-main-span"
              >
                {" "}
                Unemployment Rate
              </span>
              <span
                onClick={() => Navigate("/Youthmploymnt")}
                className="Area-main-span"
              >
                Youth Unemployment Rate (Ages 15-24)
              </span>
              <span
                onClick={() => Navigate("/GiniIndex")}
                className="Area-main-span"
              >
                Gini Index Coefficient-Distribution Of Family Income
              </span>
              <span
                onClick={() => Navigate("/Budgetsurplus")}
                className="Area-main-span"
              >
                Budget Suprlus (+) Or Deficit (-)
              </span>
              <span
                onClick={() => Navigate("/Publicdebt")}
                className="Area-main-span"
              >
                Public Debt
              </span>
              <span
                onClick={() => Navigate("/Taxesandother")}
                className="Area-main-span"
              >
                Taxes And Other Revenues
              </span>
              <span
                onClick={() => Navigate("/Currentaccountbalance")}
                className="Area-main-span"
              >
                Current Account balance
              </span>
              <span
                onClick={() => Navigate("/Exports")}
                className="Area-main-span"
              >
                Exports
              </span>
              <span
                onClick={() => Navigate("/Imports")}
                className="Area-main-span"
              >
                Imports
              </span>
              <span
                onClick={() => Navigate("/Reservesofforeign")}
                className="Area-main-span"
              >
                Revenues Of Foreign Exchange And Gold
              </span>
              <span
                onClick={() => Navigate("/Debtexternal")}
                className="Area-main-span"
              >
                Debt-External
              </span>
            </div>
            <div ref={EnergyRef} className="contain-of-Geography">
              <span className="Geography-Area-sapn">Energy</span>
              <span
                onClick={() => Navigate("/Electricity")}
                className="Area-main-span"
              >
                Electricity
              </span>
              <span
                onClick={() => Navigate("/Refinedpetroleum")}
                className="Area-main-span"
              >
                Refined Petroleum Products-Production
              </span>
              <span
                onClick={() => Navigate("/Refinedpetroleumexports")}
                className="Area-main-span"
              >
                Refined Petroleum Products-Export
              </span>
              <span
                onClick={() => Navigate("/Refinedpetroleumproductsimports")}
                className="Area-main-span"
              >
                Refined Petroleum Products-Import
              </span>
              <span
                onClick={() => Navigate("/Carbondioxideemissions")}
                className="Area-main-span"
              >
                Carbon dioxide Emissions
              </span>
              <span
                onClick={() => Navigate("/Energyconsumption")}
                className="Area-main-span"
              >
                Energy Consumption Per Capita
              </span>
            </div>
            <div ref={CommunicationsRef} className="contain-of-Geography">
              <span className="Geography-Area-sapn">Communications</span>
              <span
                onClick={() => Navigate("/Telephonesfixedlines")}
                className="Area-main-span"
              >
                Telephones-Fixed Lines
              </span>
              <span
                onClick={() => Navigate("/Telephonesmobilecellular")}
                className="Area-main-span"
              >
                Telephones-Mobile Cellular
              </span>
              <span
                onClick={() => Navigate("/Internetusers")}
                className="Area-main-span"
              >
                Internet users
              </span>
              <span
                onClick={() => Navigate("/Broadbandfixed")}
                className="Area-main-span"
              >
                Broadband-Fixed Subscription
              </span>
            </div>
            <div ref={TransportationRef} className="contain-of-Geography">
              <span className="Geography-Area-sapn">Transportation</span>
              <span
                onClick={() => Navigate("/Airports")}
                className="Area-main-span"
              >
                Airports
              </span>
              <span
                onClick={() => Navigate("/Railways")}
                className="Area-main-span"
              >
                Railways
              </span>
              <span
                onClick={() => Navigate("/Roadways")}
                className="Area-main-span"
              >
                Roadways
              </span>
              <span
                onClick={() => Navigate("/Waterways")}
                className="Area-main-span"
              >
                Waterways
              </span>
              <span
                onClick={() => Navigate("/Merchantmarine")}
                className="Area-main-span"
              >
                Merchant Marine
              </span>
            </div>
            <div ref={MilitaryRef} className="contain-of-Geography">
              <span className="Geography-Area-sapn">Military and Security</span>
              <span
                onClick={() => Navigate("/Militaryexpenditures")}
                className="Area-main-span"
              >
                Military Expenditures
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuidetoCountry;
