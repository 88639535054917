import React from "react";
import OortClou2 from "../../Images/OortCloud2.avif";
import OortClou3 from "../../Images/OortCloud3.webp";
import OortClou4 from "../../Images/OortCloud3.jpg";
import "../OortCloudOverview/OortCloudOverview.css";
const OortCloudOverview = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Oort Cloud Overview</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  "The Oort Cloud is a field of small icy celestial bodies
                  thought to exist at the far reaches of the solar system. It
                  surrounds the bubble of the heliosphere, and is the bridge
                  between our solar system and interstellar space."
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Unlike the orbits of the planets and the Kuiper Belt, which
                  lie mostly in the same flat disk around the Sun, the Oort
                  Cloud is believed to be a giant spherical shell surrounding
                  the rest of the solar system.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Oort cloud is theorized to be a vast cloud of icy
                  planetesimals surrounding the Sun at distances ranging from
                  2,000 to 200,000 astronomical units. The bodies in this cloud
                  replenish and keep constant the number of long-period comets
                  entering the inner Solar System, where they are eventually
                  consumed and destroyed during close approaches to the Sun.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Oort Cloud might contain billions, or even trillions, of
                  objects.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OortCloudOverview;
