import React from "react";
import sedo from "../../Images/dark_layer@3x.png";
import planet from "../../Images/VenusMainimg.jpg";
import VenusOverviwe from "../VenusOverviwe/VenusOverviwe";
import Navbar from "../../NavBar/Navbar";
const Venus = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Venus</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  Although it's similar in structure and size to Earth, Venus
                  has a thick atmosphere that traps heat in a runaway greenhouse
                  effect, making it the hottest planet in our solar system
                </span>
              </div>
              <div className="our-solar-span">
                <span className="UR-SOLAR-SYSTEM-span">TODAY</span>
              </div>
              <div className="Dwarf-Planets-div-do-Missions">
                <div className="Planets-etha-div">
                  <span className="eath-span">00</span>
                  <span className="Planets-main-sapn">Moons</span>
                </div>
                <div className="Planets-etha-div">
                  <span className="eath-span">03</span>
                  <span className="Planets-main-sapn">Missions on Deck</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <VenusOverviwe />
    </>
  );
};

export default Venus;
