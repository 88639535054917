import "../Css/Listoflargestairlinesinasia.css";
import React, { useState, useEffect } from "react";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import Home from "./Home";
import Tweet from "../Images/Tweetlogo.png";
import back from "../Images/back_arrow.png";
import Like from "../Images/Likelogo.png";
const Listoflargestairlinesinasia = () => {
  const [AirlineData, setAirlineData] = useState([]);
  const countrydata = async () => {
    // setloading(true);

    try {
      let Flagyy = {
        url: "get_List_of_largest_airlines_in_asia",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons);
          setAirlineData(respons.data);
        });
    } catch (error) {
      console.log("userap...", error);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}
          <div>
            <span className="Google-span-ha-Views">
              List of largest airlines in Asia
            </span>
          </div>
          <div className="list-savane-conrty-div">
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/ListoflargestairlinesinNorthAmerica")}
            >
              List of largest airlines in North America
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/Listoflargestairlinesineurope")}
            >
              List of largest airlines in Europe
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/ListoflargestairlinesinCentralAmerica")}
            >
              List of largest airlines in Central America and the Caribbean
            </span>

            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/Listoflargestairlinesinafrica")}
            >
              List of largest airlines in Africa
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/Listoflargestairlinesinasia")}
            >
              List of largest airlines in Asia{" "}
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/Listoflargestairlinesinsouthamerica")}
            >
              List of largest airlines in South America
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/Listoflargestairlinesinoceania")}
            >
              List of largest airlines in Oceania
            </span>
          </div>
        </div>
      </div>
      <section className="List-of-largest-airlines-main-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="List-of-largest-airlines-main-div ">
          <div className="indigo-of-largest-tha-Thard-div">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="indigo-air-list"
            >
              <div className="reland-Malta-div">
                <span className="airport-all-span">Airline</span>
              </div>
              <div className="airlines-main-largest-11">
                <span className="airport-all-span">country</span>
              </div>
              <div className="airlines-main-largest-11">
                <span className="airport-all-span">Alliance</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2023</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2022</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2021</span>
              </div>

              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2020</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2019</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2018</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2017</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2016</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2015</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2014</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2013</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2012</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2011</span>
              </div>
              <div className="airlines-main-largest-22">
                <span className="airport-all-span">2010</span>
              </div>
            </div>
            {AirlineData.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="indigo-air-list"
                >
                  <div className="reland-Malta-div">
                    <span className="main-airpot-span">{itm.airline}</span>
                  </div>
                  <div className="airlines-main-largest-11">
                    <span className="main-airpot-span">{itm.country}</span>
                  </div>
                  <div className="airlines-main-largest-11">
                    <span className="main-airpot-span">{itm.alliance}</span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[0].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[1].passanger}
                    </span>
                  </div>

                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[2].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[3].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[4].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[5].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[6].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[7].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[8].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[9].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[10].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[11].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[12].passanger}
                    </span>
                  </div>
                  <div className="airlines-main-largest-22">
                    <span className="main-airpot-span">
                      {itm.data[13].passanger}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Listoflargestairlinesinasia;
