import React from "react";
import planet from "../../Images/BlackHolesImg.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import BlackHolesOverview from "../BlackHolesOverview/BlackHolesOverview";
import Navbar from "../../NavBar/Navbar";
const BlackHoles = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Ceres-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Black Holes</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  Black Holes: Cosmic Abyss and Gravitational Giants
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlackHolesOverview />
    </>
  );
};

export default BlackHoles;
