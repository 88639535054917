import React, { useState, useEffect, useRef } from "react";
import "../Css/ListOfVolcanic.css";
import axios from "../Schemas/Api";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import { useNavigate } from "react-router-dom";
import back from "../Images/back_arrow.png";
const ListOfVolcanic = () => {
  const [Listvol, setListvol] = useState([]);

  const ListVolcanic = async () => {
    try {
      let poly = {
        url: "/List_of_recent_volcanic_eruptions",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons.data);
          setListvol(respons.data);
          //   setloading(false);
        });
    } catch (error) {
      console.log("userap...", error);
    }
  };
  useEffect(() => {
    ListVolcanic();
  }, []);
  const Navigate = useNavigate();
  return (
    <>
      <Home />

      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views"> List of largest </span>

            <span className="Sphere-span-ha-Photo">volcanic eruptions</span>
          </div>
          <div className="main-volconec-div">
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/VolcanoBycountry")}
            >
              Volcanoes by country
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/LargeEruptions")}
            >
              Recent Volcanic eruptions
            </span>

            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/ListOfVolcanic")}
            >
              Largest volcanic eruptions
            </span>
          </div>
        </div>
      </div>

      <section className="Countries-of-tha-world-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Volcanic-of-tha-world-div">
          <div className="Countries-of-tha-Thard-div">
            <div className="Florida-Coast-Keys">
              <span className="Countries-Regions-az">
                List of largest volcanic eruptions
              </span>
            </div>
            <table className="Alfreds-tebal">
              <tr className="Alfreds-tr">
                <td className="eruption-td-1">
                  {" "}
                  Volcanic-Eruption <sup>[5]</sup>
                </td>
                <td className="age-td-spam-2">
                  {" "}
                  Age (Millions of Years) <sup>[n 2]</sup>{" "}
                </td>
                <td className="age-td-spam-2">Location </td>
                <td className="age-td-spam-2">
                  {" "}
                  Volume (km <sup>3</sup>) <sup>[n 3]</sup>
                </td>
                <td className="Notes-aa-gai">Notes</td>
              </tr>{" "}
            </table>
            {Listvol.map((itm, index) => {
              return (
                <table className="Alfreds-tebal">
                  <tr className="Alfreds-tr">
                    <td className="eruption-td">{itm.eruption}</td>
                    <td className="age-td-spam">{itm.age}</td>
                    <td className="age-td-spam">{itm.location}</td>
                    <td className="age-td-spam">{itm.volume}</td>
                    <td className="fir-se-notes"> {itm.notes}</td>
                  </tr>{" "}
                </table>
              );
            })}
            {/* <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              {array.map((letter) => (
                <div
                  key={letter}
                  id={letter}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <h2>{letter} </h2>
                  {ContryCode.filter(
                    (item) =>
                      item.country_name[0].toLowerCase() ===
                      letter.toLowerCase()
                  ).map((item) => (
                    <div
                     
                      n
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      className="wasdwasdwasd-ka-bhi-wasd"
                    >
                  
                      <div className="country-name-logoc-img">
                        <span>mkdm</span>
                      </div>
                      <div className="country-name-logoc-img">
                        <span>kmkemc</span>
                      </div>
                      <div className="country-name-logoc-img">
                        <span>kxemml</span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ListOfVolcanic;
