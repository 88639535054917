import React from "react";
import "../SolarSystemOverview/SolarSystemOverview.css";
const SolarSystemOverview = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div">
          <div className="Overview-contain-main-div">
            <div>
              <span className="Overview-soler-span">Solar System Overview</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Our solar system is made up of the Sun, eight planets, 146
                  moons, a bunch of comets, asteroids and space rocks, ice, and
                  several dwarf planets, such as Pluto.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The eight planets are Mercury, Venus, Earth, Mars, Jupiter,
                  Saturn, Uranus, and Neptune. Mercury is closest to the Sun.
                  Neptune is the farthest.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Planets, asteroids, and comets orbit the Sun. They travel
                  around our Sun in a flattened circle called an ellipse.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Moons orbit planets. Jupiter has the most named moons—50.
                  Mercury and Venus don't have any moons. Earth has one. It is
                  the brightest object in the night sky and the Sun is the
                  brightest object in the daytime sky. The Sun lights up the
                  moon, planets, comets, and asteroids.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="over-ifrem-main">
        <iframe
          src="https://www.solarsystemscope.com/iframe"
          className="ifram-main-div"
        ></iframe>
      </div>
    </>
  );
};

export default SolarSystemOverview;
