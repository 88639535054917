import React from "react";
import OortClou2 from "../../Images/DarkMatterandDarkEnergyOverview1.jpg";
import OortClou3 from "../../Images/DarkMatterandDarkEnergyOverview2.webp";
import OortClou4 from "../../Images/DarkMatterandDarkEnergyOverview3.jpg";
const DarkMatterandDarkEnergyOverview = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Dark Matter and Dark Energy
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  In the vast expanse of the cosmos, two mysterious and unseen
                  phenomena—dark matter and dark energy—play pivotal roles,
                  shaping the universe in ways that defy conventional
                  understanding.
                </span>
              </div>
            </div>

            <div className="mt-4 mb-5">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Dark Matter: The Invisible Cosmic Architect
                </span>
              </div>
              <span className="asteroids-main-span">
                Dark matter is a mysterious and invisible form of matter that
                constitutes a significant portion of the total mass in the
                universe. Unlike ordinary matter—such as atoms and particles
                that make up stars, planets, and galaxies—dark matter does not
                interact with light or other forms of electromagnetic radiation.
                As a result, it cannot be observed directly using telescopes or
                other conventional instruments.
              </span>
              <div className="mt-4">
                <span>
                  The existence of dark matter is inferred from its
                  gravitational effects on visible matter. Observations of
                  galaxies and galaxy clusters reveal that the gravitational
                  pull exerted by the visible mass alone is insufficient to
                  account for the observed motions and structures. Something
                  unseen, dark matter, must be providing the additional
                  gravitational influence needed to explain these cosmic
                  phenomena.
                </span>
              </div>
              <div className="mt-4">
                <span>
                  The exact nature of dark matter remains one of the most
                  significant mysteries in astrophysics. Various theoretical
                  models propose that dark matter could consist of exotic
                  particles that interact weakly with ordinary matter, making
                  them exceptionally challenging to detect directly. Researchers
                  are actively conducting experiments and observations to unveil
                  the true identity of dark matter and understand its role in
                  shaping the large-scale structure of the universe. Despite its
                  mysterious nature, dark matter stands as a foundational
                  component in our current understanding of the cosmos, playing
                  a crucial role in the gravitational dance that governs the
                  dynamics of galaxies and cosmic structures.
                </span>
              </div>
            </div>
            <div className="mt-4 mb-5">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Dark Energy: The Cosmic Antigravity Force
                </span>
              </div>
              <span className="asteroids-main-span">
                Dark energy is an enigmatic and elusive form of energy that
                pervades the universe, contributing to its accelerated
                expansion. Unlike familiar forms of energy, such as light or
                kinetic energy, dark energy does not manifest in a way that is
                directly observable. Its existence and influence are inferred
                from observations of the large-scale structure and dynamics of
                the cosmos.
              </span>
              <div className="mt-4">
                <span>
                  The discovery of dark energy stemmed from observations of
                  distant supernovae (exploding stars) in the late 1990s. These
                  observations revealed that the universe is not only expanding,
                  as previously thought, but that the rate of expansion is
                  accelerating over time. Dark energy is the leading explanation
                  for this unexpected cosmic acceleration.
                </span>
              </div>
              <div className="mt-4">
                <span>
                  Dark energy is characterized by a repulsive gravitational
                  effect, acting as a sort of "antigravity" force. While gravity
                  traditionally pulls objects together, dark energy drives
                  galaxies apart, causing the expansion of the universe to
                  accelerate. This concept challenges conventional expectations
                  and introduces a mysterious component that dominates the
                  cosmic energy budget.
                </span>
              </div>
              <div className="mt-4">
                <span>
                  Despite its central role in the dynamics of the universe, the
                  true nature of dark energy remains largely unknown. Various
                  theoretical models propose that dark energy could be
                  associated with a "cosmological constant" or a dynamic field
                  permeating space itself. As scientists seek to comprehend the
                  fundamental forces shaping our cosmos, the study of dark
                  energy stands as a forefront pursuit, with experiments and
                  observations aimed at unraveling the mysteries of this cosmic
                  influence.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div mb-5">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default DarkMatterandDarkEnergyOverview;
