import React from "react";
import OortClou2 from "../../Images/WhatisUniverseOverViewimg1.jpg";
import OortClou3 from "../../Images/WhatisUniverseOverView2.jpg";
import OortClou4 from "../../Images/WhatisUniverseOverView3.jpg";

const WhatisUniverseOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Origins of Universe</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  The origins of the universe trace back to a singular,
                  cataclysmic event known as the Big Bang. Approximately 13.8
                  billion years ago, the universe emerged from an extremely hot
                  and dense state, initiating a process of rapid expansion. This
                  explosive birth marked the beginning of cosmic evolution, as
                  space, time, and matter unfolded.
                </span>
              </div>

              <div className="mt-4 ">
                <span className="asteroids-main-span">
                  In the early moments after the Big Bang, the universe
                  underwent significant transformations. Fundamental particles,
                  such as quarks and electrons, formed during the intense heat
                  of this primordial phase. As the universe expanded and cooled,
                  these particles combined to create protons and neutrons,
                  eventually leading to the formation of the first elements.
                </span>
              </div>
              <div className="mt-4 ">
                <span className="asteroids-main-span">
                  The formation of galaxies and cosmic structures followed as
                  gravity played a crucial role in bringing matter together.
                  Galaxies, comprised of stars, planets, and other celestial
                  bodies, formed over billions of years. Within these galaxies,
                  stars ignited, initiating nuclear fusion and producing the
                  light and energy that fill the cosmos.
                </span>
              </div>
              <div className="mt-4 ">
                <span className="asteroids-main-span">
                  The ongoing cosmic expansion, discovered through astronomical
                  observations, remains a fundamental aspect of the universe's
                  story. Dark matter and dark energy, though mysterious and not
                  yet fully understood, exert influences on cosmic structures
                  and expansion dynamics.
                </span>
              </div>
              <div className="mt-4 ">
                <span className="asteroids-main-span">
                  The origins of the universe represent a profound chapter in
                  scientific inquiry, blending theoretical models, observational
                  astronomy, and particle physics to construct a comprehensive
                  understanding of our cosmic origins. The pursuit of knowledge
                  about the universe's birth and evolution continues to inspire
                  scientific exploration, pushing the boundaries of human
                  understanding.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div mb-5">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatisUniverseOverView;
