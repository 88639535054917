import React from "react";
import "../Css/WhatisOcean.css";
import I1 from "../Images/img1.gif";
import mizen from "../Images/Mizen.jpg";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import { useNavigate } from "react-router-dom";
import back from "../Images/back_arrow.png";
const WhatisOcean = () => {
  const data = [
    {
      numb: "1",
      text: "Pacific Ocean",
      loc: "Between Asia and Australasia and the Americas[50]",
      par: "168,723,000 (46.6%)",
      vol: "669,880,000 (50.1%)",
      k: "3,970",
      km: "135,663 (35.9%)",
    },
    {
      numb: 3,
      text: "Indian Ocean",
      loc: "Between southern Asia, Africa and Australia[52]",
      par: "70,560,000 (19.5%)",
      vol: "264,000,000 (19.8%)	",
      k: "3,741	",
      km: "66,526 (17.6%)",
    },
    {
      numb: 2,
      text: "Atlantic Ocean",
      loc: "Between the Americas and Europe and Africa[51]",
      par: "85,133,000 (23.5%)",
      vol: "310,410,900 (23.3%)	",
      k: "3,646",
      km: "111,866 (29.6%)",
    },
    {
      numb: 5,
      text: "Arctic Ocean",
      loc: "Between northern North America and Eurasia in the Arctic Sometimes considered a marginal sea of the Atlantic.[55][56][57]",
      par: "15,558,000 (4.3%)",
      vol: "18,750,000 (1.4%)",
      k: "1,205",
      km: "45,389 (12.0%)",
    },
    {
      numb: 4,
      text: "Antarctic/Southern Ocean",
      loc: "Between Antarctica and the Pacific, Atlantic and Indian oceans Sometimes considered an extension of those three oceans.[53][54]",
      par: "21,960,000 (6.1%)",
      vol: "71,800,000 (5.4%)",
      k: "3,270	",
      km: "17,968 (4.8%)",
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views"> What </span>
            <span className="Sphere-span-ha-Photo">Is Ocean</span>
          </div>
        </div>
      </div>
      <section className="Countries-of-tha-world-sec">
        <div onClick={() => navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="osea-of-tha-world-div">
          <div className="img-con-ocean-div">
            <div className="ocean-largest-div">
              <span className="Countries-Regions-az">Ocean</span>
              <span className="mt-3 world-conventionally-span">
                The ocean (also known as the sea or the world ocean) is a body
                of salt water that covers approximately 70.8% of the Earth and
                contains 97% of Earth's water.[9] The term ocean also refers to
                any of the large bodies of water into which the world ocean is
                conventionally divided.[10] Distinct names are used to identify
                five different areas of the ocean: Pacific (the largest),
                Atlantic, Indian, Southern, and Arctic (the smallest).[11][12]
                Seawater covers approximately 361,000,000 km2 (139,000,000 sq
                mi) of the planet. The ocean is the primary component of the
                Earth's hydrosphere, and thus essential to life on Earth. The
                ocean influences climate and weather patterns, the carbon cycle,
                and the water cycle by acting as a huge heat reservoir.
              </span>
            </div>
            <div className="influences-main-oes">
              <img className="influences-img" src={I1} alt="" />
            </div>
          </div>
          <div className="contemporary-main-div mt-5">
            <span className="Countries-Regions-az"> Ocean and sea</span>
            <span className="world-conventionally-span mt-3">
              The terms "the ocean" or "the sea" used without specification
              refer to the interconnected body of salt water covering the
              majority of the Earth's surface.[11][12] It includes the Atlantic,
              Pacific, Indian, Southern and Arctic Oceans.[20] As a general
              term, "the ocean" and "the sea" are often interchangeable,
              although speakers of British English refer to "the sea" in all
              cases,[21][dubious – discuss] even when the body of water is one
              of the oceans.
            </span>
          </div>
          <div className="contemporary-main-div mt-5">
            <span className="Countries-Regions-az"> World ocean</span>
            <span className="world-conventionally-span mt-3">
              The contemporary concept of the World Ocean was coined in the
              early 20th century by the Russian oceanographer Yuly Shokalsky to
              refer to the continuous ocean that covers and encircles most of
              the Earth.[24][25] The global, interconnected body of salt water
              is sometimes referred to as the world ocean, global ocean or the
              great ocean.[26][27][28] The concept of a continuous body of water
              with relatively unrestricted exchange between its components is
              critical in oceanography.[29]
            </span>
          </div>
          <div className="img-con-ocean-div">
            <div className="ocean-largest-div">
              <span className="Countries-Regions-az">Ocean</span>
              <span className="mt-3 world-conventionally-span">
                The word ocean comes from the figure in classical antiquity,
                Oceanus (/oʊˈsiːənəs/; Greek: Ὠκεανός Ōkeanós,[30] pronounced
                [ɔːkeanós]), the elder of the Titans in classical Greek
                mythology. Oceanus was believed by the ancient Greeks and Romans
                to be the divine personification of an enormous river encircling
                the world.
              </span>
            </div>
            <div className="influences-main-oes">
              <img className="Mizen-imag-ok" src={mizen} alt="" />
            </div>
          </div>
          <div className="oecia-main-sectiondiv">
            <div className="mt-5">
              <span className="Countries-Regions-az">Oceans by size</span>
            </div>

            <div className="Countries-of-tha-world-div">
              <div className="list-of-lake-by-all-over-arey">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="numb-text-and-upr mt-4"
                >
                  <div className="country-Tebal-all">
                    <span className="Flag-all-span">#</span>
                  </div>
                  <div className="country-Tebal-sec">
                    <span className="Flag-all-span">Ocean</span>
                  </div>

                  <div className="country-Tebal-thard">
                    <span className="Flag-all-span">ocation</span>
                  </div>

                  <div className="country-Tebal-forth">
                    <span className="Flag-all-span">
                      Area (km)<sup>2</sup>
                    </span>
                  </div>
                  <div className="country-Tebal-fiv">
                    <span className="Flag-all-span">
                      Volume (km)<sup>3</sup>{" "}
                    </span>
                  </div>
                  <div className="country-Tebal-six">
                    <span className="Flag-all-span">Avg.depth (m)</span>
                  </div>
                  <div className="country-Tebal-sven">
                    <span className="Flag-all-span">
                      Coastline (km)<sup>49</sup>
                    </span>
                  </div>
                </div>

                {data.map((itm, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      className="numb-text-and-upr mt-4"
                    >
                      <div className="country-Tebal-all">
                        <span className="Flag-all-span">{itm.numb}</span>
                      </div>
                      <div className="country-Tebal-sec">
                        <span className="Flag-all-span">{itm.text}</span>
                      </div>

                      <div className="country-Tebal-thard">
                        <span className="Flag-all-span">{itm.loc}</span>
                      </div>

                      <div className="country-Tebal-forth">
                        <span className="Flag-all-span">{itm.par}</span>
                      </div>
                      <div className="country-Tebal-fiv">
                        <span className="Flag-all-span">{itm.vol}</span>
                      </div>
                      <div className="country-Tebal-six">
                        <span className="Flag-all-span">{itm.k}</span>
                      </div>
                      <div className="country-Tebal-sven">
                        <span className="Flag-all-span">{itm.km}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatisOcean;
