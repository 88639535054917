import React from "react";
import "../Css/StreetViewMessage.css"; // Create this CSS file for styling

const StreetViewMessage = () => {
  return (
    <div className="street-view-message">
      <p>Street view is not available for this location.</p>
    </div>
  );
};

export default StreetViewMessage;
