import React from "react";
import OortClou2 from "../../../Images/Uranusmoons1.jpg";
import OortClou3 from "../../../Images/UranusMoons2.avif";
import OortClou4 from "../../../Images/UranusMoons3.webp";

const UranusMoonOverVeiw = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Uranus Moon Overview </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  The seventh planet of the Solar System, Uranus, has at least
                  27 moons, most of which are named after characters that appear
                  or are mentioned in the works of William Shakespeare and
                  Alexander Pope.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Uranus's moons are divided into three groups:
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  • Thirteen inner moons. The inner moons are small dark bodies
                  that share common properties and origins with Uranus's rings.
                  All inner moons have prograde orbits.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  • Five major moons. The names of the five major moons are:
                  Miranda, Ariel, Umbriel, Titania, and Oberon. The five major
                  moons are ellipsoidal, indicating that they reached
                  hydrostatic equilibrium at some point in their past (and may
                  still be in equilibrium), and four of them show signs of
                  internally driven processes such as canyon formation and
                  volcanism on their surfaces. The largest of these five,
                  Titania, is 1,578 km in diameter and the eighth-largest moon
                  in the Solar System. All Five major moons have prograde
                  orbits.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  • Nine irregular moons. Uranus's irregular moons have
                  elliptical and strongly inclined (mostly retrograde) orbits at
                  large distances from the planet.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">
                Names of Saturn Moons
              </span>
            </div>
            <span className="asteroids-main-span">
              Ariel, Miranda, Umbriel, Titania, Oberon, Cordelia, Ophelia,
              Bianca, Cressida, Desdemona, Juliet, Portia, Rosalind, Belinda,
              Puck, Caliban, Sycorax, Prospero, Setebos, Stephano, Trinculo,
              plus others yet to receive names.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default UranusMoonOverVeiw;
