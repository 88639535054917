import React, { useState, useEffect } from "react";
import "../Css/Listofbusiest.css";
import ContriesOfLogo from "../Images/ContriesOfThaWorld.jpg";
import axios from "../Schemas/Api";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import back from "../Images/back_arrow.png";
import { useNavigate } from "react-router-dom";
const Listofbusiest = () => {
  const [ContryData, setContryData] = useState([]);
  const [ContryDataSec, setContryDataSec] = useState(false);
  const [sortOrder, setSortOrder] = useState("");
  const initialValue = "airport";
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [clickCount, setClickCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortedData, setSortedData] = useState([]);

  const handleValueChange = (event) => {
    setSelectedValue(event.target.innerText);
    setClickCount(clickCount + 1);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "/List_of_busiest_airports_by_passenger_traffic"
      );
      setContryData(response.data.data);
      setLoading(false);
      console.log(response.data.data, "response.data.data");
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchSortedData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/List_of_busiest_airports_by_passenger_traffic",
        {
          field: selectedValue,
          order: sortOrder,
        },
        {
          Headers: { "Content-Type": "application/json" },
        }
      );
      setSortedData(response.data.data);
      console.log(response.data.data, "ban na swar na");
      setLoading(false);
    } catch (error) {
      console.log("Error fetching sorted data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Initial data fetch
  }, []);

  useEffect(() => {
    fetchSortedData(); // Fetch sorted data when selectedValue or sortOrder changes
  }, [selectedValue, sortOrder]);

  const handleSortingClick = (value) => {
    setSelectedValue(value);

    // Check if the "airport" button was clicked three times
    if (value === "airport" && clickCount === 2) {
      // Perform the data fetch here
      fetchData();
      setContryDataSec(false); // Reset sorting
      setClickCount(0); // Reset click count
    } else {
      // For other buttons, continue with sorting logic
      setSortOrder(sortOrder === "" ? "desc" : "");
      setContryDataSec(true);
      setClickCount(clickCount + 1);
    }
  };
  const array = [];
  for (let i = 65; i <= 90; i++) {
    array.push(String.fromCharCode(i));
  }

  const remaining = 26 - array.length;
  for (let i = 0; i < remaining; i++) {
    array.push(""); // Fill with empty strings
  }
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views">
              {" "}
              List of busiest airports{" "}
            </span>
            <span className="Sphere-span-ha-Photo"> by passenger traffic</span>
          </div>
        </div>
      </div>
      <section className="Countries-of-tha-world-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Countries-of-tha-world-div">
          <div className="Countries-of-tha-Thard-div">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="wasdwasdwasd-busies"
            >
              <div
                className="airport-name-logoc-1"
                onClick={() => handleSortingClick("airport")}
              >
                <span className="airport-all-span">airport</span>
                {selectedValue === "airport" && sortOrder === "" && "▼"}
                {selectedValue === "airport" && sortOrder === "desc" && "▲"}
              </div>
              <div
                className="location-name-logoc-1"
                onClick={() => handleSortingClick("location")}
              >
                <span className="airport-all-span">location</span>
                {selectedValue === "location" && sortOrder === "" && "▼"}
                {selectedValue === "location" && sortOrder === "desc" && "▲"}
              </div>
              <div
                className="country-logoc-2"
                onClick={() => handleSortingClick("country")}
              >
                <span className="airport-all-span"> country</span>
                {selectedValue === "country" && sortOrder === "" && "▼"}
                {selectedValue === "country" && sortOrder === "desc" && "▲"}
              </div>
              <div
                className="Code-name-logoc-3"
                onClick={() => handleSortingClick("code")}
              >
                <span className="airport-all-span">Code</span>
                {selectedValue === "code" && sortOrder === "" && "▼"}
                {selectedValue === "code" && sortOrder === "desc" && "▲"}
              </div>

              <div
                className="passenger-name-logoc-4"
                onClick={() => handleSortingClick("total_passenger")}
              >
                <span className="airport-all-span">total passenger</span>
                {selectedValue === "total_passenger" &&
                  sortOrder === "" &&
                  " ▼"}
                {selectedValue === "total_passenger" &&
                  sortOrder === "desc" &&
                  "▲"}
              </div>
            </div>
            {ContryDataSec ? (
              <>
                {sortedData.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="wasdwasdwasd-busies"
                  >
                    <div className="country-name-logoc-img-2">
                      <span className="main-airpot-span">{item.airport}</span>
                    </div>
                    <div className="country-name-logoc-img-2">
                      <span className="main-airpot-span">{item.location}</span>
                    </div>
                    <div className="country-name-logoc-img-3">
                      <span className="main-airpot-span">{item.country}</span>
                    </div>
                    <div className="country-name-logoc-img-4">
                      <span className="main-airpot-span">{item.code}</span>
                    </div>
                    <div className="country-name-logoc-img-4">
                      <span className="main-airpot-span">
                        {item.total_passenger}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {ContryData.map((item) => (
                  <div
                    key={item.airport}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="wasdwasdwasd-busies"
                  >
                    <div className="country-name-logoc-img-2">
                      <span className="main-airpot-span">{item.airport}</span>
                    </div>
                    <div className="country-name-logoc-img-2">
                      <span className="main-airpot-span">{item.location}</span>
                    </div>
                    <div className="country-name-logoc-img-3">
                      <span className="main-airpot-span">{item.country}</span>
                    </div>
                    <div className="country-name-logoc-img-4">
                      <span className="main-airpot-span">{item.code}</span>
                    </div>
                    <div className="country-name-logoc-img-4">
                      <span className="main-airpot-span">
                        {item.total_passenger}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Listofbusiest;
