import React from "react";
import OortClou2 from "../../../Images/PlutoMoon2.png";
import OortClou3 from "../../../Images/PlutoMoon1.avif";
import OortClou4 from "../../../Images/PlutoMoon3.jpg";

const PlutoMoonOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Pluto Moon Overview </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  The dwarf planet Pluto has five natural satellites. In order
                  of distance from Pluto, they are Charon, Styx, Nix, Kerberos,
                  and Hydra.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  This moon system might have formed by a collision between
                  Pluto and another similar-sized body early in the history of
                  the solar system.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Charon is the largest one and it is about half the size of
                  Pluto itself, making it the largest satellite relative to the
                  planet it orbits in our solar system.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Charon is mutually tidally locked with Pluto. This means that
                  the same side of Charon always faces Pluto. Charon possesses a
                  synchronous rotation with Pluto and Charon-Pluto is sometimes
                  considered a double dwarf planet.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  On the other hand, Pluto's other four moons are much smaller
                  and they are irregularly shaped. Unlike many other moons in
                  the solar system, these moons are not tidally locked to Pluto.
                  They all spin and don’t keep the same face towards Pluto.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Names of Pluto Moons</span>
            </div>
            <span className="asteroids-main-span">
              Charon, Nix, Kerberos, Hydra, Styx.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlutoMoonOverView;
