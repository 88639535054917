import React from "react";
import OortClou2 from "../../Images/MakemakeDistance1.jpg";
import OortClou3 from "../../Images/MakemakeDistance2.jpg";
import OortClou4 from "../../Images/MakemakeDistance3.jpg";

const MakemakeOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Makemake Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Makemake, along with fellow dwarf planets Pluto, Eris, and
                  Haumea, is located in the Kuiper Belt, a donut-shaped region
                  of icy bodies beyond the orbit of Neptune. Makemake is
                  slightly smaller than Pluto, and is the second-brightest
                  object in the Kuiper Belt as seen from Earth while Pluto is
                  the brightest.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  It has a diameter approximately that of Saturn's moon Iapetus,
                  or 60% that of Pluto.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Makemake takes 305 Earth years to make one trip around the
                  Sun. As Makemake orbits the Sun, it completes one rotation
                  every 22 and a half hours, making its day length similar to
                  Earth and Mars.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  It has one known satellite.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Makemake has extremely low average temperature, about 40 K
                  (−230 °C), which means that its surface is covered with
                  methane, ethane, and possibly nitrogen ices.
                </span>
              </div>

              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Makemake Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  It was initially known as 2005 FY9 and later given the
                  minor-planet number 136472. In July 2008, it was named after
                  Makemake, the god of fertility in the Rapa Nui mythology of
                  Easter Island, under the expectation by the International
                  Astronomical Union (IAU) that it would prove to be a dwarf
                  planet.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              With a radius of approximately 444 miles (715 kilometers),
              Makemake is 1/9 the radius of Earth.
            </span>
            <span className="asteroids-main-span">
              From an average distance of 4,253,000,000 miles (6,847,000,000
              kilometers), Makemake is 45.8 astronomical units away from the Sun
              (One astronomical unit is the distance from the Sun to Earth).
              From this distance, it takes sunlight 6 hours and 20 minutes to
              travel from the Sun to Makemake
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default MakemakeOverView;
