import React from "react";
import OortClou2 from "../../Images/Marsradius1.jpg";
import OortClou3 from "../../Images/Marsradius2.webp";
import OortClou4 from "../../Images/Marsradius3.jpg";

const MarsOverveiwe = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Planet Mars Overview </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Mars is the fourth planet and the furthest terrestrial planet
                  from the Sun and it is one of Earth's two closest planetary
                  neighbors (Venus is the other).
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The reddish color of its surface is due to finely grained iron
                  oxide dust in the soil, giving it the nickname “the Red
                  Planet”.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Martian dichotomy is visible on the surface: on average,
                  the terrain on Mars's northern hemisphere is flatter and lower
                  than its southern hemisphere.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Mars has a thin atmosphere made primarily of carbon dioxide
                  and two irregularly shaped natural satellites: Phobos and
                  Deimos.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Geologically, Mars is fairly active, with dust devils sweeping
                  across the landscape and marsquakes (Martian analog to
                  earthquakes) trembling underneath the ground. The surface of
                  Mars hosts a large shield volcano (Olympus Mons) and one of
                  the largest canyons in the Solar System (Valles Marineris).
                  light.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Mars's significant orbital eccentricity and axial tilt cause
                  large seasonal changes to the polar ice caps' coverage and
                  temperature swings between −110 °C (−166 °F) to 35 °C (95 °F)
                  on the surface.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  A Martian solar day (sol) is equal to 24.5 hours and a Martian
                  solar year is equal to 1.88 Earth years.{" "}
                </span>
              </div>

              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Mars Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  Mars was named by the ancient Romans for their god of war
                  because its reddish color was reminiscent of blood. Even
                  today, it is frequently called the "Red Planet" because the
                  finely grained iron oxide dust in the soil causes it surface
                  to look red.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              Mars's radius is second smallest among the planets in the Solar
              System at 3,389.5 km (2,106 mi). and it is about half the size of
              Earth.
            </span>
            <span className="asteroids-main-span">
              From an average distance of 142 million miles (228 million
              kilometers), Mars is 1.5 astronomical units away from the Sun (One
              astronomical unit is the distance from the Sun to Earth). From
              this distance, it takes sunlight 13 minutes to travel from the Sun
              to Mars.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default MarsOverveiwe;
