import React, { useState, useEffect } from "react";
import "../Css/LargestNine.css";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";

const LargestNine = () => {
  const [AirlineData, setAirlineData] = useState([]);
  console.log(AirlineData, "AirlineData");
  const countrydata = async () => {
    // setloading(true);

    try {
      let Flagyy = {
        url: "Largest_airlines_in_the_world_by_routes",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons);
          setAirlineData(respons.data);
        });
    } catch (error) {
      console.log("userap...", error);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);
  return (
    <>
      <section className="List-of-largest-airlines-main-sec">
        <div className="List-of-largest-airlines-main-div ">
          <div className="Savon-of-largest-tha-Thard-div">
            <span className="busiest-btn-one">
              Largest Airlines in The World by Routes
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="Savon-and-sund-data"
            >
              <div className="Six-name-logoc-img-rank">
                <span className="airport-all-span">Rank</span>
              </div>
              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Country</span>
              </div>
              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Airline</span>
              </div>

              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Type</span>
              </div>

              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Value</span>
              </div>
            </div>
            {AirlineData?.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="Savon-and-sund-data"
                >
                  <div className="Six-name-logoc-img-rank">
                    <span className="main-airpot-span">{itm.rank}</span>
                  </div>
                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.country}</span>
                  </div>
                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.airline}</span>
                  </div>
                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.type}</span>
                  </div>

                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.value}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default LargestNine;
