import React from "react";
import OortClou2 from "../../Images/UranusDistance1.jpg";
import OortClou3 from "../../Images/UranusDistance2.jpg";
import OortClou4 from "../../Images/UranusImgs.jpg";

const UranusOverVeiwe = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Uranus Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Uranus is the seventh planet from the Sun and it is a gaseous
                  cyan-colored ice giant.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Most of the planet is made of water, ammonia, and methane in a
                  supercritical phase of matter, which in astronomy is called
                  'ice' or volatiles.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The planet's atmosphere has a complex layered cloud structure
                  and it has the lowest minimum temperature of 49 K (−224 °C;
                  −371 °F) out of all Solar System's planets.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  It has a marked axial tilt of 97.8° with a retrograde rotation
                  rate of 17 hours. This means that in an 84 Earth years orbital
                  period around the Sun, its poles get around 42 years of
                  continuous sunlight, followed by 42 years of continuous
                  darkness.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Uranus has the third-largest diameter and fourth-largest mass
                  among the Solar System's planets Uranus is one more planet
                  that has a ring system, orbiting natural satellites and a
                  magnetosphere. Its ring system is extremely dark, with only
                  about 2% of the incoming light reflected, and contains the
                  known 13 inner moons. Further out, there are the larger five
                  major moons of the planet: Miranda, Ariel, Umbriel, Titania,
                  and Oberon. Morover, orbiting at much greater distance from
                  Uranus, there are the known nine irregular moons.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The planet's magnetosphere is highly asymmetric and has many
                  charged particles, which may cause the darkening of its rings
                  and moons.
                </span>
              </div>

              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Uranus Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  Uranus was first observed in 1781 by William Herschel who
                  tried unsuccessfully to name his discovery Georgium Sidus
                  after King George III. About seven decades after its
                  discovery, consensus was reached that the planet be named from
                  the Greek god of sky, Uranus (Ouranos), one of the Greek
                  primordial deities.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              With a radius of 15,759.2 miles (25,362 kilometers), Uranus is 4
              times wider than Earth.
            </span>
            <span className="asteroids-main-span">
              From an average distance of 1.8 billion miles (2.9 billion
              kilometers), Uranus is 19.8 astronomical units away from the Sun
              (One astronomical unit is the distance from the Sun to Earth).
              From this distance, it takes sunlight 2 hours and 40 minutes to
              travel from the Sun to Uranus.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default UranusOverVeiwe;
