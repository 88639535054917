import React, { useState, useEffect, useRef } from "react";
import "../Css/FREENauticalCharts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../Schemas/Api";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import back from "../Images/back_arrow.png";
import { useNavigate } from "react-router-dom";

const FREENauticalCharts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setloading] = useState(true);
  const recordsPerPage = 10; // Assuming this matches the API response per_page value

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateNumbers = () => {
    const pagesToShow = 5; // Number of pages to show before and after the current page
    const pages = [];

    if (totalPages <= pagesToShow * 2 + 1) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= pagesToShow * 2) {
        for (let i = 1; i <= pagesToShow * 2 + 1; i++) {
          pages.push(i);
        }
        pages.push("...");
        pages.push(totalPages);
      } else if (currentPage > totalPages - pagesToShow * 2) {
        pages.push(1);
        pages.push("...");
        for (let i = totalPages - pagesToShow * 2; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push("...");
        for (
          let i = currentPage - pagesToShow;
          i <= currentPage + pagesToShow;
          i++
        ) {
          pages.push(i);
        }
        pages.push("...");
        pages.push(totalPages);
      }
    }

    return pages;
  };

  const Numbers = calculateNumbers();

  const PortsApi = async () => {
    setloading(true);
    try {
      let portss = {
        url: `/get_chart?page=${currentPage}`,
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios(portss);
      setData(response.data.data);
      console.log(response, "response");
      setloading(false);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.log("userap...", error);
      setloading(false);
    }
  };

  useEffect(() => {
    PortsApi();
  }, [currentPage]);
  const Navigate = useNavigate();

  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views"> Florida East Coast </span>
            <span className="Sphere-span-ha-Photo"> and the Keys Charts</span>
          </div>
        </div>
      </div>
      <section className="Countries-of-tha-world-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Countries-of-tha-world-div">
          <div className="Countries-of-tha-Thard-div">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="Flag-Country-wsad"
            >
              <div className="Flag-all-logo-sapan-sec-CHART">
                <span className="Flag-all-span-sec-one">CHART #</span>
              </div>
              <div className="country-name-flag-lantha-sec">
                <span className="Flag-all-span-sec-one"> CHART NAME</span>
              </div>
            </div>

            <div className="Countries-of-tha-Thard-div">
              {data.map((item) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="river_system-volum"
                  key={item.id}
                >
                  <div className="Flag-all-logo-sapan">
                    <span className="Flag-all-span">{item.chartId}</span>
                  </div>
                  <div className="country-name-flag-lantha">
                    <span className="Flag-all-span">{item.chart_name}</span>
                  </div>

                  <div className="country-name-logoc-Page">
                    <a href={item.image} download="image.jpg">
                      <img className="imgv-logo-free" src={item.image} alt="" />
                    </a>
                  </div>
                </div>
              ))}
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a className="page-link" onClick={prePage}>
                    Prev
                  </a>
                </li>
                {Numbers.map((n, i) => (
                  <li
                    key={i}
                    className={`page-item ${n === "..." ? "disabled" : ""} ${
                      currentPage === n ? "active" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() => n !== "..." && changeCPage(n)}
                    >
                      {n}
                    </a>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <a className="page-link" onClick={nextPage}>
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default FREENauticalCharts;
