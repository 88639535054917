import React from "react";
// import World from "../Images/logo hai bhai.png";
import World from "../Images/ayan bhai ka logo.png";
import SearchBar from "../Component/SearchBar";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const handleSearch = (address) => {
    // Perform search logic here
    console.log("Search address:", address);
  };

  const Navigate = useNavigate();
  return (
    <>
      <div className="Home-section">
        <div className="Geoghraphic-img-div">
          <div className="serach-and-mogo">
            <div
              onClick={() => Navigate("/")}
              className="serach-ke-liya-mhanat"
            >
              <img className="World-img" src={World} alt="" />
              <div>
                <span className="Geoghraphic-span">I-Geoghraphics</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
