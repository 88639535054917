import React, { useState, useEffect } from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import "../Css/VolcanoBycountry.css";
import Accordion from "react-bootstrap/Accordion";
import axios from "../Schemas/Api";
import Home from "./Home";
import Tweet from "../Images/Tweetlogo.png";
import back from "../Images/back_arrow.png";
import { useNavigate } from "react-router-dom";
import Like from "../Images/Likelogo.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const VolcanoBycountry = () => {
  const [AirlineData, setAirlineData] = useState([]);
  const [loading, setloading] = useState(true);
  console.log(AirlineData, "AirlineData");
  const countrydata = async () => {
    setloading(true);

    try {
      let Flagyy = {
        url: "Volcanoes_by_country",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons);
          setloading(false);
          setAirlineData(respons.countries);
        });
    } catch (error) {
      console.log("userap...", error);
      setloading(false);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);

  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views"> Volcanoes By </span>
            <span className="Sphere-span-ha-Photo">Country</span>
          </div>

          <div className="main-volconec-div">
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/VolcanoBycountry")}
            >
              Volcanoes by country
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/LargeEruptions")}
            >
              Recent Volcanic eruptions
            </span>

            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/ListOfVolcanic")}
            >
              Largest volcanic eruptions
            </span>
          </div>
        </div>
      </div>

      <section className="Volcanoes-country-data-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Volcanoes-country-data-div">
          <div className="accordion_div_FAQ">
            {AirlineData.map((Item, Index) => {
              return (
                <Accordion style={{ width: "100%" }}>
                  <Accordion.Item
                    className="mb-2"
                    style={{ width: "100%" }}
                    eventKey="0"
                  >
                    <Accordion.Header>
                      <div className="main_Qus_div">
                        <div className="Qusetion_No">
                          <span>{Item.No}</span>
                        </div>
                        <div className="Qusetion_text">{Item.name}</div>
                      </div>
                    </Accordion.Header>

                    {Item?.volcanoes?.map((_Item) => {
                      return (
                        <Accordion.Body className="volcanoes-started-div">
                          <div>Volcanoes {_Item}</div>
                        </Accordion.Body>
                      );
                    })}
                  </Accordion.Item>
                </Accordion>
              );
            })}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default VolcanoBycountry;
