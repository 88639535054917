import React from "react";
import "../KuiperBeltOverviw/KuiperBeltOverviw.css";
const KuiperBeltOverviw = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div">
          <div className="Overview-contain-main-div">
            <div>
              <span className="Overview-soler-span">Kuiper Belt Overview</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  The Kuiper belt is a circumstellar disc in the outer Solar
                  System. It is similar to the asteroid belt, but is far
                  larger—20 times as wide and 20–200 times as massive. Like the
                  asteroid belt, it consists mainly of small bodies or remnants
                  from when the Solar System formed. While many asteroids are
                  composed primarily of rock and metal, most Kuiper belt objects
                  are composed largely of frozen volatiles, such as methane,
                  ammonia, and water. The Kuiper belt is home to most of the
                  objects that astronomers generally accept as dwarf planets:
                  Orcus, Pluto, Haumea, Quaoar, and Makemake. Some of the Solar
                  System's moons, such as Neptune's Triton and Saturn's Phoebe,
                  may have originated in the region.
                </span>
              </div>

              <div className="mt-4 mb-5">
                <div>
                  <span className="Neptunian-soler-span">Namesake</span>
                </div>
                <span className="asteroids-main-span">
                  The region is named for astronomer Gerard Kuiper. Astronomer
                  Kenneth Edgeworth also mentioned objects beyond Pluto, and
                  thus it is sometimes referred to as the Edgeworth-Kuiper Belt.
                  Some researchers prefer to call it the Trans-Neptunian Region,
                  and refer to Kuiper Belt objects (KBOs) as trans-Neptunian
                  objects, or TNOs (trans-Neptunian objects).
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default KuiperBeltOverviw;
