import React, { useState, useEffect, useRef } from "react";
import "../Css/ContriesOfThaWorld.css";
import ContriesOfLogo from "../Images/ContriesOfThaWorld.jpg";
// import logoc from "../Images/Countrylogo10.avif";
import axios from "../Schemas/Api";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Home from "./Home";
import Tweet from "../Images/Tweetlogo.png";
import back from "../Images/back_arrow.png";
import Like from "../Images/Likelogo.png";
import { useNavigate } from "react-router-dom";
const ContriesOfThaWorld = () => {
  const [ContryData, setContryData] = useState([]);
  const [ContryDataSec, setContryDataSec] = useState(false);
  console.log(ContryDataSec, "ContryDataSec");
  //   console.log(ContryData.country_name, "ContryData");
  const [sortOrder, setSortOrder] = useState("");
  const initialValue = "country_name";
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [clickCount, setClickCount] = useState(0);
  const [loading, setloading] = useState(true);
  // console.log(selectedValue, "selectedValue");
  const handleValueChange = (event) => {
    setSelectedValue(event.target.innerText);
    setClickCount(clickCount + 1);
  };

  // const getMessage = () => {
  //   if (clickCount % 2 === 0) {
  //     return "";
  //   } else {
  //     return "desc";
  //   }
  // };

  const ContriesOf = async () => {
    setloading(true);
    try {
      let poly = {
        url: "/get_geocountry",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons.data);
          setContryData(respons.data);
          setContryDataSec(false);
          setloading(false);
        });
    } catch (error) {
      console.log("userap...", error);
      setloading(false);
    }
  };
  useEffect(() => {
    ContriesOf();
  }, []);

  const handleSortingClick = (value) => {
    if (selectedValue === value) {
      setSortOrder(sortOrder === "" ? "desc" : "");
    } else {
      setSelectedValue(value);
      setSortOrder(""); // Reset sorting order when a new span is clicked
      setContryDataSec(true);
    }
  };
  console.log(sortOrder, "sortOrder");

  const array = [];
  for (let i = 65; i <= 90; i++) {
    array.push(String.fromCharCode(i));
  }

  const remaining = 26 - array.length;
  for (let i = 0; i < remaining; i++) {
    array.push(""); // Fill with empty strings
  }

  const SortC = async () => {
    setloading(true);
    try {
      const respons = await axios.post(
        "/sort_geocountry",
        {
          field: selectedValue,
          order: sortOrder,
        },
        {
          Headers: { "Content-Type": "application/json" },
        }
      );

      // console.log("res..... momomommm", respons);
      setContryData(respons.data.data);
      console.log("this is the response", respons.data.data);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    SortC();
  }, [selectedValue, sortOrder]);

  const handlID = (item) => {
    console.log(item, "item");
    Navigate("/ContriesDetails", {
      state: {
        item,
      },
    });
  };

  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div>
          <div>
            <span className="Google-span-ha-Views">
              {" "}
              Countries and Regions of the World from A to Z{" "}
            </span>{" "}
            <span className="Sphere-span-ha-Photo">
              Find by Country Name =:
            </span>
          </div>
        </div>
      </div>
      <section className="Countries-of-tha-world-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Countries-of-tha-world-div">
          <div className="Countries-of-tha-Thard-div">
            {ContryDataSec ? null : (
              <>
                <div>
                  {array.map((letter) => (
                    <a
                      className="letter-map-font"
                      key={letter}
                      href={`#${letter}`}
                      style={{ textDecoration: "none" }}
                    >
                      {""} {letter} {""}|
                    </a>
                  ))}
                </div>
              </>
            )}

            <button
              className="setContryDataSec-btn-one"
              onClick={() => setContryDataSec(!ContryDataSec)}
            >
              {" "}
              {ContryDataSec ? "Sorted Data" : "Unsorted Data"}
            </button>

            <div>
              <img className="ContriesOfLogo-img" src={ContriesOfLogo} alt="" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="wasdwasdwasd-Flag-div"
            >
              <div className="Flag-contrys-of-the-worldis">
                <span className="Flag-all-span">Flag</span>
              </div>
              <div
                className="country-name-logoc-1"
                onClick={() => handleSortingClick("country_name")}
              >
                <span className="Flag-all-span">Country</span>
                {selectedValue === "country_name" && sortOrder === "" && "▼"}
                {selectedValue === "country_name" &&
                  sortOrder === "desc" &&
                  "▲"}
              </div>
            </div>
            {ContryDataSec ? (
              <>
                {ContryData.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="wasdwasdwasd-Flag-div"
                  >
                    <div className="country-main-flags-contryof-thea">
                      <img src={item.flag} alt="" width="20px" height="auto" />
                    </div>
                    <div
                      onClick={() => handlID(item)}
                      className="country_name-name-logoc-img"
                    >
                      <span>{item.country_name}</span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {array.map((letter) => (
                  <div
                    key={letter}
                    id={letter}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <h2>{letter} </h2>
                    {ContryData.filter(
                      (item) =>
                        item.country_name[0].toLowerCase() ===
                        letter.toLowerCase()
                    ).map((item) => (
                      <div
                        key={item.country_name}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                        className="wasdwasdwasd-Flag-div"
                      >
                        <div className="country-main-flags-contryof-thea">
                          <img
                            src={item.flag}
                            alt=""
                            width="20px"
                            height="auto"
                          />
                        </div>
                        <div
                          onClick={() => handlID(item)}
                          className="country-name-logoc-img-2"
                        >
                          <span>{item.country_name}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default ContriesOfThaWorld;
