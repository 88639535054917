import React from "react";
import SunImg from "../../Images/SunImg.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import "../SunSystem/SunSystem.css";
import Navbar from "../../NavBar/Navbar";
import SunOverview from "../SunOverview/SunOverview";
const SunSystem = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={SunImg} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">The Sun</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  The Sun is a yellow dwarf star, a hot ball of glowing gases at
                  the heart of our solar system.
                </span>
              </div>
              <div className="our-solar-span">
                <span className="UR-SOLAR-SYSTEM-span">TODAY</span>
              </div>
              <div className="Dwarf-Planets-div-do-Missions">
                <div className="Planets-etha-div">
                  <span className="eath-span">19</span>
                  <span className="Planets-main-sapn">Active Missions</span>
                </div>
                <div className="Planets-etha-div">
                  <span className="eath-span">13</span>
                  <span className="Planets-main-sapn">Upcoming Missions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SunOverview />
    </>
  );
};

export default SunSystem;
