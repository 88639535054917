import React, { useEffect, useRef, useState } from "react";
import "../Css/StreetViewPage.css";
import StreetViewMessage from "../Component/StreetViewMessage";
import SearchBar from "./SearchBar";
import { useLocation } from "react-router-dom";
import Home from "./Home";
import loadjs from "loadjs";
import { useNavigate } from "react-router-dom";
import back from "../Images/back_arrow.png";
const StreetViewPage = () => {
  const navigate = useNavigate();
  const [foundNotfound, setfoundNotfound] = useState(false);
  const mapContainerRef = useRef(null);
  const streetViewContainerRef = useRef(null);
  const mapRef = useRef(null);
  const streetViewPanoramaRef = useRef(null);
  const latlong = JSON.parse(localStorage.getItem("latlng"));
  const [streetViewAvailable, setStreetViewAvailable] = useState(true);
  const [lat, setLat] = useState({
    lat: latlong?.lat || null,
    lon: latlong?.lng || null,
  });
  const route = useLocation();
  const Address = route.state?.no;
  useEffect(() => {
    if (Address) {
      localStorage.setItem("address", JSON.stringify(Address.address));
    }
  }, [Address]);

  const handleSearch = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyB86XJujeIEHyCA9YZE15d3rKYfKNVfP0A&libraries=places`
      );
      const data = await response.json();
      console.log(data, "data found");

      if (data.results && data.results.length > 0) {
        console.log(data.results.length);
        const result = data.results[0];
        const { lat, lng } = result.geometry.location;
        setLat({ lat: lat, lon: lng });
      }
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };
  useEffect(() => {
    const storedAddress = JSON.parse(localStorage.getItem("address"));

    if (storedAddress) {
      console.log(storedAddress);

      handleSearch(storedAddress);
    }
  }, []);

  // const initializeStreetView = () => {
  //   const panoramaOptions = {
  //     position: { lat: lat.lat, lng: lat.lon },
  //     pov: { heading: 0, pitch: 0 },
  //     zoom: 1,
  //   };

  //   mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
  //     center: { lat: lat.lat, lng: lat.lon },
  //     zoom: 14,
  //   });

  //   streetViewPanoramaRef.current = new window.google.maps.StreetViewPanorama(
  //     streetViewContainerRef.current,
  //     panoramaOptions
  //   );

  //   mapRef.current.setStreetView(streetViewPanoramaRef.current);

  //   const marker = new window.google.maps.Marker({
  //     position: { lat: lat.lat, lng: lat.lon },
  //     map: mapRef.current,
  //   });
  //   console.log(marker);
  // };

  // useEffect(() => {
  //   initializeStreetView();
  // }, [mapRef]);
  const initializeStreetView = () => {
    const panoramaOptions = {
      position: { lat: lat.lat, lng: lat.lon },
      pov: { heading: 0, pitch: 0 },
      zoom: 1,
    };

    mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
      center: { lat: lat.lat, lng: lat.lon },
      zoom: 14,
    });

    streetViewPanoramaRef.current = new window.google.maps.StreetViewPanorama(
      streetViewContainerRef.current,
      panoramaOptions
    );

    // Listen for status changes
    streetViewPanoramaRef.current.addListener("status_changed", () => {
      if (streetViewPanoramaRef.current.getStatus() === "ZERO_RESULTS") {
        setStreetViewAvailable(false);
        // Handle the case when street view is not available
        // You can choose to display a message or take any other action.
      }
    });

    mapRef.current.setStreetView(streetViewPanoramaRef.current);

    const marker = new window.google.maps.Marker({
      position: { lat: lat.lat, lng: lat.lon },
      map: mapRef.current,
    });
    console.log(marker);
  };

  useEffect(() => {
    const loadGoogleMapsAPI = () => {
      loadjs(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyB86XJujeIEHyCA9YZE15d3rKYfKNVfP0A&libraries=places",
        {
          success: initializeStreetView,
          error: () => {
            console.error("Failed to load Google Maps API");
          },
        }
      );
    };

    if (!window.google) {
      loadGoogleMapsAPI();
      console.log("call");
    } else {
      initializeStreetView();
      console.log("nhi");
    }
  }, []);
  useEffect(() => {
    if (
      window.google &&
      window.google.maps &&
      streetViewPanoramaRef.current &&
      lat.lat &&
      lat.lon
    ) {
      // Update Street View panorama options and position
      streetViewPanoramaRef.current.setOptions({
        position: { lat: lat.lat, lng: lat.lon },
        pov: { heading: 0, pitch: 0 },
        zoom: 1,
      });

      // Update map center and marker position
      mapRef.current.setCenter({ lat: lat.lat, lng: lat.lon });
      mapRef.current.panTo({ lat: lat.lat, lng: lat.lon });
      // Update the marker position (if needed)

      // Optionally, update marker position on the map
      const marker = new window.google.maps.Marker({
        position: { lat: lat.lat, lng: lat.lon },
        map: mapRef.current,
      });
      console.log(marker);
    }
  }, [lat.lat, lat.lon]);

  // useEffect(() => {
  //   if (
  //     window.google &&
  //     window.google.maps &&
  //     streetViewPanoramaRef.current &&
  //     lat.lat &&
  //     lat.lon
  //   ) {
  //     streetViewPanoramaRef.current.setVisible(false);

  //     const panoramaOptions = {
  //       position: { lat: lat.lat, lng: lat.lon },
  //       pov: { heading: 0, pitch: 0 },
  //       zoom: 1,
  //     };
  //     streetViewPanoramaRef.current = new window.google.maps.StreetViewPanorama(
  //       streetViewContainerRef.current,
  //       panoramaOptions
  //     );

  //     mapRef.current.setStreetView(streetViewPanoramaRef.current);

  //     const marker = new window.google.maps.Marker({
  //       position: { lat: lat.lat, lng: lat.lon },
  //       map: mapRef.current,
  //     });
  //     console.log(marker);
  //   }
  // }, [lat.lat, lat.lon]);

  return (
    <>
      <Home />
      <div onClick={() => navigate(-1)} className="back-map-arry">
        <img src={back} className="Back-btn-ok" alt="" />
        <span>Back</span>
      </div>
      {foundNotfound ? (
        <>Bhai tru ho gaya</>
      ) : (
        <div className="streetview-container">
          <div className="search-container">
            {/* <SearchBar handleSearch={handleSearch} /> */}
          </div>
          {streetViewAvailable ? (
            <>
              <div className="streetview" ref={streetViewContainerRef} />
              <div className="map-container" ref={mapContainerRef} />
            </>
          ) : (
            <>
              {" "}
              <StreetViewMessage />
              <div className="map-container" ref={mapContainerRef} />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default StreetViewPage;
