import React from "react";
import planet from "../../Images/MeteorsandMeteorites.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import Navbar from "../../NavBar/Navbar";
import MeteorsandMeteoritesOverView from "../MeteorsandMeteoritesOverView/MeteorsandMeteoritesOverView";
const MeteorsandMeteorites = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Ceres-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Meteors and Meteorites</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MeteorsandMeteoritesOverView />
    </>
  );
};

export default MeteorsandMeteorites;
