import React, { useEffect, useState } from "react";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import { useLocation } from "react-router-dom";
import Loc from "../Images/Location.png";

import send from "../Images/send.png";
import Home from "./Home";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import back from "../Images/back_arrow.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const Localities = () => {
  const navigate = useNavigate();
  let route = useLocation();
  const loc = route.state.ok;

  const [street, setstreet] = useState([]);
  const [loading, setloading] = useState(true);
  const [maxIndexLength, setmaxIndexLength] = useState();
  console.log(loc, "ok");
  const onSubmit = async () => {
    setloading(true);
    try {
      const respons = await axios.post(
        "/street_data",
        {
          state_id: loc.state_id,
          city_id: loc.id,
        },
        {
          Headers: { "Content-Type": "application/json" },
        }
      );
      setstreet(respons.data.data);
      setmaxIndexLength(respons.data.data.length.toString().length);
      console.log("res.....", respons);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  // const handlId = async (no) => {
  //   navigate("/StreetViewPage", {
  //     state: {
  //       no,
  //     },
  //   });
  // };
  const handlId = async (no) => {
    window.open(
      "https://www.instantstreetview.com/s/" + no.replace(/ /g, ""),
      "_blank"
    );
    // window.location.href = "https://www.instantstreetview.com/s/" + no;
  };
  const padIndexWithZeros = (index, maxLength) => {
    const indexStr = index.toString();
    const zerosToAdd = maxLength - indexStr.length;
    return "0".repeat(zerosToAdd) + indexStr;
  };

  return (
    <>
      <Home />
      <section className="Provinces-section">
        <div className="Provinces-div">
          <div className="contain-main-div">
            {/* <div className="like-dono-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div> */}
            <div className="osam-font-he-na">
              <span className="Google-span-ha-Views">
                List of Localities in {loc.city_name}, {loc.state_name},{" "}
                {loc.country_name}.
              </span>
              <span className="Sphere-span-ha-Photo">
                Google Maps and Street View Photos
              </span>
            </div>
          </div>
        </div>

        <div className="Provinces-list">
          <div className="back-ka-div">
            <img src={back} className="Back-btn-ok" alt="" />
            <span onClick={() => navigate(-1)}>Back</span>
          </div>
          <div className="Provinces-list-contain">
            {street.map((itm, index) => {
              return (
                <>
                  <div
                    onClick={() => handlId(itm.address)}
                    className="loc-lista"
                  >
                    <>{padIndexWithZeros(index + 1, maxIndexLength)}.)</>
                    <img className="location-img" src={Loc} alt="" />
                    <span className="Badakhshan-span">{itm.address}</span>
                  </div>
                  <div className="line-ka-dady-hu">
                    <div className="line-Provinces-ka-lliya"></div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Localities;
