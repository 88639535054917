import React from "react";

const MoonsOfThaSolarSystumOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Moons Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  With the term moon we call the naturally formed bodies that
                  orbit planets or else planetary satellites.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Moons come in many shapes, sizes and types. They are generally
                  solid bodies, and few have atmospheres. Most planetary moons
                  probably formed out the discs of gas and dust circulating
                  around planets in the early solar system.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  There are hundreds of moons in our solar system.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  In the inner solar system, Mercury and Venus do not have any
                  moons at all. On the other hand, Earth has one and Mars has
                  its two small moons.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  In the outer solar system, the gas giants Jupiter and Saturn
                  and the ice giants Uranus and Neptune have dozens of moons. As
                  these planets grew in the early solar system, they were able
                  to capture smaller objects with their large gravitational
                  fields.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MoonsOfThaSolarSystumOverView;
