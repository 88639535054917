import React from "react";
import "../OortCloud/OortCloud.css";
import OortImg from "../../Images/OortCloud.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import OortCloudOverview from "../OortCloudOverview/OortCloudOverview";
import Navbar from "../../NavBar/Navbar";
const OortCloud = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={OortImg} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Oort Cloud</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  The Oort Cloud is the most distant region of our solar system.
                  Even the nearest objects in the Oort Cloud are thought to be
                  many times farther from the Sun than the outer reaches of the
                  Kuiper Belt.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OortCloudOverview />
    </>
  );
};

export default OortCloud;
