import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./AllDwarfPlanets.css";
import "swiper/css";
import left from "../../Images/left-arrow.png";
import Right from "../../Images/arrow-right.png";
import Plan1 from "../../Images/Plutodwro.jpg";
import Plan2 from "../../Images/CeresDrow.jpg";
import Plan3 from "../../Images/Makemakedrow.webp";
import Plan4 from "../../Images/HaumeaGrow.jpg";
import Plan5 from "../../Images/ErisGrow.jpg";
import { Link } from "react-router-dom";
const AllDwarfPlanets = () => {
  const btnref = useRef(null);
  const data = [
    {
      img: Plan1,
      title: "Pluto",

      link: "/Pluto",
    },
    {
      img: Plan2,
      title: "Ceres",
      link: "/Ceres",
    },
    {
      img: Plan3,
      title: "Makemake ",
      link: "/Makemake",
    },
    {
      img: Plan4,
      title: "Haumea ",
      link: "/Haumea",
    },
    {
      img: Plan5,
      title: "Eris ",
      link: "/Eris",
    },
  ];
  return (
    <>
      <section className="Section-Four-Main-Eris" id="Method-use-Scroll">
        <div className="Perent-Fours">
          <div className="Swiper-Button">
            <div onClick={() => btnref.current.swiper.slidePrev()}>
              <img src={left} alt="lefts" className="left-Right" />
            </div>

            <Swiper
              ref={btnref}
              slidesPerGroup={1}
              slidesPerView={3}
              loop={true}
              spaceBetween={0}
              // autoplay={{
              //   delay: 2000,
              // }}
              // modules={[Autoplay]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                770: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1358: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
                1920: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              <div
                style={{
                  width: "1200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {data.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      {item.link ? (
                        <Link className="Link-text-in" to={item.link}>
                          <div className="Our-methods-class">
                            <div className="Name-and-Head">
                              <img
                                src={item.img}
                                alt="None"
                                className="Img-section-four"
                              />
                              <div className="title-heading">
                                <span>{item.title}</span>
                              </div>
                              <div className="Para-heading">
                                <span>{item.Para}</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <Link to={item.link}>
                          <div className="Our-methods-class">
                            <div className="Name-and-Head">
                              <img
                                src={item.img}
                                alt="None"
                                className="Img-section-four"
                              />
                              <div className="title-heading">
                                <span>{item.title}</span>
                              </div>
                              <div className="Para-heading">
                                <span>{item.Para}</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      )}
                    </SwiperSlide>
                  );
                })}
              </div>
            </Swiper>

            <div onClick={() => btnref.current.swiper.slideNext()}>
              <img src={Right} alt="lefts" className="left-Right" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllDwarfPlanets;
