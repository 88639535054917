import React from "react";
import OortClou2 from "../../../Images/SaturnMoonImg1.jpg";
import OortClou3 from "../../../Images/SaturnMoonimg2.avif";
import OortClou4 from "../../../Images/SaturnMoonimg3.png";
const SaturnMoonOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Saturn Moon Overview </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  The moons of Saturn are numerous and diverse, ranging from
                  tiny moonlets only tens of meters across to the enormous
                  Titan, which is larger than the planet Mercury.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  As of June 8, 2023, Saturn has 146 moons with confirmed
                  orbits. This number does not include the many thousands of
                  moonlets embedded within Saturn's dense rings.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Seven Saturnian moons are large enough to have collapsed into
                  a relaxed, ellipsoidal shape, though only one or two of those,
                  Titan and possibly Rhea, are currently in hydrostatic
                  equilibrium.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Three moons are particularly notable. Titan is the
                  second-largest moon in the Solar System (after Jupiter's
                  Ganymede), with a nitrogen-rich Earth-like atmosphere and a
                  landscape featuring river networks and hydrocarbon lakes.
                  Enceladus emits jets of ice from its south-polar region and is
                  covered in a deep layer of snow. Iapetus has contrasting black
                  and white hemispheres as well as an extensive ridge of
                  equatorial mountains among the tallest in the solar system.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Of the known moons, 24 are regular satellites they have
                  prograde orbits. The remaining 122 are irregular satellites,
                  whose orbits are much farther from Saturn. These moons are
                  probably captured minor planets, or fragments from the
                  collisional breakup of such bodies after they were captured,
                  creating collisional families.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Here it is essential to mention that the rings of Saturn are
                  made up of objects ranging in size from microscopic to
                  moonlets hundreds of meters across, each in its own orbit
                  around Saturn. For this reason, a precise number of Saturnian
                  moons cannot be given, because there is no objective boundary
                  between the countless small anonymous objects that form
                  Saturn's ring system and the larger objects that have been
                  named as moons.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  There are 83 designated moons of Saturn that are still
                  unnamed.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">
                Names of Saturn Moons
              </span>
            </div>
            <span className="asteroids-main-span">
              Aegaeon, Aegir, Albiorix, Alvaldi, Albiorix, Alvaldi, Angrboda,
              Anthe, Atlas, Bebhionn, Beli, Bergelmir, Bestla, Calypso, Daphnis,
              Dione, Eggther, Enceladus, Epimetheus, Erriapus, Farbauti, Fenrir,
              Fornjot, Geirrod, Gerd, Greip, Gridr, Gunnlod, Hati, Helene,
              Hyperion, Hyrrokkin, Iapetus, Ijiraq, Janus, Jarnsaxa, Kari,
              Kiviuq, Loge, Methone, Mimas, Mundilfari, Narvi, Paaliaq, Pallene,
              Pan, Pandora, Phoebe, Polydeuces, Prometheus, Rhea, Siarnaq,
              Skathi, Skoll, Skrymir, Surtur, Suttungr, Tarqeq, Tarvos, Telesto,
              Tethys, Thiazzi, Thrymr, Titan, Ymir, plus others yet to receive
              names
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default SaturnMoonOverView;
