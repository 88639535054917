import React, { useState, useEffect } from "react";
import "../Css/ListOfBusiestContainer.css";
import ContriesOfLogo from "../Images/ContriesOfThaWorld.jpg";
import axios from "../Schemas/Api";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Home from "./Home";
import back from "../Images/back_arrow.png";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import { useNavigate } from "react-router-dom";

const ListOfBusiestContainer = () => {
  const [ContryData, setContryData] = useState([]);
  const [ContryDataSec, setContryDataSec] = useState(false);
  const [sortOrder, setSortOrder] = useState("");
  const initialValue = "country";
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [clickCount, setClickCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortedData, setSortedData] = useState([]);

  const handleValueChange = (event) => {
    setSelectedValue(event.target.innerText);
    setClickCount(clickCount + 1);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/List_of_busiest_container_ports");
      setContryData(response.data.data);
      setLoading(false);
      console.log(response.data.data, "response.data.data");
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchSortedData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/List_of_busiest_container_ports",
        {
          field: selectedValue,
          order: sortOrder,
        },
        {
          Headers: { "Content-Type": "application/json" },
        }
      );
      setSortedData(response.data.data);
      console.log(response.data.data, "ban na swar na");
      setLoading(false);
    } catch (error) {
      console.log("Error fetching sorted data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Initial data fetch
  }, []);

  useEffect(() => {
    fetchSortedData(); // Fetch sorted data when selectedValue or sortOrder changes
  }, [selectedValue, sortOrder]);

  const handleSortingClick = (value) => {
    setSelectedValue(value);

    // Check if the "country" button was clicked three times
    if (value === "country" && clickCount === 2) {
      // Perform the data fetch here
      fetchData();
      setContryDataSec(false); // Reset sorting
      setSortOrder("desc");
      setClickCount(0); // Reset click count
    } else {
      // For other buttons, continue with sorting logic
      setSortOrder(sortOrder === "" ? "desc" : "");
      setContryDataSec(true);
      setClickCount(clickCount + 1);
    }
  };
  const array = [];
  for (let i = 65; i <= 90; i++) {
    array.push(String.fromCharCode(i));
  }

  const remaining = 26 - array.length;
  for (let i = 0; i < remaining; i++) {
    array.push(""); // Fill with empty strings
  }

  const years = ["2020", "2019", "2018", "2017", "2016", "2015", "2014"];
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          <div>
            <span className="Google-span-ha-Views">
              {" "}
              List of busiest airports
            </span>
            <span className="Sphere-span-ha-Photo"> by passenger traffic</span>
          </div>
        </div>
      </div>
      <section className="Countries-of-tha-world-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Countries-of-tha-world-div">
          <div className="Countries-of-tha-Thard-div">
            <span className="busiest-btn-one">
              List of busiest airports by passenger traffic
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="passanger-busies"
            >
              <div
                className="Region-name-logoc-1"
                onClick={() => handleSortingClick("country")}
              >
                <span className="airport-all-span">country</span>
                {selectedValue === "country" && sortOrder === "" && "▼"}
                {selectedValue === "country" && sortOrder === "desc" && "▲"}
              </div>
              <div
                className="Region-name-logoc-2"
                onClick={() => handleSortingClick("location")}
              >
                <span className="airport-all-span">location</span>
                {selectedValue === "location" && sortOrder === "" && "▼"}
                {selectedValue === "location" && sortOrder === "desc" && "▲"}
              </div>
              <div
                className="Region-logoc-3"
                onClick={() => handleSortingClick("port_name")}
              >
                <span className="airport-all-span"> Port Name</span>
                {selectedValue === "port_name" && sortOrder === "" && "▼"}
                {selectedValue === "port_name" && sortOrder === "desc" && "▲"}
              </div>
              <div
                className="Region-name-logoc-4"
                onClick={() => handleSortingClick("region")}
              >
                <span className="airport-all-span">Region</span>
                {selectedValue === "region" && sortOrder === "" && "▼"}
                {selectedValue === "region" && sortOrder === "desc" && "▲"}
              </div>

              {years.map((year) => (
                <div key={year} className="Region-name-logoc-6">
                  <span className="airport-all-span">{year}</span>
                </div>
              ))}
            </div>

            {ContryDataSec ? (
              <>
                {sortedData.map((item, i) => (
                  <div
                    key={item.port_name + i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="passanger-busies"
                  >
                    <div className="port-name-logoc-img-2">
                      <span className="main-airpot-span">{item.country}</span>
                    </div>
                    <div className="port-name-logoc-img-2">
                      <span className="main-airpot-span">{item.location}</span>
                    </div>
                    <div className="port-name-logoc-img-3">
                      <span className="main-airpot-span">{item.port_name}</span>
                    </div>
                    <div className="port-name-logoc-img-4">
                      <span className="main-airpot-span">{item.region}</span>
                    </div>
                    {item.data.map((data, index) => (
                      <div key={index} className="port-name-logoc-img-4">
                        <span className="main-airpot-span">
                          {data[years[index]]}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {ContryData.map((item, i) => (
                  <div
                    key={item.port_name + i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="passanger-busies"
                  >
                    <div className="port-name-logoc-img-2">
                      <span className="main-airpot-span">{item.country}</span>
                    </div>
                    <div className="port-name-logoc-img-2">
                      <span className="main-airpot-span">{item.location}</span>
                    </div>
                    <div className="port-name-logoc-img-3">
                      <span className="main-airpot-span">{item.port_name}</span>
                    </div>
                    <div className="port-name-logoc-img-4">
                      <span className="main-airpot-span">{item.region}</span>
                    </div>
                    {item.data.map((data, index) => (
                      <div key={index} className="port-name-logoc-img-4">
                        <span className="main-airpot-span">
                          {data[years[index]]}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default ListOfBusiestContainer;
