import React from "react";
import OortClou2 from "../../Images/NormalMatterOverViewImg.webp";
import OortClou3 from "../../Images/NormalMatterImg.webp";
import OortClou4 from "../../Images/NormalMatterover2.jpg";

const NormalMatterOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">What is Normal Matter</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Normal matter, often referred to as baryonic matter,
                  constitutes the familiar and observable building blocks of the
                  universe. It includes the elementary particles that form
                  atoms, the basic units of ordinary matter. This category
                  encompasses a wide range of substances, from the air we
                  breathe to the stars that illuminate the night sky.
                </span>
              </div>

              <div className="mt-4 ">
                <span className="asteroids-main-span">
                  Normal matter, despite constituting only a small fraction of
                  the total mass-energy content of the universe, is crucial for
                  the formation of structures and the emergence of complexity.
                  Its behavior and interactions, governed by fundamental forces,
                  shape the observable universe and contribute to the intricate
                  cosmic tapestry that we explore and seek to understand.
                </span>
              </div>
            </div>
          </div>

          <div className="OortClou3-main-div mb-5">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">
                Key Characteristics of Normal Matter:
              </span>
            </div>
            <span className="asteroids-main-span">
              1. Composition: Normal matter is composed of elementary particles,
              primarily quarks and leptons. Quarks combine to form protons and
              neutrons, while electrons orbit the atomic nucleus. The most
              common elements in the universe, such as hydrogen and helium, are
              made up of these particles.
            </span>
            <div className="mt-4">
              <span>
                2. States of Matter: Normal matter exists in various states
                depending on temperature and pressure. These states include
                solid, liquid, gas, and plasma. Each state represents a distinct
                arrangement of particles, showcasing the versatility of normal
                matter under different conditions.
              </span>
            </div>
            <div className="mt-4">
              <span>
                3. Elemental Abundance: Hydrogen and helium are the most
                abundant elements in the universe, formed in the early stages of
                cosmic evolution. Heavier elements, such as carbon, oxygen, and
                iron, are forged in the cores of stars through nuclear fusion
                and are dispersed into space during stellar processes like
                supernovae.
              </span>
            </div>
            <div className="mt-4">
              <span>
                4. Cosmic Structures: Normal matter plays a central role in the
                formation of cosmic structures. Galaxies, stars, and planets are
                composed predominantly of normal matter. These structures arise
                from the gravitational interaction and coalescence of gas and
                dust clouds, which eventually give birth to stars and planetary
                systems.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NormalMatterOverView;
