import React from "react";
import planet from "../../Images/MakemakeMainimg.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import MakemakeOverView from "../MakemakeOverView/MakemakeOverView";
import { useNavigate } from "react-router-dom";
import { IoChevronBackCircle } from "react-icons/io5";
import Navbar from "../../NavBar/Navbar";
const Makemake = () => {
  const Navigate = useNavigate();
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div onClick={() => Navigate(-1)} className="Back-Eris-moon">
            <IoChevronBackCircle className="IoChevronBackCircle-main-h" />
            <span>Back</span>
          </div>
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Ceres-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Makemake</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MakemakeOverView />
    </>
  );
};

export default Makemake;
