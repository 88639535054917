import React from "react";
import "../MoonsinOurSolarSystem/MoonsinOurSolarSystem.css";
import Moon from "../../../Images/EarthsMoonimg.jpg";
import Moon2 from "../../../Images/MarsMoon.jpg";
import Moon3 from "../../../Images/JupiterMoons.avif";
import Moon4 from "../../../Images/SaturnMoon.webp";
import { FaCircleArrowRight } from "react-icons/fa6";
import Moon5 from "../../../Images/UranusMoons.jpg";
import Moon6 from "../../../Images/Neptunemoons.jpg";
import Moon7 from "../../../Images/PlutoMoonimg.jpg";
import { Link } from "react-router-dom";
const MoonsinOurSolarSystem = () => {
  const data = [
    {
      img: Moon,
      MoonName: "Earth Moon",
      MoonSpan:
        "   Our only natural satellite is a stabilizing force that makes the planet a more pleasant place for life.",
      entarimg: Moon,
      link: "/EarthMoon",
    },
    {
      img: Moon2,
      MoonName: "Mars Moon",
      MoonSpan:
        "  Two tiny moons orbit the Red Planet. One, Phobos, is slowly being torn apart by Mars.",
      entarimg: Moon,
      link: "/MersMoon",
    },
    {
      img: Moon3,
      MoonName: "Jupiter Moon",
      MoonSpan:
        "  Moons swarm around this giant world, including icy Europa with its hidden ocean.",
      entarimg: Moon,
      link: "/JupiterMoon",
    },
    {
      img: Moon4,
      MoonName: "Saturn Moon",
      MoonSpan:
        " Some moons of Saturn shape its beautiful rings. One is equal in size to a planet.",
      entarimg: Moon,
      link: "/SaturnMoon",
    },
    {
      img: Moon5,
      MoonName: "Uranus Moon",
      MoonSpan:
        " Moons of this distant ice giant are named for great literature.",
      entarimg: Moon,

      link: "/UranusMoon",
    },
    {
      img: Moon6,
      MoonName: "Neptune Moon",
      MoonSpan: "Icy Triton may be a captured world from the Kuiper Belt.",
      entarimg: Moon,
      link: "/NeptuneMoon",
    },
    {
      img: Moon7,
      MoonName: "Pluto Moon",
      MoonSpan: "The moon Charon is so big it makes Pluto wobble.",
      entarimg: Moon,
      link: "/PlutoMoon",
    },
  ];

  return (
    <>
      <section className="MoonsinOurSolarSystem-main-sec">
        <div className="MoonsinOurSolarSystem-min-div">
          <div>
            <span>Moons in Our Solar System</span>
          </div>
          {data.map((itm, index) => {
            return (
              <div className="line-contin-div-m mt-4" key={index}>
                <div className="Moons-line"></div>
                <div className="errow-and-spn">
                  <div className="Moon-main-Earth-div">
                    <img className="Moon-main-img" src={itm.img} alt="" />
                    <span>{itm.MoonName}</span>
                  </div>
                  <div className="Moon-main-Earth-div">
                    <div className="natural-span">
                      <span>{itm.MoonSpan}</span>
                    </div>
                    {itm.link ? (
                      <Link to={itm.link}>
                        <FaCircleArrowRight className="FaCircleArrowRight-main-errow" />
                      </Link>
                    ) : (
                      <FaCircleArrowRight className="FaCircleArrowRight-main-errow" />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="mb-5 mt-3 Moons-line"></div>
        </div>
      </section>
    </>
  );
};

export default MoonsinOurSolarSystem;
