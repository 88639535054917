import React from "react";
import OortClou2 from "../../Images/GalaxiesOver1.jpg";
import OortClou3 from "../../Images/Galaxiesover2.jpg";
import OortClou4 from "../../Images/GalaxiesOver3.jpg";
const GalaxiesOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Galaxies OverView</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Galaxies are awe-inspiring cosmic entities, each a unique
                  testament to the grandeur and complexity of the universe. The
                  exploration of galaxies provides a window into the cosmic
                  past, present, and future, unraveling the mysteries of our
                  cosmic home.
                </span>
              </div>
            </div>
            <div className="mt-4 mb-5">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Galaxies: Cosmic Islands of Stars and Dark Matter
                </span>
              </div>
              <span className="asteroids-main-span">
                A galaxy is a vast, gravitationally bound system comprising
                stars, stellar remnants, interstellar gas, dust, dark matter,
                and various celestial objects. These colossal structures,
                ranging in size from tens of thousands to hundreds of billions
                of stars, represent the fundamental building blocks of the
                observable universe. Galaxies come in a variety of shapes,
                sizes, and structures, each telling a unique story about its
                formation, evolution, and cosmic environment.
              </span>
            </div>
            <div className="mt-4 mb-5">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Key Features of Galaxies:
                </span>
              </div>
              <span className="asteroids-main-span">
                1. Stars and Stellar Systems: Galaxies are composed of an
                enormous number of stars, often organized into various
                structures such as spiral arms, elliptical shapes, or irregular
                patterns. These stars form intricate constellations within the
                galactic framework.
              </span>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  2. Interstellar Medium: The interstellar medium within
                  galaxies consists of gas (mostly hydrogen and helium) and
                  dust. These materials play a crucial role in the formation of
                  new stars and serve as the raw ingredients for cosmic
                  processes.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  3. Dark Matter Halos: Dark matter, an invisible and mysterious
                  form of matter, envelops galaxies in massive halos. Although
                  not directly observable, its gravitational influence is
                  evident in the motions of stars and galaxies, providing the
                  cosmic scaffolding that shapes the visible structures.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  4. Galactic Morphology: Galaxies exhibit diverse morphologies.
                  Spiral galaxies, characterized by winding arms, elliptical
                  galaxies with a smooth, football-like appearance, and
                  irregular galaxies with a less defined structure are among the
                  main classifications.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  5. Supermassive Black Holes: Many galaxies, including our
                  Milky Way, harbor supermassive black holes at their centers.
                  These cosmic giants exert a gravitational influence on
                  surrounding matter and can release immense amounts of energy.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  6. Galactic Groups and Clusters: Galaxies are not isolated
                  entities but often exist in groups or clusters. These cosmic
                  communities are bound together by gravity, and their
                  interactions shape the larger-scale structure of the universe.
                </span>
              </div>
            </div>
            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Formation and Evolution:
                </span>
              </div>
              <span className="asteroids-main-span">
                The formation of galaxies is intricately tied to the early
                universe's conditions, with gravity playing a central role in
                pulling matter together. Over cosmic time, galaxies evolve
                through processes such as mergers, interactions, and the
                continuous formation of new stars.
              </span>
            </div>
            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Observation and Classification:
                </span>
              </div>
              <span className="asteroids-main-span">
                Astronomers observe and classify galaxies based on their
                appearance, spectral characteristics, and other properties.
                Telescopes, both ground-based and space-based, allow researchers
                to explore the depths of the universe and uncover the diverse
                array of galaxies scattered throughout space.
              </span>
            </div>
            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Significance in Cosmology:
                </span>
              </div>
              <span className="asteroids-main-span">
                The study of galaxies is fundamental to our understanding of
                cosmology—the large-scale structure and evolution of the
                universe. Galaxies serve as cosmic laboratories, offering
                insights into the distribution of matter, the nature of dark
                matter and dark energy, and the cosmic web that connects them.
              </span>
            </div>
          </div>
          <div className="OortClou3-main-div mb-5">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default GalaxiesOverView;
