import React from "react";

const DwarfPlanetsOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div">
          <div className="Overview-contain-main-div">
            <div>
              <span className="Overview-soler-span">
                Dwarf Planets Overview
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <span className="Neptunian-soler-span">
                What is a Dwarf Planet?
              </span>
              <div className="mt-3">
                <span className="asteroids-main-span">
                  A dwarf planet is a small planetary-mass object that is in
                  direct orbit of the Sun, smaller than any of the eight
                  classical planets.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The International Astronomical Union (IAU), a world
                  organization of astronomers, came up with the definition of a
                  planet in 2006. According to the IAU, a planet must do three
                  things:
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  a. Orbit its host star (In our solar system that’s the Sun).
                </span>
              </div>
              <div className="">
                <span className="asteroids-main-span">b. Be mostly round.</span>
              </div>
              <div className="">
                <span className="asteroids-main-span">
                  c. Be big enough that its gravity cleared away any other
                  objects of similar size near its orbit around the Sun.aller
                  worlds called dwarf planets reign, including Pluto.
                </span>
              </div>
              <div className="mb-5">
                <span className="asteroids-main-span">
                  Dwarf planets like Pluto were defined as objects that orbit
                  the Sun, and are nearly round, but have not been able to clear
                  their orbit of debris.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DwarfPlanetsOverView;
