import React from "react";
import "../KuiperBeltSystem/KuiperBeltSystem.css";
import KuiperBelt from "../../Images/KuiperBelt.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import KuiperBeltOverviw from "../KuiperBeltOverviw/KuiperBeltOverviw";
import Navbar from "../../NavBar/Navbar";
const KuiperBeltSystem = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={KuiperBelt} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">The Kuiper Belt</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  The Kuiper Belt is a doughnut-shaped region of icy objects
                  beyond the orbit of Neptune. It is home to Pluto and most of
                  the known dwarf planets and some comets.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <KuiperBeltOverviw />
    </>
  );
};

export default KuiperBeltSystem;
