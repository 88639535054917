import React, { useState, useEffect } from "react";
import "../Css/LargestSix.css";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";

const LargestSix = () => {
  const [AirlineData, setAirlineData] = useState([]);
  console.log(AirlineData, "AirlineData");
  const countrydata = async () => {
    // setloading(true);

    try {
      let Flagyy = {
        url: "Largest_airlines_in_the_world_by_fleet_size_March_2023",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons);
          setAirlineData(respons.data);
        });
    } catch (error) {
      console.log("userap...", error);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);
  return (
    <>
      <section className="List-of-largest-airlines-main-sec">
        <div className="List-of-largest-airlines-main-div ">
          <div className="List-of-largest-tha-Thard-div">
            <span className="busiest-btn-one">
              Largest Airlines in the World by Fleet Size March 2023
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="Six-and-sund-data"
            >
              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Rank</span>
              </div>
              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Airline</span>
              </div>
              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Type</span>
              </div>
              <div className="Six-name-logoc-img-3">
                <span className="airport-all-span">Fleet</span>
              </div>
            </div>
            {AirlineData?.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="Six-and-sund-data"
                >
                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.rank}</span>
                  </div>
                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.airline}</span>
                  </div>
                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.type}</span>
                  </div>
                  <div className="Six-name-logoc-img-3">
                    <span className="main-airpot-span">{itm.fleet}</span>
                  </div>
                </div>
              );
            })}
            {/* {ContryDataSec ? (
              <>
                {sortedData.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="List-of-highest-mountain"
                  >
                    <div className="mountains-name-logoc-img-1">
                      <span className="main-airpot-span">{item.country}</span>
                    </div>
                    <div className="mountains-name-logoc-img-2">
                      <span className="main-airpot-span">
                        {item.mountain_name}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-3">
                      <span className="main-airpot-span">{item.range}</span>
                    </div>
                    <div className="mountains-name-logoc-img-4">
                      <span className="main-airpot-span">
                        {item.height_meter}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-5">
                      <span className="main-airpot-span">
                        {item.prominence_feet}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-6">
                      <span className="main-airpot-span">
                        {item.prominence_meter}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-7">
                      <span className="main-airpot-span">
                        {item.parent_mountain}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-8">
                      <span className="main-airpot-span">
                        {item.height_feet}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {ContryData.map((item) => (
                  <div
                    key={item.airport}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="List-of-highest-mountain "
                  >
                    <div className="mountains-name-logoc-img-1">
                      <span className="main-airpot-span">{item.country}</span>
                    </div>
                    <div className="mountains-name-logoc-img-2">
                      <span className="main-airpot-span">
                        {item.mountain_name}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-3">
                      <span className="main-airpot-span">{item.range}</span>
                    </div>
                    <div className="mountains-name-logoc-img-4">
                      <span className="main-airpot-span">
                        {item.height_meter}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-5">
                      <span className="main-airpot-span">
                        {item.prominence_feet}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-6">
                      <span className="main-airpot-span">
                        {item.prominence_meter}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-7">
                      <span className="main-airpot-span">
                        {item.parent_mountain}
                      </span>
                    </div>
                    <div className="mountains-name-logoc-img-8">
                      <span className="main-airpot-span">
                        {item.height_feet}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )} */}
          </div>
        </div>
      </section>
    </>
  );
};

export default LargestSix;
