import React from "react";
import OortClou2 from "../../Images/Neptun1.jpg";
import OortClou3 from "../../Images/Neptun2.avif";
import OortClou4 from "../../Images/Neptune4.avif";

const NeptuneOverveiwe = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Neptune Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Neptune is the eighth and farthest planet from the Sun. It is
                  the fourth-largest planet in the Solar System by diameter, the
                  third-most-massive planet, and the densest giant planet.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  It is 17 times the mass of Earth, and slightly more massive
                  than its near-twin Uranus.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Neptune is denser and physically smaller than Uranus because
                  its greater mass causes more gravitational compression of its
                  atmosphere. Being composed primarily of gases and liquids, it
                  has no well-defined solid surface.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The planet orbits the Sun once every 164.8 years at an orbital
                  distance of 30.1 astronomical units (4.5 billion kilometres;
                  2.8 billion miles).
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Neptune is not visible to the unaided eye and is the only
                  planet in the Solar System found by mathematical predictions
                  rather than by empirical observation.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Like the gas giants (Jupiter and Saturn), Neptune's atmosphere
                  is composed primarily of hydrogen and helium, along with
                  traces of hydrocarbons and possibly nitrogen, but contains a
                  higher proportion of ices such as water, ammonia and methane.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Similar to Uranus, its interior is primarily composed of ices
                  and rock. Both planets are normally considered "ice giants" to
                  distinguish them.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Traces of methane in the outermost regions in part account for
                  the planet's blue appearance. The blue color is more saturated
                  than the one present on Uranus due to the thinner haze of
                  Neptune's more active atmosphere.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  In contrast to the hazy, relatively featureless atmosphere of
                  Uranus, Neptune's atmosphere has active and visible weather
                  patterns.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  These weather patterns are driven by the strongest sustained
                  winds of any planet in the Solar System, with recorded wind
                  speeds as high as 2,100 km/h (580 m/s; 1,300 mph).
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Because of its great distance from the Sun, Neptune's outer
                  atmosphere is one of the coldest places in the Solar System,
                  with temperatures at its cloud tops approaching 55 K (−218 °C;
                  −361 °F). Temperatures at the planet's center are
                  approximately 5,400 K (5,100 °C; 9,300 °F).
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Neptune has a faint and fragmented ring system, labelled
                  "arcs".
                </span>
              </div>

              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Neptune Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  It is named after the Roman god of the sea and has the
                  astronomical symbol ♆, representing Neptune's trident.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              With a radius of 15,299.4 miles (24,622 kilometers), Neptune is
              about four times wider than Earth.
            </span>
            <span className="asteroids-main-span">
              From an average distance of 2.8 billion miles (4.5 billion
              kilometers), Neptune is 30 astronomical units away from the Sun
              (One astronomical unit is the distance from the Sun to Earth).
              From this distance, it takes sunlight 4 hours to travel from the
              Sun to Neptune.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default NeptuneOverveiwe;
