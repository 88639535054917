import React from "react";
import planet from "../../Images/NormalMatterImg.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import NormalMatterOverView from "../NormalMatterOverView/NormalMatterOverView";
import Navbar from "../../NavBar/Navbar";
const NormalMatter = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Ceres-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Normal Matter</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  Normal Matter: the Foundations of the Observable Universe
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NormalMatterOverView />
    </>
  );
};

export default NormalMatter;
