import React, { useState, useEffect } from "react";
import "../Css/Nationalanthemsoftheworld.css";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";

import back from "../Images/back_arrow.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Nationalanthemsoftheworld = () => {
  const [AirlineData, setAirlineData] = useState([]);
  const [loading, setLoading] = useState(true);
  const countrydata = async () => {
    setLoading(true);

    try {
      let Flagyy = {
        url: "National_anthems_of_the_world",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons);
          setAirlineData(respons);
          setLoading(false);
        });
    } catch (error) {
      console.log("userap...", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views"> National Anthems </span>
            <span className="Sphere-span-ha-Photo">Of The World</span>
          </div>
        </div>
      </div>
      <section className="List-of-largest-airlines-main-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="List-of-largest-airlines-main-div ">
          <span className="busiest-btn-one">National Anthems Of The World</span>
          <div className="National-arbic-largest-tha-Thard-div mt-5">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="National-Anthems-line"
            >
              <div className="arbic-contr-11">
                <span className="airport-all-span">Country</span>
              </div>
              <div className="arbic-contr-11">
                <span className="airport-all-span">Composer/Artist</span>
              </div>
              <div className="arbic-contr-11">
                <span className="airport-all-span">Date adopted (de jure)</span>
              </div>
              <div className="arbic-contr-111">
                <span className="airport-all-span">Length</span>
              </div>

              <div className="arbic-contr-11">
                <span className="airport-all-span">Lyricist</span>
              </div>
              <div className="arbic-contry">
                <span className="airport-all-span">National anthem</span>
              </div>
            </div>
            {AirlineData.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="National-Anthems-line"
                >
                  <div className="arbic-contr-11">
                    <span className="airport-all-span">{itm.Country}</span>
                  </div>
                  <div className="arbic-contr-11">
                    <span className="airport-all-span">
                      {itm.Composer_Artist}
                    </span>
                  </div>
                  <div className="arbic-contr-11">
                    <span className="airport-all-span">
                      Date adopted (de jure)
                    </span>
                  </div>
                  <div className="arbic-contr-111">
                    <span className="airport-all-span">{itm.Length}</span>
                  </div>

                  <div className="arbic-contr-11">
                    <span className="airport-all-span">{itm.Lyricist}</span>
                  </div>
                  <div className="arbic-contry">
                    <span className="airport-all-span">
                      {itm.national_anthem}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Nationalanthemsoftheworld;
