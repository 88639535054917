import React from "react";
import "../PlanetsOverviwe/PlanetsOverviwe.css";
const PlanetsOverviwe = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div">
          <div className="Overview-contain-main-div">
            <div>
              <span className="Overview-soler-span">Planets Overview</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  A planet is a large, rounded astronomical body that is neither
                  a star nor stellar remnants.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The most widely accepted theory of planet formation is the
                  nebular hypothesis, which posits that an interstellar cloud
                  collapses out of a nebula to create a young protostar orbited
                  by a protoplanetary disk. Planets grow in this disk by the
                  gradual accumulation of material driven by gravity, a process
                  called accretion.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Solar System has at least eight planets: the terrestrial
                  or inner solar system planets Mercury, Venus, Earth, and Mars,
                  and the giant or outer planets Jupiter, Saturn, Uranus, and
                  Neptune.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  These planets each rotate around an axis tilted with respect
                  to its orbital pole. All the major planets of the Solar System
                  other than Mercury possess a considerable atmosphere, and some
                  share such features as ice caps, seasons, volcanism,
                  hurricanes, tectonics, and even hydrology. Apart from Venus
                  and Mars, the Solar System planets generate magnetic fields,
                  and all the major planets except Venus and Mercury have
                  natural satellites. The giant planets bear planetary rings,
                  the most prominent being those of Saturn.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Beyond Neptune, a newer class of smaller worlds called dwarf
                  planets reign, including Pluto.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Thousands more planets have been discovered beyond our solar
                  system. Scientists call them exoplanets (exo means "from
                  outside").
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlanetsOverviwe;
