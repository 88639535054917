import React from "react";
import OortClou2 from "../../Images/CeresDistance1.webp";
import OortClou3 from "../../Images/CeresDistance2.avif";
import OortClou4 from "../../Images/CeresDistance3.jpg";

const CeresOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Ceres Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Ceres is a dwarf planet in the middle main asteroid belt
                  between the orbits of Mars and Jupiter and it's the only dwarf
                  planet located in the inner solar system.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  It was the first asteroid discovered on 1 January 1801, by
                  Giuseppe Piazzi at Palermo Astronomical Observatory in Sicily
                  and announced as a new planet. Ceres was later classified as
                  an asteroid and then a dwarf planet, the only one always
                  inside Neptune's orbit.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Ceres's small size means that even at its brightest, it is too
                  dim to be seen by the naked eye, except under extremely dark
                  skies.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Dawn found Ceres's surface to be a mixture of water ice and
                  hydrated minerals such as carbonates and clay. Gravity data
                  suggest Ceres to be partially differentiated into a muddy
                  (ice-rock) mantle/core and a less dense but stronger crust
                  that is at most thirty percent ice by volume.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Although Ceres likely lacks an internal ocean of liquid water,
                  brines still flow through the outer mantle and reach the
                  surface, allowing cryovolcanoes such as Ahuna Mons to form
                  roughly every fifty million years. This makes Ceres the
                  closest known cryovolcanic body to the Sun, and the brines
                  provide a potential habitat for microbial life.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  In January 2014, emissions of water vapour were detected
                  around Ceres, creating a tenuous, transient atmosphere known
                  as an exosphere. This was unexpected because vapour is usually
                  a hallmark of comets, not asteroids.
                </span>
              </div>

              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Ceres Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  Ceres is named for the Roman goddess of agriculture. The word
                  cereal comes from the same name.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              With a radius of 296 miles (476 kilometers), Ceres is 1/13 the
              radius of Earth.
            </span>
            <span className="asteroids-main-span">
              From an average distance of 257 million miles (413 million
              kilometers), Ceres is 2.8 astronomical units away from the Sun
              (One astronomical unit is the distance from the Sun to Earth).
              From this distance, it takes sunlight 22 minutes to travel from
              the Sun to Ceres.akes sunlight 43 minutes to travel from the Sun
              to Jupiter.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default CeresOverView;
