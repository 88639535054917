import React from "react";
import OortClou2 from "../../Images/ErisDistance1.png";
import OortClou3 from "../../Images/ErisDistance2.jpg";
import OortClou4 from "../../Images/ErisDistance3.jpg";

const ErisOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Planet Eris Overview </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Eris is the most massive and second-largest known dwarf planet
                  in the Solar System.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  It is a trans-Neptunian object (TNO) in the scattered disk and
                  has a high-eccentricity orbit.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Eris was discovered in January 2005 by a Palomar
                  Observatory–based team led by Mike Brown and verified later
                  that year.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Eris is the ninth-most massive known object orbiting the Sun
                  and the sixteenth-most massive overall in the Solar System
                  (counting moons).
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Eris has been measured at 2,326 ± 12 kilometers (1,445 ± 7 mi)
                  in diameter.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Its mass is 0.28% that of the Earth and 27% greater than that
                  of Pluto although Pluto is slightly larger by volume. Both
                  having a surface area that is comparable to the area of Russia
                  or South America.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Eris has one large known moon, Dysnomia.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Because Eris appeared to be larger than Pluto, NASA initially
                  described it as the Solar System's tenth planet. This, along
                  with the prospect of other objects of similar size being
                  discovered in the future, motivated the International
                  Astronomical Union (IAU) to define the term planet for the
                  first time. Under the IAU definition approved on August 24,
                  2006, Eris, Pluto and Ceres are "dwarf planets", reducing the
                  number of known planets in the Solar System to eight.
                </span>
              </div>

              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Eris Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  In September 2006, it was named after the ancient Greek
                  goddess of strife and discord. The name fits since Eris
                  remains at the center of a scientific debate about the
                  definition of a planet.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              With a radius of about 722 miles (1,163 kilometers), Eris is about
              1/5 the radius of Earth. Eris, like Pluto, is a little smaller
              than Earth's Moon.
            </span>
            <span className="asteroids-main-span">
              From an average distance of 6,289,000,000 miles (10,125,000,000
              kilometers), Eris is about 68 astronomical units away from the Sun
              (One astronomical unit is the distance from the Sun to Earth).
              From this distance, it takes sunlight more than nine hours to
              travel from the Sun to the surface of Eris.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErisOverView;
