import React from "react";
import OortClou2 from "../../Images/StarlightOverView1.webp";
import OortClou3 from "../../Images/StarlightOverView2.avif";
import OortClou4 from "../../Images/StarlightOverView3.jpg";

const StarlightOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Star: Luminous Spheres of Cosmic Brilliance
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  A star is a celestial object composed primarily of hot,
                  glowing gas that emits light and other forms of radiant
                  energy. These luminous spheres serve as the fundamental
                  building blocks of galaxies and play a central role in the
                  dynamic processes shaping the cosmos. Stars are born from vast
                  clouds of gas and dust, undergo distinct life cycles based on
                  their mass, and contribute to the enrichment of the universe
                  by forging elements through nuclear fusion. This section
                  unravels the key characteristics of stars, from their
                  formation to their ultimate fate, illuminating the profound
                  role they play in the cosmic tapestry.
                </span>
              </div>
            </div>
            <div className="mt-4 mb-5">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Key Features of Stars:
                </span>
              </div>
              <span className="asteroids-main-span">
                1. Composition: Stars are primarily composed of hydrogen and
                helium, the two lightest elements in the universe. These
                elements undergo nuclear fusion in the intense heat and pressure
                at a star's core, producing heavier elements.
              </span>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  2. Formation: Stars form within vast clouds of gas and dust
                  known as nebulae or stellar nurseries. The gravitational
                  collapse of these clouds initiates the process of star
                  formation.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  3. Nuclear Fusion: At the core of a star, nuclear fusion
                  occurs, where hydrogen atoms fuse to form helium, releasing a
                  tremendous amount of energy in the process. This energy, in
                  the form of light and heat, creates the star's luminosity.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  4. Life Cycle: The life cycle of a star depends on its mass.
                  Low-mass stars, like our Sun, go through stages such as
                  protostar, main-sequence, red giant, and white dwarf.
                  High-mass stars may experience more dramatic events, such as
                  supernova explosions, leading to the formation of neutron
                  stars or black holes.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  5. Luminosity: The brightness of a star, known as its
                  luminosity, is determined by factors such as its size,
                  temperature, and age. Luminosity is a key characteristic used
                  to classify stars.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  6. Color and Temperature: Stars emit light across a spectrum
                  of colors. The color of a star is related to its surface
                  temperature, with cooler stars appearing red and hotter stars
                  appearing blue.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  7. Binary and Multiple Systems: Many stars exist in binary or
                  multiple star systems, where two or more stars orbit each
                  other. These systems provide valuable insights into stellar
                  dynamics and interactions.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  8. Stellar Remnants: The end stages of a star's life depend on
                  its mass. Low-mass stars may become white dwarfs, while more
                  massive stars can end as neutron stars or black holes
                </span>
              </div>
            </div>
            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Cosmic Significance:
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  1. Galactic Architecture: Stars are the primary components of
                  galaxies, forming structures such as spiral arms, globular
                  clusters, and galactic cores. They contribute to the overall
                  architecture and dynamics of galaxies.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  2. Elemental Enrichment: Through nuclear fusion, stars forge
                  heavier elements, including carbon, oxygen, and iron. When
                  massive stars explode in supernovae, these elements are
                  released into space, enriching the interstellar medium and
                  influencing the formation of new stars and planetary systems.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  3. Energy Sources: Stars are the primary sources of energy in
                  the universe, providing the radiant energy that sustains
                  planetary atmospheres and drives atmospheric and oceanic
                  processes.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  In the vast cosmic expanse, stars stand as luminous beacons,
                  each narrating a unique tale of cosmic birth, life, and
                  eventual transformation. Their brilliance shapes the cosmic
                  canvas, guiding our exploration and understanding of the
                  intricate dance of matter and energy across the cosmos.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div mb-5">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default StarlightOverView;
