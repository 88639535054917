import React, { useEffect, useState } from "react";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import "../Css/Provinces.css";
import Loc from "../Images/Location.png";
import send from "../Images/send.png";
import Home from "./Home";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import back from "../Images/back_arrow.png";
const Provinces = () => {
  const navigate = useNavigate();
  const [statedata, setStatedata] = useState([]);
  console.log(statedata, "statedata");
  let router = useLocation();
  const Cuntryid = router.state.id;
  console.log(Cuntryid, "jhgfdfghjkl");
  // console.log(statedata.data.states.state_name, "qazxcvbnm,loiuytrewsxcvbnjhg");
  const [loading, setloading] = useState(true);
  const [maxIndexLength, setmaxIndexLength] = useState();
  const onSubmit = async () => {
    setloading(true);
    try {
      const respons = await axios.post(
        "/state_data",
        {
          country_id: Cuntryid.id,
        },
        {
          Headers: { "Content-Type": "application/json" },
        }
      );
      setStatedata(respons.data.states);
      setmaxIndexLength(respons.data.states.length.toString().length);
      console.log("res.....", respons);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  const Send = async (id) => {
    navigate("/Districts", {
      state: {
        id,
      },
    });
  };
  const padIndexWithZeros = (index, maxLength) => {
    const indexStr = index.toString();
    const zerosToAdd = maxLength - indexStr.length;
    return "0".repeat(zerosToAdd) + indexStr;
  };
  return (
    <>
      <Home />
      <section className="Provinces-section">
        <div className="Provinces-div">
          <div className="contain-main-div">
            {/* <div className="like-dono-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div> */}
            <div className="osam-font-he-na">
              <span className="Google-span-ha-Views">
                List of Provinces in {Cuntryid?.country_name}
              </span>
              <span className="Sphere-span-ha-Photo">
                with Google Maps and Street Views
              </span>
            </div>
          </div>
        </div>

        <div className="Provinces-list">
          <div onClick={() => navigate(-1)} className="back-ka-div">
            <img src={back} className="Back-btn-ok" alt="" />
            <span>Back</span>
          </div>
          <div className="Provinces-list-contain">
            {statedata?.map((itm, index) => {
              console.log(itm, "milrha he");
              return (
                <>
                  <div onClick={() => Send(itm)} className="loc-lista">
                    <>{padIndexWithZeros(index + 1, maxIndexLength)}.)</>
                    <img className="location-img" src={Loc} alt="" />
                    <span className="Badakhshan-span">{itm?.name}</span>
                  </div>
                  <div className="line-ka-dady-hu">
                    <div className="line-Provinces-ka-lliya"></div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Provinces;
