import React from "react";
import OortClou4 from "../../Images/stype.jpg";
import OortClou2 from "../../Images/Ctype.jpg";
import OortClou3 from "../../Images/Mtype.jpg";
const AsteroidsMainOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">What is an asteroid? </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  An asteroid is a small, rocky object that orbits the Sun,
                  residing primarily in the asteroid belt—a region situated
                  between the orbits of Mars and Jupiter. An asteroid, sometimes
                  called a minor planet, is neither a true planet nor a comet.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Sizes and shapes of asteroids vary significantly, ranging from
                  1-meter rocks to a dwarf planet almost 1000 km in diameter.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Types of asteroids</span>
            </div>
            <span className="asteroids-main-span">
              Asteroids are generally classified to be of three types:
            </span>

            <div className="mt-4">
              <span className="asteroids-main-span">
                • The C-type is named after and consists of carbonaceous
                compositions,
              </span>
            </div>
            <div>
              <span className="asteroids-main-span">
                • The M-type of metallic compositions, and
              </span>
            </div>
            <div>
              <span className="asteroids-main-span">
                • The S-type consists of silicaceous materials.
              </span>
            </div>
            <div className="mt-4">
              <span className="asteroids-main-span">
                The compositional differences of asteroids are related to how
                far from the Sun they formed.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AsteroidsMainOverView;
