import React from "react";
import planet from "../../Images/PlutoMainimg.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import PlutoOverview from "../PlutoOverview/PlutoOverview";
import { useNavigate } from "react-router-dom";
import { IoChevronBackCircle } from "react-icons/io5";
import Navbar from "../../NavBar/Navbar";
const Pluto = () => {
  const Navigate = useNavigate();
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div onClick={() => Navigate(-1)} className="Back-Eris-moon">
            <IoChevronBackCircle className="IoChevronBackCircle-main-h" />
            <span>Back</span>
          </div>
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Pluto</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  Pluto's heart-shaped glacier is about the size of Texas and
                  Oklahoma.
                </span>
              </div>
              <div className="our-solar-span">
                <span className="UR-SOLAR-SYSTEM-span">TODAY</span>
              </div>
              <div className="Dwarf-Planets-div-do-Missions">
                <div className="Planets-etha-div">
                  <span className="eath-span">05</span>
                  <span className="Planets-main-sapn">Moons</span>
                </div>
                <div className="Planets-etha-div">
                  <span className="eath-span">01</span>
                  <span className="Planets-main-sapn">Missions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PlutoOverview />
    </>
  );
};

export default Pluto;
