import React from "react";
import sedo from "../../Images/dark_layer@3x.png";
import planet from "../../Images/EarthMainimg.jpg";
import EarthOverViwe from "../EarthOverViwe/EarthOverViwe";
import Navbar from "../../NavBar/Navbar";
const Earth = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Earth</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  Earth—our home planet—is the third planet from the Sun, and
                  the only place we know of so far that’s inhabited by living
                  things. It's also the only planet in our solar system with
                  liquid water on the surface.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EarthOverViwe />
    </>
  );
};

export default Earth;
