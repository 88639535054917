import React from "react";
import SolarImg from "../../Images/SolarSystem.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import "../SolarSystemExploration/SolarSystemExploration.css";
import Navbar from "../../NavBar/Navbar";
import SolarSystemOverview from "../SolarSystemOverview/SolarSystemOverview";
import { useNavigate } from "react-router-dom";
import { IoChevronBackCircle } from "react-icons/io5";
import Home from "../../Component/Home";
const SolarSystemExploration = () => {
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          <div>
            <span className="Google-span-ha-Views">Welcome To </span>
            <span className="Sphere-span-ha-Photo"> Tha Space</span>
          </div>
        </div>
      </div>

      <div onClick={() => Navigate(-1)} className="Back-Eris-moon">
        <IoChevronBackCircle className="IoChevronBackCircle-main-h" />
        <span>Back</span>
      </div>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={SolarImg} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Solar System Exploration</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  join us as we explore our planetary neighborhood: The Sun,
                  planets, moons, and millions of asteroids, and comets.
                </span>
              </div>
              <div className="our-solar-span">
                <span className="UR-SOLAR-SYSTEM-span">OUR SOLAR SYSTEM</span>
              </div>
              <div className="Dwarf-Planets-div-do">
                <div className="Planets-etha-div">
                  <span className="eath-span">8</span>
                  <span className="Planets-main-sapn">Planets</span>
                </div>
                <div className="Planets-etha-div">
                  <span className="eath-span">5</span>
                  <span className="Planets-main-sapn">Dwarf Planets</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SolarSystemOverview />
    </>
  );
};

export default SolarSystemExploration;
