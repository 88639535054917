import React from "react";
import "../MercuryOveviwe/MercuryOveviwe.css";
import OortClou2 from "../../Images/Venusorbits1.jpg";
import OortClou3 from "../../Images/Venusorbits2.jpg";
import OortClou4 from "../../Images/Venusorbits3.jpg";

const VenusOverviwe = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Planet Venus Overview</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Venus is the second planet from the Sun. It is a rocky planet
                  with the densest atmosphere of all the rocky bodies in the
                  Solar System, and the only one with a mass and size that is
                  close to that of its orbital neighbour Earth.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Orbiting inferiorly (inside of Earth's orbit), it appears in
                  Earth's sky always close to the Sun.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Despite being the smallest planet in the Solar System, Mercury
                  is dense enough to have roughly the same surface gravity as
                  Mars. Mercury has a dynamic magnetic field with a strength
                  about 1% of that of Earth's and has no natural satellites.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Venus is the third brightest object in Earth's sky after the
                  Moon and the Sun, appearing brighter than any other star-like
                  classical planet or any fixed star.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Venus has a weak induced magnetosphere and an especially thick
                  carbon dioxide atmosphere, which creates, together with its
                  global sulfuric acid cloud cover, an extreme greenhouse
                  effect.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Conditions possibly favourable for life on Venus have been
                  identified at its cloud layers, with recent research having
                  found indicative, but not convincing, evidence of life on the
                  planet. Venus may have had liquid surface water early in its
                  history, possibly enough to form oceans, but runaway
                  greenhouse effects eventually evaporated any water, which then
                  was taken into space by the solar wind.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Internally, Venus is thought to consist of a core, mantle, and
                  crust, the latter releasing internal heat through its active
                  volcanism, shaping the surface with large resurfacing instead
                  of plate tectonics.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Venus is one of two planets in the Solar System which have no
                  moons.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Venus has a rotation which has been slowed and turned against
                  its orbital direction by the strong currents and drag of its
                  atmosphere. A Venusian solar day takes 117 Earth days to be
                  completed and it takes Venus 224.7 Earth days to complete an
                  orbit around the Sun, resulting in a Venusian year being just
                  under two Venusian days long.
                </span>
              </div>
              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Venus Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  The ancient Romans named the brightest objects in the sky
                  after their most important gods. Venus, which is the third
                  brightest object after the Sun and Moon, was named after the
                  Roman goddess of love and beauty, Venus. It’s the only planet
                  named after a female god.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              Venus orbits the Sun from an average distance of 67 million miles
              (108 million kilometers), or 0.72 astronomical units (it is the
              distance from the Sun to Earth). It takes sunlight about six
              minutes to travel from the Sun to Venus.
            </span>
            <span className="asteroids-main-span">
              From Earth, Venus is the brightest object in the night sky after
              our own Moon and it is some 38 million miles (about 61 million
              kilometers) away.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default VenusOverviwe;
