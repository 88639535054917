import React, { useRef } from "react";
import "../Css/Wondersoftheworld.css";
import Piramit from "../Images/piramit.jpg";
import Mumyy from "../Images/Mummyy.jpg";
import Hangin from "../Images/Hangin.jpg";
import Laghithouse from "../Images/LighthouseThiersch.png";
import lejump from "../Images/Lejumpo.jpg";
import mausolem from "../Images/Mausoleum.jpg";
import mini from "../Images/Miniaturk.jpg";
import Shoqaf from "../Images/SHOQAF.jpg";
import Close from "../Images/Colosseo.jpg";
import Wall from "../Images/WallofChina_a.jpg";
import Aya from "../Images/Ayaworld.jpg";
import Lala from "../Images/lala1.jpg";
import Toun from "../Images/cahinatoun.jpg";
import Stonehenge from "../Images/Stonehenge.jpg";
import Aurora from "../Images/Church.jpg";
import Canyon from "../Images/Canyon.jpeg";
import Mount from "../Images/Mount.jpg";
import Roi from "../Images/Rio.jpg";
import Parícutin from "../Images/Paricutin.jpg";
import { useNavigate } from "react-router-dom";
import back from "../Images/back_arrow.png";
import Victoria from "../Images/Cataratas.jpg";
import core from "../Images/Treasur jpeg.jpeg";
import iss from "../Images/ISSjpg.jpg";
import crict from "../Images/Christ.jpg";
import machu from "../Images/Machu.jpg";
import chainez from "../Images/Chichen.jpg";
import Taj from "../Images/taj.jpg";
import pahad from "../Images/pahad.jpg";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";

const Wondersoftheworld = () => {
  const navigate = useNavigate();
  const ancientWorldRef = useRef(null);
  const middleAgesRef = useRef(null);
  const naturalWondersRef = useRef(null);
  const newWondersRef = useRef(null);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views"> Wonders of </span>
            <span className="Sphere-span-ha-Photo">the World</span>
          </div>
        </div>
      </div>

      <section className="Countries-of-tha-world-sec">
        <div onClick={() => navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="all-btn-ancientWorldRef">
          <span
            className="Wonders-btbn-one"
            onClick={() => scrollToRef(ancientWorldRef)}
          >
            . Seven Wonders of the Ancient World
          </span>
          <span
            className="Wonders-btbn-one"
            onClick={() => scrollToRef(middleAgesRef)}
          >
            . Seven Wonders of the Middle Ages – (list from other era in the
            link)
          </span>
          <span
            className="Wonders-btbn-one"
            onClick={() => scrollToRef(naturalWondersRef)}
          >
            . Seven Natural Wonders of the World
          </span>
          <span
            className="Wonders-btbn-one"
            onClick={() => scrollToRef(newWondersRef)}
          >
            . New 7 Wonders of the World
          </span>
        </div>
        <div className="Countries-of-tha-world-div">
          <div className="Countries-of-tha-Thard-div" ref={ancientWorldRef}>
            <div className="Florida-Coast-Keys">
              <span className="Countries-Regions-az">
                Seven Wonders of the Ancient World
              </span>
            </div>
          </div>
          <div className="all-world-Pyramid mt-4">
            <div className="all-world-Pyramid mt-4">
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Piramit} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Great Pyramid of Giza,
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Mumyy} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Colossus of Rhodes
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Hangin} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Hanging Gardens of Babylon
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Laghithouse} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Lighthouse of Alexandria
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={mausolem} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Mausoleum at Halicarnassus
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={lejump} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Statue of Zeus at Olympia
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={mini} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Temple of Artemis
                </span>
              </div>
            </div>
          </div>
          <div className="Countries-of-tha-Thard-div mt-5" ref={middleAgesRef}>
            <div className="Florida-Coast-Keys">
              <span className="Countries-Regions-az">
                Seven Wonders of the Middle Ages – (list from other era in the
                link)
              </span>
            </div>
          </div>
          <div className="all-world-Pyramid mt-4">
            <div className="all-world-Pyramid mt-4">
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Shoqaf} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Catacombs of Kom El Shoqafa
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Close} alt="" />
                <span className="all-world-sapn-Pyramid">Colosseum</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Wall} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Great Wall of China
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Aya} alt="" />
                <span className="all-world-sapn-Pyramid">Hagia Sophia</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Lala} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Leaning Tower of Pisa
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Toun} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Porcelain Tower of Nanjing
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Stonehenge} alt="" />
                <span className="all-world-sapn-Pyramid">Stonehenge</span>
              </div>
            </div>
          </div>
          <div
            className="Countries-of-tha-Thard-div mt-5"
            ref={naturalWondersRef}
          >
            <div className="Florida-Coast-Keys">
              <span className="Countries-Regions-az">
                Seven Natural Wonders of the World
              </span>
            </div>
          </div>
          <div className="all-world-Pyramid mt-4">
            <div className="all-world-Pyramid mt-4">
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Aurora} alt="" />
                <span className="all-world-sapn-Pyramid">Aurora</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Canyon} alt="" />
                <span className="all-world-sapn-Pyramid">Grand Canyon</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={iss} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Great Barrier Reef
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Roi} alt="" />
                <span className="all-world-sapn-Pyramid">Guanabara Bay</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Mount} alt="" />
                <span className="all-world-sapn-Pyramid">Mount Everest</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Parícutin} alt="" />
                <span className="all-world-sapn-Pyramid">Parícutin</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Victoria} alt="" />
                <span className="all-world-sapn-Pyramid">Victoria Falls</span>
              </div>
            </div>
          </div>
          <div className="Countries-of-tha-Thard-div mt-5" ref={newWondersRef}>
            <div className="Florida-Coast-Keys">
              <span className="Countries-Regions-az">
                New 7 Wonders of the World
              </span>
            </div>
          </div>
          <div className="all-world-Pyramid mt-4">
            <div className="all-world-Pyramid mt-4">
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Wall} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Great Wall of China
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={core} alt="" />
                <span className="all-world-sapn-Pyramid">Petra</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={crict} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Christ the Redeemer (statue)
                </span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={machu} alt="" />
                <span className="all-world-sapn-Pyramid">Machu Picchu</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={chainez} alt="" />
                <span className="all-world-sapn-Pyramid">Chichen Itza</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={Taj} alt="" />
                <span className="all-world-sapn-Pyramid">Taj Mahal</span>
              </div>
              <div className="Giza-priamit-div">
                <img className="Piramit-Great-img" src={pahad} alt="" />
                <span className="all-world-sapn-Pyramid">
                  Giza pyramid complex
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wondersoftheworld;
