import React from "react";

const MeteorsandMeteoritesOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">What are Meteors?</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Meteors, commonly known as shooting stars, are transient and
                  luminous streaks of light that grace the night sky. Their
                  spectacular display is the result of cosmic debris, typically
                  fragments of comets or asteroids, entering Earth's atmosphere
                  at high speeds. As these meteoroids encounter atmospheric
                  resistance, they undergo rapid heating due to compression of
                  air in their path. The intense heat causes the meteoroids to
                  vaporize and ionize, creating a trail of glowing plasma that
                  we observe as a meteor.
                </span>
              </div>

              <div className="">
                <span className="asteroids-main-span">
                  Meteors are often visible for only a few seconds, and their
                  brightness can vary widely. The term "meteor shower" refers to
                  an elevated number of meteors occurring within a specific time
                  frame, typically associated with the Earth passing through the
                  debris trail left by a comet.
                </span>
              </div>
              <div className="mt-4 mb-5">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    What are Meteorites?
                  </span>
                </div>
                <span className="asteroids-main-span">
                  Meteorites, distinct from meteors, are fragments of cosmic
                  debris that survive the fiery journey through the Earth's
                  atmosphere and reach the planet's surface. These remnants
                  provide a tangible link to the broader cosmos and are
                  classified into three main types: stony meteorites, iron
                  meteorites, and stony-iron meteorites.
                </span>
              </div>

              <div className="">
                <span className="asteroids-main-span">
                  Stony Meteorites: Comprising silicate minerals, these
                  meteorites are the most common and can be further categorized
                  into chondrites and achondrites based on their mineral
                  composition. Iron Meteorites: Dominated by metallic iron and
                  nickel, these meteorites are known for their distinctive
                  metallic appearance. Stony-Iron Meteorites: Combining both
                  silicate minerals and metallic elements, this category
                  includes the rare pallasites and mesosiderites.
                </span>
              </div>
              <div className="">
                <span className="asteroids-main-span">
                  Meteorites provide invaluable insights into the composition
                  and processes occurring in the early solar system. They also
                  contribute to our understanding of planetary geology and have
                  implications for the study of life's potential existence
                  beyond Earth.
                </span>
              </div>
              <div className="">
                <span className="asteroids-main-span">
                  These celestial artifacts hold significance not only for
                  scientists but also for collectors and enthusiasts, as they
                  serve as tangible remnants of the grand cosmic ballet
                  witnessed in the night sky.
                </span>
              </div>

              <div className="mt-4 mb-5">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">Meteor Showers</span>
                </div>
                <span className="asteroids-main-span">
                  Meteor showers represent an elevated concentration of meteors
                  visible in the night sky, captivating observers with an
                  enhanced celestial display. This section would explore the
                  origins of meteor showers, their periodic nature, and the
                  radiant points from which meteors appear to originate. Notable
                  meteor showers, such as the Perseids and Leonids, can be
                  highlighted, along with the underlying astronomical phenomena
                  contributing to these captivating celestial spectacles.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MeteorsandMeteoritesOverView;
