import React from "react";
import OortClou2 from "../../../Images/MersSize1.webp";
import OortClou3 from "../../../Images/MersSize2.jpg";
import OortClou4 from "../../../Images/MersSize3.webp";

const MersMoonOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Mars Moon Overview </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Mars has two moons. Phobos and Deimos.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Both these moons are believed to be captured asteroids or
                  other debris from early in the formation of our solar system.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Phobos is the larger of Mars' two moons. It orbits Mars three
                  times a day, and is so close to the planet's surface that in
                  some locations on Mars it cannot always be seen. Phobos is
                  nearing Mars at a rate of six feet (1.8 meters) every hundred
                  years.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Deimos is the smaller moon. It orbits Mars every 30 hours.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Both Phobos and Deimos are irregular in shape and they were
                  discovered by American astronomer Asaph Hall in August 1877.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">
                How Phobos and Deimos Got their Names
              </span>
            </div>
            <span className="asteroids-main-span">
              They are named after the Greek mythological twin characters Phobos
              (fear and panic) and Deimos (terror and dread) who accompanied
              their father Ares into battle. Ares, the god of war, was known to
              the Romans as Mars.
            </span>
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size</span>
            </div>
            <span className="asteroids-main-span">
              Compared to the Earth's Moon, the moons Phobos and Deimos are
              small. Phobos has a diameter of 22.2 km (13.8 mi) while Deimos
              measures 12.6 km (7.8 mi) across.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default MersMoonOverView;
