import React, { useState, useEffect } from "react";
import "../Css/ContinentsOfTheWorld.css";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import Home from "./Home";
import Tweet from "../Images/Tweetlogo.png";
import back from "../Images/back_arrow.png";
import Like from "../Images/Likelogo.png";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ContinentsOfTheWorld = () => {
  const navigate = useNavigate();
  const [AirlineData, setAirlineData] = useState([]);
  console.log(AirlineData, "AirlineData");
  const [loading, setloading] = useState(true);
  const countrydata = async () => {
    setloading(true);

    try {
      let Flagyy = {
        url: "continents_of_the_world",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons);
          setloading(false);
          setAirlineData(respons.data);
        });
    } catch (error) {
      console.log("userap...", error);
      setloading(false);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);

  const handleID = async (Name) => {
    navigate("/ContinentsOfTheWorldOthar", {
      state: {
        Name,
      },
    });
  };
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views"> Country Of </span>
            <span className="Sphere-span-ha-Photo">the World</span>
          </div>
        </div>
      </div>

      <section className="List-of-largest-airlines-main-sec">
        <div onClick={() => navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="new-div-ContinentsOfTheWorldOthar">
          <div className="List-of-largest-airlines-main-div ">
            <div className="List-of-largest-tha-Thard-div">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                className="Elavon-all-yers-data"
              >
                <div className="airlines-main-largest">
                  <span className="airport-all-span">Continent</span>
                </div>
                <div className="airlines-main-largest">
                  <span className="airport-all-span">Area</span>
                </div>

                <div className="airlines-main-largest">
                  <span className="airport-all-span">Density</span>
                </div>
                <div className="airlines-main-largest">
                  <span className="airport-all-span">Population</span>
                </div>
                <div className="airlines-main-largest">
                  <span className="airport-all-span"> Population Share</span>
                </div>
              </div>
              {AirlineData.map((itm, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="Elavon-all-yers-data"
                  >
                    <div
                      onClick={() => handleID(itm.continent)}
                      className="airlines-main-largest"
                      style={{ cursor: "pointer" }}
                    >
                      <span className="main-airpot-span">{itm.continent}</span>
                    </div>
                    <div className="airlines-main-largest">
                      <span className="main-airpot-span">{itm.area}</span>
                    </div>
                    <div className="airlines-main-largest">
                      <span className="main-airpot-span">{itm.density}</span>
                    </div>
                    <div className="airlines-main-largest">
                      <span className="main-airpot-span">{itm.population}</span>
                    </div>
                    <div className="airlines-main-largest">
                      <span className="main-airpot-span">
                        {itm.population_share}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default ContinentsOfTheWorld;
