import React from "react";
import OortClou2 from "../../../Images/ErthDistance1.jpg";
import OortClou3 from "../../../Images/EthaDistance2.jpg";
import OortClou4 from "../../../Images/ErthaDistance3.webp";

const EarthMoonOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">Earth Moon Overview </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  The Moon is Earth's only natural satellite. It orbits at an
                  average distance of 384400 km (238900 mi), about 30 times the
                  planet's diameter.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Moon always presents the same side to Earth, because
                  gravitational pull has locked its rotation to the planet. This
                  results in the lunar day of 29.5 days matching the lunar
                  month.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Moreover, the Moon's gravitational pull is the main driver of
                  the tides.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  It was formed 4.51 billion years ago, not long after Earth's
                  formation, out of the debris from a giant impact between Earth
                  and a hypothesized Mars-sized body called Theia.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Moon is in geophysical terms a planetary-mass object or
                  satellite planet. It has a mass that amounts to 1.2% of
                  Earth's, and a diameter that is roughly one-quarter of Earth's
                  or with 3,474 km (2,159 mi) about as wide as Australia.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Within the Solar System it is the most massive and largest
                  satellite in relation to its parent planet.
                </span>
              </div>

              <div className="mt-4">
                <span className="asteroids-main-span">
                  Its surface gravity is about one sixth of Earth's, about half
                  of that of Mars, and after Jupiter's moon Io the second
                  highest among all Solar System moons.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Moon is rotating at the same rate that it revolves around
                  Earth, so the same hemisphere faces Earth all the time.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The lunar surface is covered in lunar dust and marked by
                  mountains, impact craters, their ejecta, ray-like streaks and,
                  mostly on the near side of the Moon, by dark maria ("seas"),
                  which are plains of cooled magma. These maria formed when
                  large impacts on the far side of the Moon heated up low lying
                  layers of its crust on the near side.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Moon is always illuminated by the Sun, but from Earth the
                  visible illumination shifts during its orbit, producing the
                  lunar phases. The Moon is the brightest celestial object in
                  Earth's night sky.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              With a radius of about 1,080 miles (1,740 kilometers), the Moon is
              less than a third of the width of Earth.
            </span>
            <div>
              <span className="asteroids-main-span">
                The Moon is an average of 238,855 miles (384,400 kilometers)
                away.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EarthMoonOverView;
