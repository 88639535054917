import React from "react";
import OortClou2 from "../../../Images/JupiterMoons1.jpg";
import OortClou3 from "../../../Images/JupiterMoons2.jpg";
import OortClou4 from "../../../Images/JupiterMoons3.webp";

const JupiterMoonOverview = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                {" "}
                Moon of Jupiter Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Jupiter has 95 moons with confirmed orbits as of 23 October
                  2023 and have been officially recognized by the International
                  Astronomical Union.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  This number does not include a number of meter-sized moonlets
                  thought to be shed from the inner moons, nor hundreds of
                  possible kilometer-sized outer irregular moons that were only
                  briefly captured by telescopes.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  All together, Jupiter's moons form a satellite system called
                  the Jovian system.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The most massive of the moons are the four Galilean moons: Io,
                  Europa, Ganymede, and Callisto, which were independently
                  discovered in 1610 and were the first objects found to orbit a
                  body that was neither Earth nor the Sun.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Galilean moons are by far the largest and most massive
                  objects to orbit Jupiter, with the remaining 91 known moons
                  and the rings together composing just 0.003% of the total
                  orbiting mass.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Galilean satellites are nearly spherical in shape due to
                  their planetary mass, and are just massive enough that they
                  would be considered major planets if they were in direct orbit
                  around the Sun.
                </span>
              </div>

              <div className="mt-4">
                <span className="asteroids-main-span">
                  The other four regular satellites, known as the inner moons,
                  are much smaller and closer to Jupiter. These serve as sources
                  of the dust that makes up Jupiter's rings.
                </span>
              </div>

              <div className="mt-4">
                <span className="asteroids-main-span">
                  The remainder of Jupiter's moons are outer irregular
                  satellites whose prograde and retrograde orbits are much
                  farther from Jupiter and have high inclinations and
                  eccentricities.
                </span>
              </div>

              <div className="mt-4">
                <span className="asteroids-main-span">
                  The largest of these moons were likely asteroids that were
                  captured from solar orbits by Jupiter before impacts with
                  other small bodies shattered them into many kilometer-sized
                  fragments, forming collisional families of moons sharing
                  similar orbits.
                </span>
              </div>

              <div className="mt-4">
                <span className="asteroids-main-span">
                  Of the 87 known irregular moons of Jupiter, 38 of them have
                  not yet been officially named.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">
                Names of Jupiter Moons
              </span>
            </div>
            <span className="asteroids-main-span">
              Io, Europa, Ganymede, Callisto, Amalthea, Himalia, Elara,
              Pasiphae, Sinope, Lysithea, Carme, Ananke, Leda, Metis, Adrastea,
              Thebe, Callirrhoe,Themisto, Kalyke, Iocaste, Erinome, Harpalyke,
              Isonoe, Praxidike, Megaclite, Taygete, Chaldene, Autonoe, Thyone,
              Hermippe, Eurydome, Sponde, Pasithee, Euanthe, Kale, Orthosie,
              Euporie, Aitne, plus others yet to receive names.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default JupiterMoonOverview;
