// import logo from './logo.svg';
// import './App.css';
import { Routes, Route } from "react-router-dom";
import Streetview from "./Component/Streetview";
import Provinces from "../src/Component/Provinces";
import Districts from "./Component/Districts";
import Localities from "./Component/Localities";
import StreetViewPage from "./Component/StreetViewPage";
import ContriesOfThaWorld from "./Component/ContriesOfThaWorld";
import CountryCodesList from "./Component/CountryCodesList";
import Ports from "./Component/Ports";
import FREENauticalCharts from "./Component/FREENauticalCharts";
import AirportCode from "./Component/AirportCode";

import Footer from "./Component/Footer";
import Flags from "./Component/Flags";
import Wondersoftheworld from "./Component/Wondersoftheworld";
import WhatisOcean from "./Component/WhatisOcean";
import ListOfVolcanic from "./Component/ListOfVolcanic";
import LargeEruptions from "./Component/LargeEruptions";
import HomePage from "./Component/HomePage";
import Listofbusiest from "./Component/Listofbusiest";
import ListOfBusiestContainer from "./Component/ListOfBusiestContainer";
import ListofHighestMountains from "./Component/ListofHighestMountains";
import ListOfLakesByVolume from "./Component/ListOfLakesByVolume";
import ListOfRiverSystems from "./Component/ListOfRiverSystems";
import GuidetoCountry from "./Component/GuidetoCountry";
import Area from "./Component/Area";
import Population from "./Component/Population";
import MedianAge from "./Component/MedianAge";
import PopulationGrowthRate from "./Component/PopulationGrowthRate";
import Birthrate from "./Component/Birthrate";
import Deathrate from "./Component/Deathrate";
import Netmigrationrate from "./Component/Netmigrationrate";
import Maternalmortalityratio from "./Component/Maternalmortalityratio";
import Infantmortalityrate from "./Component/Infantmortalityrate";
import Lifeexpectancyatbirth from "./Component/Lifeexpectancyatbirth";
import Totalfertilityrate from "./Component/Totalfertilityrate";
import Obesity from "./Component/Obesity";
import Alcoholconsumptionpercapita from "./Component/Alcoholconsumptionpercapita";
import Tobaccouse from "./Component/Tobaccouse";
import Childrenunder from "./Component/Childrenunder";
import Educationexpenditures from "./Component/Educationexpenditures";
import Youthunemployment from "./Component/Youthunemployment";
import RevenueFrom from "./Component/RevenueFrom";
import Revenuefromcoal from "./Component/Revenuefromcoal";
import RealGDP from "./Component/RealGDP";
import RealGDPgrowthrate from "./Component/RealGDPgrowthrate";
import RealGDPpercapita from "./Component/RealGDPpercapita";
import Inflationrate from "./Component/Inflationrate";
import GDPcomposition from "./Component/GDPcomposition";
import Industrialproduction from "./Component/Industrialproduction";
import Laborforce from "./Component/Laborforce";
import Unemploymentrate from "./Component/Unemploymentrate";
import Youthmploymnt from "./Component/Youthmploymnt";
import GiniIndex from "./Component/GiniIndex";
import Budgetsurplus from "./Component/Budgetsurplus";
import Publicdebt from "./Component/Publicdebt";
import Taxesandother from "./Component/Taxesandother";
import Currentaccountbalance from "./Component/Currentaccountbalance";
import Exports from "./Component/Exports";
import Imports from "./Component/Imports";
import Reservesofforeign from "./Component/Reservesofforeign";
import Debtexternal from "./Component/Debtexternal";
import Refinedpetroleum from "./Component/Refinedpetroleum";
import Refinedpetroleumexports from "./Component/Refinedpetroleumexports";
import Refinedpetroleumproductsimports from "./Component/Refinedpetroleumproductsimports";
import Carbondioxideemissions from "./Component/Carbondioxideemissions";
import Energyconsumption from "./Component/Energyconsumption";
import Telephonesfixedlines from "./Component/Telephonesfixedlines";
import Telephonesmobilecellular from "./Component/Telephonesmobilecellular";
import Internetusers from "./Component/Internetusers";
import Broadbandfixed from "./Component/Broadbandfixed";
import Airports from "./Component/Airports";
import Railways from "./Component/Railways";
import Roadways from "./Component/Roadways";
import Waterways from "./Component/Waterways";
import Merchantmarine from "./Component/Merchantmarine";
import Militaryexpenditures from "./Component/Militaryexpenditures";
import Electricity from "./Component/Electricity";
import Capitalcitiescountries from "./Component/Capitalcitiescountries";
import ListoflargestairlinesinNorthAmerica from "./Component/ListoflargestairlinesinNorthAmerica";
import Listoflargestairlinesineurope from "./Component/Listoflargestairlinesineurope";
import ListoflargestairlinesinCentralAmerica from "./Component/ListoflargestairlinesinCentralAmerica";
import Listoflargestairlinesinafrica from "./Component/Listoflargestairlinesinafrica";
import Listoflargestairlinesinasia from "./Component/Listoflargestairlinesinasia";
import Listoflargestairlinesinsouthamerica from "./Component/Listoflargestairlinesinsouthamerica";
import Listoflargestairlinesinoceania from "./Component/Listoflargestairlinesinoceania";
import Nationalanthemsoftheworld from "./Component/Nationalanthemsoftheworld";
import Largestone from "./Component/Largestone";
import LargestTwo from "./Component/LargestTwo";
import LargestThre from "./Component/LargestThre";
import LargestFour from "./Component/LargestFour";
import LargestFive from "./Component/LargestFive";
import LargestSix from "./Component/LargestSix";
import LargestSavon from "./Component/LargestSavon";
import LargestEight from "./Component/LargestEight";
import LargestNine from "./Component/LargestNine";
import LargestTan from "./Component/LargestTan";
import ContinentsOfTheWorld from "./Component/ContinentsOfTheWorld";
import ContinentsOfTheWorldOthar from "./Component/ContinentsOfTheWorldOthar";
import VolcanoBycountry from "./Component/VolcanoBycountry";
import Listoflakesbyarea from "./Component/Listoflakesbyarea";
import Navbar from "./NavBar/Navbar";
import SolarSystemExploration from "./SolarSystem/SolarSystemExploration/SolarSystemExploration";
import SolarSystemOverview from "./SolarSystem/SolarSystemOverview/SolarSystemOverview";
import SunSystem from "./SolarSystem/SunSystem/SunSystem";
import SunOverview from "./SolarSystem/SunOverview/SunOverview";
import KuiperBeltSystem from "./SolarSystem/KuiperBeltSystem/KuiperBeltSystem";
import KuiperBeltOverviw from "./SolarSystem/KuiperBeltOverviw/KuiperBeltOverviw";
import OortCloud from "./SolarSystem/OortCloud/OortCloud";
import OortCloudOverview from "./SolarSystem/OortCloudOverview/OortCloudOverview";
import Planets from "./SolarSystem/Planets/Planets";
import AllPlanets from "./SolarSystem/AllPlanets/AllPlanets";
import PlanetsOverviwe from "./SolarSystem/PlanetsOverviwe/PlanetsOverviwe";
import Mercury from "./SolarSystem/Mercury/Mercury";
import MercuryOveviwe from "./SolarSystem/MercuryOveviwe/MercuryOveviwe";
import Venus from "./SolarSystem/Venus/Venus";
import Earth from "./SolarSystem/Earth/Earth";
import Mars from "./SolarSystem/Mars/Mars";
import Jupiter from "./SolarSystem/Jupiter/Jupiter";
import Saturn from "./SolarSystem/Saturn/Saturn";
import SaturnOverviewe from "./SolarSystem/SaturnOverviewe/SaturnOverviewe";
import Uranus from "./SolarSystem/Uranus/Uranus";
import Neptune from "./SolarSystem/Neptune/Neptune";
import Pluto from "./SolarSystem/Pluto/Pluto";
import DwarfPlanets from "./SolarSystem/DwarfPlanets/DwarfPlanets";
import AllDwarfPlanets from "./SolarSystem/AllDwarfPlanets/AllDwarfPlanets";
import Ceres from "./SolarSystem/Ceres/Ceres";
import Makemake from "./SolarSystem/Makemake/Makemake";
import Haumea from "./SolarSystem/Haumea/Haumea";
import Eris from "./SolarSystem/Eris/Eris";
import MoonsOfThaSolarSystum from "./Moons/MoonsOfThaSolarSystum/MoonsOfThaSolarSystum";
import MoonsinOurSolarSystem from "./Moons/MoonsOfThaSolarSystum/MoonsinOurSolarSystem/MoonsinOurSolarSystem";
import EarthMoon from "./Moons/MoonsOfThaSolarSystum/EarthMoon/EarthMoon";
import MersMoon from "./Moons/MoonsOfThaSolarSystum/MersMoon/MersMoon";
import JupiterMoon from "./Moons/MoonsOfThaSolarSystum/JupiterMoon/JupiterMoon";
import SaturnMoon from "./Moons/MoonsOfThaSolarSystum/SaturnMoon/SaturnMoon";
import UranusMoon from "./Moons/MoonsOfThaSolarSystum/UranusMoon/UranusMoon";
import NeptuneMoon from "./Moons/MoonsOfThaSolarSystum/NeptuneMoon/NeptuneMoon";
import PlutoMoon from "./Moons/MoonsOfThaSolarSystum/PlutoMoon/PlutoMoon";
import Asteroids from "./AsteroidsandComets/Asteroids/Asteroids";
import AsteroidsMain from "./AsteroidsandComets/AsteroidsMain/AsteroidsMain";
import Comets from "./AsteroidsandComets/Comets/Comets";
import MeteorsandMeteorites from "./AsteroidsandComets/MeteorsandMeteorites/MeteorsandMeteorites";
import WhatisUniverse from "./Universe/WhatisUniverse/WhatisUniverse";
import NormalMatter from "./Universe/NormalMatter/NormalMatter";
import DarkMatterandDarkEnergy from "./Universe/DarkMatterandDarkEnergy/DarkMatterandDarkEnergy";
import BlackHoles from "./Universe/BlackHoles/BlackHoles";
import Wormholes from "./Universe/Wormholes/Wormholes";
import Galaxies from "./Universe/Galaxies/Galaxies";
import Starlight from "./Universe/Star/Starlight";
import Exoplanet from "./Universe/Exoplanet/Exoplanet";
import ContriesDetails from "./Component/ContriesDetails";
import Blog from "./Component/Blog";
import BlogDetail from "./Component/BlogDetail";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route index element={<Home />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="StreetView" element={<Streetview />} />
        <Route path="Provinces" element={<Provinces />} />
        <Route path="Districts" element={<Districts />} />
        <Route path="Localities" element={<Localities />} />
        <Route path="StreetViewPage" element={<StreetViewPage />} />
        <Route path="ContriesOfThaWorld" element={<ContriesOfThaWorld />} />
        <Route path="CountryCodesList" element={<CountryCodesList />} />
        <Route path="Ports" element={<Ports />} />
        <Route path="FREENauticalCharts" element={<FREENauticalCharts />} />
        <Route path="AirportCode" element={<AirportCode />} />
        <Route path="Footer" element={<Footer />} />
        <Route path="Flags" element={<Flags />} />
        <Route path="Wondersoftheworld" element={<Wondersoftheworld />} />
        <Route path="WhatisOcean" element={<WhatisOcean />} />
        <Route path="ListOfVolcanic" element={<ListOfVolcanic />} />
        <Route path="LargeEruptions" element={<LargeEruptions />} />
        <Route path="Listofbusiest" element={<Listofbusiest />} />
        <Route
          path="ListOfBusiestContainer"
          element={<ListOfBusiestContainer />}
        />
        <Route
          path="ListofHighestMountains"
          element={<ListofHighestMountains />}
        />
        <Route path="ListOfLakesByVolume" element={<ListOfLakesByVolume />} />
        <Route path="ListOfRiverSystems" element={<ListOfRiverSystems />} />
        <Route path="GuidetoCountry" element={<GuidetoCountry />} />
        <Route path="Area" element={<Area />} />
        <Route path="Population" element={<Population />} />
        <Route path="MedianAge" element={<MedianAge />} />
        <Route path="PopulationGrowthRate" element={<PopulationGrowthRate />} />
        <Route path="Birthrate" element={<Birthrate />} />
        <Route path="Deathrate" element={<Deathrate />} />
        <Route path="Netmigrationrate" element={<Netmigrationrate />} />
        <Route
          path="Maternalmortalityratio"
          element={<Maternalmortalityratio />}
        />
        <Route path="Infantmortalityrate" element={<Infantmortalityrate />} />
        <Route
          path="Lifeexpectancyatbirth"
          element={<Lifeexpectancyatbirth />}
        />
        <Route path="Totalfertilityrate" element={<Totalfertilityrate />} />
        <Route path="Obesity" element={<Obesity />} />
        <Route
          path="Alcoholconsumptionpercapita"
          element={<Alcoholconsumptionpercapita />}
        />
        <Route path="Tobaccouse" element={<Tobaccouse />} />
        <Route path="Childrenunder" element={<Childrenunder />} />
        <Route
          path="Educationexpenditures"
          element={<Educationexpenditures />}
        />
        <Route path="Youthunemployment" element={<Youthunemployment />} />
        <Route path="RevenueFrom" element={<RevenueFrom />} />
        <Route path="Revenuefromcoal" element={<Revenuefromcoal />} />
        <Route path="RealGDP" element={<RealGDP />} />
        <Route path="RealGDPgrowthrate" element={<RealGDPgrowthrate />} />
        <Route path="RealGDPpercapita" element={<RealGDPpercapita />} />
        <Route path="Inflationrate" element={<Inflationrate />} />
        <Route path="GDPcomposition" element={<GDPcomposition />} />
        <Route path="Industrialproduction" element={<Industrialproduction />} />
        <Route path="Laborforce" element={<Laborforce />} />
        <Route path="Unemploymentrate" element={<Unemploymentrate />} />
        <Route path="Youthmploymnt" element={<Youthmploymnt />} />
        <Route path="GiniIndex" element={<GiniIndex />} />
        <Route path="Budgetsurplus" element={<Budgetsurplus />} />
        <Route path="Publicdebt" element={<Publicdebt />} />
        <Route path="Taxesandother" element={<Taxesandother />} />
        <Route
          path="Currentaccountbalance"
          element={<Currentaccountbalance />}
        />
        <Route path="Exports" element={<Exports />} />
        <Route path="Imports" element={<Imports />} />
        <Route path="Reservesofforeign" element={<Reservesofforeign />} />
        <Route path="Debtexternal" element={<Debtexternal />} />
        <Route path="Refinedpetroleum" element={<Refinedpetroleum />} />
        <Route
          path="Refinedpetroleumexports"
          element={<Refinedpetroleumexports />}
        />
        <Route
          path="Refinedpetroleumproductsimports"
          element={<Refinedpetroleumproductsimports />}
        />
        <Route
          path="Carbondioxideemissions"
          element={<Carbondioxideemissions />}
        />
        <Route path="Energyconsumption" element={<Energyconsumption />} />
        <Route path="Telephonesfixedlines" element={<Telephonesfixedlines />} />
        <Route
          path="Telephonesmobilecellular"
          element={<Telephonesmobilecellular />}
        />
        <Route path="Internetusers" element={<Internetusers />} />
        <Route path="Broadbandfixed" element={<Broadbandfixed />} />
        <Route path="Airports" element={<Airports />} />
        <Route path="Railways" element={<Railways />} />
        <Route path="Roadways" element={<Roadways />} />
        <Route path="Waterways" element={<Waterways />} />
        <Route path="Merchantmarine" element={<Merchantmarine />} />
        <Route path="Militaryexpenditures" element={<Militaryexpenditures />} />
        <Route path="Electricity" element={<Electricity />} />
        <Route
          path="Capitalcitiescountries"
          element={<Capitalcitiescountries />}
        />
        <Route
          path="ListoflargestairlinesinNorthAmerica"
          element={<ListoflargestairlinesinNorthAmerica />}
        />
        <Route
          path="Listoflargestairlinesineurope"
          element={<Listoflargestairlinesineurope />}
        />
        <Route
          path="ListoflargestairlinesinCentralAmerica"
          element={<ListoflargestairlinesinCentralAmerica />}
        />
        <Route
          path="Listoflargestairlinesinafrica"
          element={<Listoflargestairlinesinafrica />}
        />
        <Route
          path="Listoflargestairlinesinasia"
          element={<Listoflargestairlinesinasia />}
        />
        <Route
          path="Listoflargestairlinesinsouthamerica"
          element={<Listoflargestairlinesinsouthamerica />}
        />
        <Route
          path="Listoflargestairlinesinoceania"
          element={<Listoflargestairlinesinoceania />}
        />
        <Route
          path="Nationalanthemsoftheworld"
          element={<Nationalanthemsoftheworld />}
        />
        <Route path="Listoflakesbyarea" element={<Listoflakesbyarea />} />
        <Route path="Largestone" element={<Largestone />} />
        <Route path="LargestTwo" element={<LargestTwo />} />
        <Route path="LargestThre" element={<LargestThre />} />
        <Route path="LargestFour" element={<LargestFour />} />
        <Route path="LargestFive" element={<LargestFive />} />
        <Route path="LargestSix" element={<LargestSix />} />
        <Route path="LargestSavon" element={<LargestSavon />} />
        <Route path="LargestEight" element={<LargestEight />} />
        <Route path="LargestNine" element={<LargestNine />} />
        <Route path="LargestTan" element={<LargestTan />} />
        <Route path="ContinentsOfTheWorld" element={<ContinentsOfTheWorld />} />
        <Route
          path="ContinentsOfTheWorldOthar"
          element={<ContinentsOfTheWorldOthar />}
        />
        <Route path="ContriesDetails" element={<ContriesDetails />} />
        <Route path="VolcanoBycountry" element={<VolcanoBycountry />} />
        <Route path="Navbar" element={<Navbar />} />
        <Route
          path="SolarSystemExploration"
          element={<SolarSystemExploration />}
        />
        <Route path="SolarSystemOverview" element={<SolarSystemOverview />} />
        <Route path="SunSystem" element={<SunSystem />} />
        <Route path="SunOverview" element={<SunOverview />} />
        <Route path="KuiperBeltSystem" element={<KuiperBeltSystem />} />
        <Route path="KuiperBeltOverviw" element={<KuiperBeltOverviw />} />
        <Route path="OortCloud" element={<OortCloud />} />
        <Route path="AllPlanets" element={<AllPlanets />} />
        <Route path="planets" element={<Planets />} />
        <Route path="Mercury" element={<Mercury />} />
        <Route path="Venus" element={<Venus />} />
        <Route path="Earth" element={<Earth />} />
        <Route path="Mars" element={<Mars />} />
        <Route path="Jupiter" element={<Jupiter />} />
        <Route path="Saturn" element={<Saturn />} />
        <Route path="Neptune" element={<Neptune />} />
        <Route path="Uranus" element={<Uranus />} />
        <Route path="Pluto" element={<Pluto />} />
        <Route path="DwarfPlanets" element={<DwarfPlanets />} />
        <Route path="AllDwarfPlanets" element={<AllDwarfPlanets />} />
        <Route path="Ceres" element={<Ceres />} />
        <Route path="Makemake" element={<Makemake />} />
        <Route path="Haumea" element={<Haumea />} />
        <Route path="Eris" element={<Eris />} />
        /////////////////////* MoonsOfThaSolarSystum
        *///////////////////////////
        <Route
          path="MoonsOfThaSolarSystum"
          element={<MoonsOfThaSolarSystum />}
        />
        <Route
          path="MoonsinOurSolarSystem"
          element={<MoonsinOurSolarSystem />}
        />
        <Route path="EarthMoon" element={<EarthMoon />} />
        <Route path="MersMoon" element={<MersMoon />} />
        <Route path="JupiterMoon" element={<JupiterMoon />} />
        <Route path="SaturnMoon" element={<SaturnMoon />} />
        <Route path="UranusMoon" element={<UranusMoon />} />
        <Route path="NeptuneMoon" element={<NeptuneMoon />} />
        <Route path="PlutoMoon" element={<PlutoMoon />} />
        <Route path="Asteroids" element={<Asteroids />} />
        <Route path="AsteroidsMain" element={<AsteroidsMain />} />
        <Route path="Comets" element={<Comets />} />
        <Route path="MeteorsandMeteorites" element={<MeteorsandMeteorites />} />
        <Route path="WhatisUniverse" element={<WhatisUniverse />} />
        <Route path="NormalMatter" element={<NormalMatter />} />
        <Route
          path="DarkMatterandDarkEnergy"
          element={<DarkMatterandDarkEnergy />}
        />
        <Route path="BlackHoles" element={<BlackHoles />} />
        <Route path="Wormholes" element={<Wormholes />} />
        <Route path="Galaxies" element={<Galaxies />} />
        <Route path="Starlight" element={<Starlight />} />
        <Route path="Exoplanet" element={<Exoplanet />} />
        <Route path="Blog" element={<Blog />} />
        <Route path="BlogDetail" element={<BlogDetail />} />
      </Routes>
    </div>
  );
}

export default App;
