import React, { useEffect, useState } from "react";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import { useLocation } from "react-router-dom";
import Loc from "../Images/Location.png";
import send from "../Images/send.png";
import { useNavigate } from "react-router-dom";
import Home from "./Home";
import axios from "../Schemas/Api";
import Backdrop from "@mui/material/Backdrop";
import back from "../Images/back_arrow.png";
import CircularProgress from "@mui/material/CircularProgress";
const Districts = () => {
  const navigate = useNavigate();
  let route = useLocation();
  const statedata = route.state.id;
  // console.log(statedata, "haaaaaeee");

  // const navigate = useNavigate();
  // let router = useLocation();
  // const Statdata = router.state.Cuntryid;

  const [citydata, setCitydata] = useState([]);
  const [maxIndexLength, setmaxIndexLength] = useState();
  const [loading, setloading] = useState(true);
  console.log(citydata, "ya to mil na cahiya");
  const onSubmit = async () => {
    setloading(true);
    try {
      const respons = await axios.post(
        "/get_city_data",
        {
          state_id: statedata.id,
        },
        {
          Headers: { "Content-Type": "application/json" },
        }
      );
      setCitydata(respons.data.data);

      setmaxIndexLength(respons.data.data.length.toString().length);
      setloading(false);
      console.log("res.....", respons);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    onSubmit();
  }, [statedata]);

  const handleID = async (ok) => {
    navigate("/Localities", {
      state: {
        ok,
      },
    });
  };
  console.log(citydata, " state");

  const padIndexWithZeros = (index, maxLength) => {
    const indexStr = index.toString();
    const zerosToAdd = maxLength - indexStr.length;
    return "0".repeat(zerosToAdd) + indexStr;
  };

  return (
    <>
      <Home />
      <section className="Provinces-section">
        <div className="Provinces-div">
          <div className="contain-main-div">
            {/* <div className="like-dono-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div> */}
            <div className="osam-font-he-na">
              <span className="Google-span-ha-Views">
                List of Districts in {""} {citydata[0]?.state_name},{" "}
                {citydata[0]?.country_name}.
              </span>
              <span className="Sphere-span-ha-Photo">
                {" "}
                with Google Maps and Street Views
              </span>
            </div>
          </div>
        </div>

        <div className="Provinces-list">
          <div onClick={() => navigate(-1)} className="back-ka-div">
            <img src={back} className="Back-btn-ok" alt="" />
            <span>Back</span>
          </div>
          <div className="Provinces-list-contain">
            {citydata?.map((itam, index) => {
              return (
                <>
                  <div onClick={() => handleID(itam)} className="loc-lista">
                    <>{padIndexWithZeros(index + 1, maxIndexLength)}.)</>
                    <img className="location-img" src={Loc} alt="" />
                    <span className="Badakhshan-span">{itam?.city_name}</span>
                  </div>
                  <div className="line-ka-dady-hu">
                    <div className="line-Provinces-ka-lliya"></div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Districts;
