import React from "react";
import OortClou2 from "../../Images/ExoplanetOverView1.jpg";
import OortClou3 from "../../Images/ExoplanetOverView2.jpg";
import OortClou4 from "../../Images/ExoplanetOverView3.jpg";
const ExoplanetOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">What is an Exoplanet?</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  An exoplanet, or extrasolar planet, is a type of planet that
                  orbits a star outside our solar system. Unlike the planets in
                  our solar system, which orbit the Sun, exoplanets have their
                  own stellar host. The discovery and study of exoplanets have
                  revolutionized our understanding of the universe, providing
                  insights into the prevalence of planetary systems and their
                  diverse characteristics.
                </span>
              </div>
            </div>
            <div className="mt-4 mb-5">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Key Characteristics:
                </span>
              </div>
              <span className="asteroids-main-span">
                1. Orbiting Other Stars: Exoplanets orbit stars beyond the Sun.
                These stars can be similar to or different from our Sun in terms
                of size, temperature, and other properties.
              </span>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  2. Diversity in Size and Composition: Exoplanets come in a
                  wide range of sizes and compositions. They can be as small as
                  Earth or as massive as Jupiter, and their compositions may
                  vary from rocky to gaseous.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  3. Detection Methods: Identifying exoplanets is a challenging
                  task due to the overwhelming brightness of their host stars.
                  Astronomers use several methods to detect them, including the
                  transit method (observing the dimming of a star as a planet
                  passes in front of it), radial velocity method (detecting the
                  star's wobble caused by an orbiting planet), and direct
                  imaging.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  4. Habitability Considerations: The study of exoplanets often
                  involves investigating their potential habitability.
                  Scientists are particularly interested in identifying
                  exoplanets within their star's habitable zone—the region where
                  conditions may allow for the presence of liquid water, a key
                  ingredient for life as we know it.
                </span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  5. Technological Advancements: Advances in observational
                  technology, such as space telescopes like Kepler and
                  ground-based observatories, have significantly contributed to
                  the discovery and characterization of exoplanets. Ongoing and
                  upcoming missions, like the James Webb Space Telescope,
                  promise to further enhance our ability to study these distant
                  worlds.
                </span>
              </div>
            </div>
            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">Significance:</span>
              </div>
              <div className="mt-2">
                <span className="asteroids-main-span">
                  The discovery of exoplanets has transformed our understanding
                  of the cosmos. It suggests that planetary systems are common
                  throughout the galaxy, raising questions about the potential
                  prevalence of life beyond Earth. The ongoing search for and
                  study of exoplanets contribute to our broader exploration of
                  the universe and our place within it.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div mb-5">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default ExoplanetOverView;
