import React from "react";
import planet from "../../Images/MoonMainimg.png";
import sedo from "../../Images/dark_layer@3x.png";
import MoonsOfThaSolarSystumOverView from "../MoonsOfThaSolarSystum/MoonsOfThaSolarSystumOverView/MoonsOfThaSolarSystumOverView";
import MoonsinOurSolarSystem from "../MoonsOfThaSolarSystum/MoonsinOurSolarSystem/MoonsinOurSolarSystem";
import Navbar from "../../NavBar/Navbar";
const MoonsOfThaSolarSystum = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Moons of Our Solar System</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  Moons come in many shapes, sizes, and types. A few have
                  atmospheres and even hidden oceans beneath their surfaces.
                </span>
              </div>
              <div className="our-solar-span">
                <span className="UR-SOLAR-SYSTEM-span">TODAY</span>
              </div>
              <div className="Dwarf-Planets-div-do-Missions">
                <div className="Planets-etha-div">
                  <span className="eath-span">05</span>
                  <span className="Planets-main-sapn">Moons</span>
                </div>
                <div className="Planets-etha-div">
                  <span className="eath-span">01</span>
                  <span className="Planets-main-sapn">Missions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MoonsOfThaSolarSystumOverView />
      <MoonsinOurSolarSystem />
    </>
  );
};

export default MoonsOfThaSolarSystum;
