import React, { useState, useEffect, useRef } from "react";
import "../Css/Streetview.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Autoplay } from "swiper/modules";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import logo1 from "../Images/Countrylogo.avif";
import logo2 from "../Images/Countrylogo2.avif";
import logo3 from "../Images/Countrylogo3.avif";
import logo4 from "../Images/Countrylogo4.avif";
import logo5 from "../Images/Countrylogo5.avif";
import logo6 from "../Images/Countrylogo6.avif";
import logo7 from "../Images/Countrylogo7.avif";
import logo8 from "../Images/Countrylogo8.avif";
import logo9 from "../Images/Countrylogo9.avif";
import logo10 from "../Images/Countrylogo10.avif";
import logo11 from "../Images/Countrylogo11.avif";
import logo12 from "../Images/Countrylogo12.avif";
import logo13 from "../Images/Countrylogo13.avif";
import logo14 from "../Images/Countrylogo14.avif";
import logo15 from "../Images/Countrylogo15.avif";
import logo16 from "../Images/Countrylogo16.avif";
import logo17 from "../Images/Countrylogo17.avif";
import logo18 from "../Images/Countrylogo18.avif";
import logo19 from "../Images/Countrylogo19.avif";
import logo20 from "../Images/Countrylogo20.avif";
import logo21 from "../Images/Countrylogo21.avif";
import logo22 from "../Images/Countrylogo22.avif";
import logo23 from "../Images/Countrylogo23.avif";
import logo24 from "../Images/Countrylogo24.avif";
import logo25 from "../Images/Countrylogo25.avif";
import logo26 from "../Images/Countrylogo26.avif";
import logo27 from "../Images/Countrylogo27.avif";
import logo28 from "../Images/Countrylogo28.avif";
import stat23 from "../Images/Afghan.avif";
import stat22 from "../Images/Albania.avif";
import stat24 from "../Images/Andon.avif";
import stat25 from "../Images/Aremain.avif";
import stat5 from "../Images/Argantina.avif";
import stat6 from "../Images/Austria.avif";
import stat7 from "../Images/Bangladesh.avif";
import stat8 from "../Images/Belarus.avif";
import stat26 from "../Images/Bulgaira.avif";
import stat27 from "../Images/antartica.avif";
import stat11 from "../Images/austrlia.avif";
import stat12 from "../Images/balgium.avif";
import stat13 from "../Images/botswana.avif";
import stat14 from "../Images/brazil.avif";
import stat15 from "../Images/burmuda.avif";
import stat16 from "../Images/cambodia.avif";
import stat17 from "../Images/canada.avif";
import stat18 from "../Images/chile.avif";
import stat19 from "../Images/china.avif";
import stat28 from "../Images/cocosavif.avif";
import stat21 from "../Images/islanda.avif";
import stat29 from "../Images/iceland.avif";
import stat30 from "../Images/indiaavif.avif";
import stat31 from "../Images/indonascia.avif";
import stat32 from "../Images/ireland.avif";
import stat33 from "../Images/itly.avif";
import stat34 from "../Images/japan.avif";
import stat35 from "../Images/kenya.avif";
import stat135 from "../Images/ireland.avif";
import stat36 from "../Images/latviya.avif";
import stat37 from "../Images/leachtinstan.avif";
import stat38 from "../Images/lithuania.avif";
import stat39 from "../Images/luxamburng.avif";
import stat40 from "../Images/macau.avif";
import stat41 from "../Images/malaciya.avif";
import stat42 from "../Images/malta.avif";
import stat43 from "../Images/maxico.avif";
import stat44 from "../Images/Moldova.avif";
import stat45 from "../Images/monaco.avif";
import stat46 from "../Images/nitharlends.avif";
import stat47 from "../Images/newzileand.avif";
import stat50 from "../Images/peru.avif";
import stat51 from "../Images/phillipinase.avif";
import stat52 from "../Images/poland.avif";
import stat53 from "../Images/portugal.avif";
import stat54 from "../Images/romania.avif";
import stat55 from "../Images/Russia.jpg";
import stat56 from "../Images/sanmarion.avif";
import stat57 from "../Images/scotland.avif";
import stat58 from "../Images/sarbia.avif";
import stat59 from "../Images/singapur.webp";
import World from "../Images/ayan bhai ka logo.png";
import SearchBar from "../Component/SearchBar";
import stat60 from "../Images/slovakia.avif";
import stat61 from "../Images/sauthafrica.avif";
import stat62 from "../Images/spain.avif";
import stat63 from "../Images/srilanka.avif";
import stat64 from "../Images/sweden.avif";
import stat65 from "../Images/switzarland.avif";
import stat66 from "../Images/taiwan.avif";
import stat67 from "../Images/bangkok.avif";
import stat68 from "../Images/turkey.avif";
import stat69 from "../Images/ukraine.avif";
import stat70 from "../Images/unaited arab .avif";
import stat71 from "../Images/unitad kingdum.avif";
import stat72 from "../Images/united stat.avif";
import stat73 from "../Images/vatican.avif";
import stat74 from "../Images/wales.avif";
import back from "../Images/back_arrow.png";
import axios from "../Schemas/Api";
import Home from "./Home";
import { useNavigate } from "react-router-dom";
const Streetview = () => {
  const Street = [
    {
      Image: stat5,
      Cityname: "ARGENTINA",
    },
    {
      Image: stat6,
      Cityname: "ARMENIA",
    },
    {
      Image: stat7,
      Cityname: "AUSTRALIA",
    },
    {
      Image: stat8,
      Cityname: "AUSTRIA",
    },
    {
      Image: stat11,
      Cityname: "BELGIUM",
    },
    {
      Image: stat12,
      Cityname: "BERMUDA",
    },
    {
      Image: stat13,
      Cityname: "BOTSWANA",
    },
    {
      Image: stat14,
      Cityname: "BRAZIL",
    },
    {
      Image: stat15,
      Cityname: "BULGARIA",
    },
    {
      Image: stat16,
      Cityname: "CZECHIA",
    },
    {
      Image: stat17,
      Cityname: "CANADA",
    },
    {
      Image: stat18,
      Cityname: "CYPRUS",
    },
    {
      Image: stat19,
      Cityname: "CHINA",
    },

    {
      Image: stat21,
      Cityname: "COLOMBIA",
    },
    {
      Image: stat22,
      Cityname: "DENMARK",
    },
    {
      Image: stat23,
      Cityname: "ENGLAND",
    },
    {
      Image: stat24,
      Cityname: "FRANCE",
    },
    {
      Image: stat25,
      Cityname: "GERMANY",
    },
    {
      Image: stat26,
      Cityname: "GREECE",
    },
    {
      Image: stat27,
      Cityname: "HONG KONG",
    },
    {
      Image: stat28,
      Cityname: "HUNGARY",
    },

    {
      Image: stat29,
      Cityname: "ICELAND",
    },
    {
      Image: stat30,
      Cityname: "INDIA",
    },
    {
      Image: stat36,
      Cityname: "INDONESIA",
    },
    {
      Image: stat135,
      Cityname: "IRELAND",
    },
    {
      Image: stat47,
      Cityname: "ITALY",
    },

    {
      Image: stat31,
      Cityname: "JAPAN",
    },
    {
      Image: stat32,
      Cityname: "KENYA",
    },
    {
      Image: stat33,
      Cityname: "LATVIA",
    },
    {
      Image: stat34,
      Cityname: "LIECHTENSTEIN",
    },
    {
      Image: stat35,
      Cityname: "LITHUANIA",
    },
    {
      Image: stat39,
      Cityname: "LUXEMBOURG",
    },
    {
      Image: stat37,
      Cityname: "MACAU",
    },
    {
      Image: stat38,
      Cityname: "MALAYSIA",
    },
    {
      Image: stat34,
      Cityname: "MALTA",
    },
    {
      Image: stat40,
      Cityname: "MEXICO",
    },
    {
      Image: stat41,
      Cityname: "MOLDOVA",
    },
    {
      Image: stat42,
      Cityname: "MONACO",
    },
    {
      Image: stat43,
      Cityname: "NETHERLANDS",
    },
    {
      Image: stat44,
      Cityname: "NEW ZEALAND",
    },
    {
      Image: stat45,
      Cityname: "NIGERIA",
    },
    {
      Image: stat46,
      Cityname: "NORWAY",
    },
    {
      Image: stat50,
      Cityname: "PERU",
    },
    {
      Image: stat51,
      Cityname: "PHILLIPPINES",
    },
    {
      Image: stat52,
      Cityname: "POLAND",
    },
    {
      Image: stat53,
      Cityname: "PORTUGAL",
    },
    {
      Image: stat54,
      Cityname: "ROMANIA",
    },
    {
      Image: stat55,
      Cityname: "RUSSIA",
    },
    {
      Image: stat56,
      Cityname: "SUN MARION",
    },
    {
      Image: stat57,
      Cityname: "SCOTLAND",
    },
    {
      Image: stat58,
      Cityname: "SERBIA",
    },
    {
      Image: stat59,
      Cityname: "SINGAPORE",
    },
    {
      Image: stat60,
      Cityname: "SLOVAKIA",
    },
    {
      Image: stat61,
      Cityname: "SOUTH AFRICA",
    },
    {
      Image: stat62,
      Cityname: "SPAIN",
    },
    {
      Image: stat63,
      Cityname: "SRI LANKA",
    },
    {
      Image: stat64,
      Cityname: "SWEDEN",
    },
    {
      Image: stat65,
      Cityname: "SWITZERLAND",
    },
    {
      Image: stat66,
      Cityname: "TAIWAN",
    },
    {
      Image: stat67,
      Cityname: "THAILAND",
    },
    {
      Image: stat68,
      Cityname: "TURKEY",
    },
    {
      Image: stat69,
      Cityname: "UKRAINE",
    },
    {
      Image: stat70,
      Cityname: "UNITED ARAB EMIRATES",
    },
    {
      Image: stat71,
      Cityname: "UNITED KINGDOM",
    },
    {
      Image: stat72,
      Cityname: "UNITED STATES",
    },
    {
      Image: stat73,
      Cityname: "VATICAN",
    },
    {
      Image: stat74,
      Cityname: "WALES",
    },
  ];

  const Clogo = [
    {
      Logo: logo1,
    },
    {
      Logo: logo2,
    },
    {
      Logo: logo3,
    },
    {
      Logo: logo4,
    },
    {
      Logo: logo5,
    },
    {
      Logo: logo6,
    },
    {
      Logo: logo7,
    },
    {
      Logo: logo8,
    },
    {
      Logo: logo9,
    },
    {
      Logo: logo10,
    },
    {
      Logo: logo11,
    },
    {
      Logo: logo12,
    },
    {
      Logo: logo13,
    },
    {
      Logo: logo14,
    },
    {
      Logo: logo15,
    },
    {
      Logo: logo16,
    },
    {
      Logo: logo17,
    },
    {
      Logo: logo18,
    },
    {
      Logo: logo19,
    },
    {
      Logo: logo20,
    },
    {
      Logo: logo21,
    },
    {
      Logo: logo22,
    },
    {
      Logo: logo23,
    },
    {
      Logo: logo24,
    },
    {
      Logo: logo25,
    },
    {
      Logo: logo26,
    },
    {
      Logo: logo27,
    },
    {
      Logo: logo28,
    },
  ];

  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const [loading, setloading] = useState(true);
  console.log(country, "mil rha he");
  const countrydata = async () => {
    setloading(true);

    try {
      let poly = {
        url: "/country_data",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          setCountry(respons.country_list);
          console.log(country);
          setloading(false);
        });
    } catch (error) {
      setloading(false);
      console.log("userap...", error);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);
  const handleID = async (id) => {
    console.log(id, "mnbvcxz");
    navigate("/Provinces", {
      state: {
        id,
      },
    });
  };
  const handleSearch = (address) => {
    // Perform search logic here
    console.log("Search address:", address);
  };
  return (
    <>
      <div className="Home-section">
        <div className="Geoghraphic-img-div">
          <div className="serach-and-mogo">
            <div className="serach-ke-liya-mhanat">
              <img className="World-img" src={World} alt="" />
              <div>
                <span className="Geoghraphic-span">Geoghraphic.org</span>
                <span className="Streetview-span">/Streetview</span>
              </div>
            </div>
            <div>
              <SearchBar handleSearch={handleSearch} />
            </div>
          </div>
        </div>
      </div>
      <section className="Streetview-section">
        <div className="Streetview-div">
          <div className="buttn-logo-div">
            <div className="Pading-ka-liya">
              {/* <div className="logo-btndiv">
                <div className="dono-btn-kaliya">
                  <button className="like-img-bnt">
                    <img className="like-img" src={Like} alt="" />
                    Like
                  </button>
                  <button className="like-img-bnt">
                    <img className="like-img" src={Tweet} alt="" />
                    Tweet
                  </button>
                </div>
              </div> */}

              <div>
                <span className="Google-span-ha-Views">
                  Google Street Views
                </span>

                <span className="Sphere-span-ha-Photo">
                  (+Photo Sphere) and Maps
                </span>
              </div>
            </div>

            <div className="sab-logo-kaliya">
              <Swiper
                slidesPerView={12}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                // breakpoints={{
                //   428: {
                //     slidesPerView: 1,
                //   },
                //   640: {
                //     slidesPerView: 2,
                //   },
                //   768: {
                //     slidesPerView: 10,
                //   },
                //   1024: {
                //     slidesPerView: 5,
                //   },
                // }}
                modules={[Autoplay]}
                className="mySwiper Ya-mera-swiper-he"
              >
                {Clogo.map((itm, index) => {
                  return (
                    <SwiperSlide className="ye-mera-swiper-ka-slide">
                      <img className="country-logo" src={itm.Logo} alt="" />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>

          <div className="street-img-loc">
            <div onClick={() => navigate(-1)} className="back-ka-div">
              <img src={back} className="Back-btn-ok" alt="" />
              <span>Back</span>
            </div>
            <div className="street-img-loc-div">
              {country.map((itm, index) => {
                return (
                  <div
                    onClick={() => handleID(itm)}
                    className="span-Afghanistan-img"
                  >
                    <img
                      key={index}
                      className="Afghan-img"
                      src={itm.image}
                      alt=""
                    />
                    <span className="span-Afghanistan">{itm.country_name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Streetview;
