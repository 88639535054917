import React from "react";
import OortClou2 from "../../Images/BlackHolesOverview1.webp";
import OortClou3 from "../../Images/BlackHolesOverview2.avif";
import OortClou4 from "../../Images/BlackHolesOverview3.avif";
const BlackHolesOverview = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">What is black hole?</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Black holes are extraordinary celestial objects characterized
                  by intense gravitational forces that result in a region of
                  spacetime from which nothing, not even light, can escape—a
                  boundary known as the event horizon. These enigmatic entities,
                  predicted by Albert Einstein's theory of general relativity,
                  represent one of the most intriguing phenomena in the cosmos.
                </span>
              </div>
            </div>

            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Formation of Black Holes: The Final Stages of Stellar
                  Evolution
                </span>
              </div>
              <span className="asteroids-main-span">
                Black holes are typically formed through the gravitational
                collapse of massive stars. When a massive star exhausts its
                nuclear fuel, it undergoes a supernova explosion, expelling
                outer layers into space. If the remaining core is sufficiently
                massive, gravity overwhelms all other forces, causing the core
                to collapse into a point of infinite density known as a
                singularity, surrounded by the event horizon.
              </span>
            </div>
            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Properties of Black Holes: Gravity's Dominance and Event
                  Horizons
                </span>
              </div>
              <span className="asteroids-main-span">
                At the heart of a black hole lies the singularity—a point where
                the mass is compressed to infinite density. Surrounding the
                singularity is the event horizon, a boundary beyond which escape
                is impossible due to the overwhelming gravitational pull. The
                size of the event horizon, or the Schwarzschild radius, is
                directly proportional to the mass of the black hole.
              </span>
            </div>
            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Astrophysical Signatures: Observing the Invisible
                </span>
              </div>
              <span className="asteroids-main-span">
                Black holes themselves do not emit light, making direct
                observation challenging. However, their presence is inferred
                through the detection of various astrophysical signatures. These
                include gravitational effects on nearby objects, the distortion
                of spacetime resulting in gravitational lensing, and the
                emission of X-rays from surrounding matter forming an accretion
                disk.
              </span>
            </div>
            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">Black Hole Types</span>
              </div>
              <span className="asteroids-main-span">
                Black holes are categorized by their mass. n disk.
              </span>
              <div className="mt-4">
                <span>
                  • Stellar-mass black holes, with masses roughly three to
                  twenty times that of the Sun, are formed from collapsing
                  massive stars.
                </span>
              </div>
              <span>
                • Intermediate black holes, with masses between one hundred to
                several thousand solar masses, and
              </span>
              <div>
                <span>
                  • Supermassive black holes, found at the centers of galaxies
                  with millions to billions of solar masses, also exist.
                </span>
              </div>
            </div>
            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">Hawking Radiation</span>
              </div>
              <span className="asteroids-main-span">
                Theoretical physicist Stephen Hawking proposed that black holes
                are not entirely black. Due to quantum effects near the event
                horizon, pairs of particles and antiparticles are continually
                created and annihilated. If one particle falls into the black
                hole while the other escapes, it results in a slow loss of mass,
                known as Hawking radiation.
              </span>
            </div>
            <div className="mt-4 ">
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Black Holes and the Cosmic Landscape: Gravitational Influence
                  and Galaxy Evolution
                </span>
              </div>
              <span className="asteroids-main-span">
                Black holes play a crucial role in the cosmic ecosystem.
                Stellar-mass black holes influence the evolution of galaxies by
                interacting with nearby stars and matter. Supermassive black
                holes, residing at galactic centers, contribute to the
                regulation of star formation and the dynamics of galactic
                structures.
              </span>
            </div>
            <div
              className="mt-4 
            "
            >
              <div className="mb-3">
                <span className="Neptunian-soler-span">
                  Beyond the Event Horizon: The Nature of Spacetime
                  Singularities
                </span>
              </div>
              <span className="asteroids-main-span">
                The region beyond the event horizon is shrouded in mystery.
                Within this realm, the laws of physics, as currently understood,
                break down, giving rise to spacetime singularities where
                traditional descriptions cease to apply. The nature of these
                singularities remains one of the profound puzzles in the study
                of black holes.
              </span>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  In the cosmic symphony, black holes represent the
                  gravitational crescendos, shaping the fabric of spacetime and
                  challenging our understanding of the universe's most extreme
                  environments. Their study opens windows into the deepest
                  recesses of the cosmos, where gravity becomes an unyielding
                  force and the rules of conventional physics undergo radical
                  transformations.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div mb-5">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlackHolesOverview;
