import React from "react";

const SunOverview = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div">
          <div className="Overview-contain-main-div">
            <div>
              <span className="Overview-soler-span">Sun Overview</span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  The Sun is a yellow dwarf star, a hot ball of glowing gases at
                  the heart of our solar system.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Sun is the star at the center of the Solar System. It is a
                  massive, hot ball of plasma, inflated and heated by energy
                  produced by nuclear fusion reactions at its core. Part of this
                  internal energy is emitted from its surface as light,
                  ultraviolet, and infrared radiation, providing most of the
                  energy for life on Earth.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Sun's gravity holds the solar system together, keeping
                  everything, from the biggest planets to the smallest particles
                  of debris, in its orbit. The connection and interactions
                  between the Sun and Earth drive the seasons, ocean currents,
                  weather, climate, radiation belts and auroras.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The Sun moves around the Galactic Center of the Milky Way, at
                  a distance of 26,660 light-years. From Earth it is on average
                  1 AU (1.496×108 km) or about 8 light-minutes far away. Its
                  diameter is about 1,391,400 km (864,600 mi; 4.64 ls), 109
                  times that of Earth or 4 lunar distances. Its mass is about
                  330,000 times that of Earth, making up about 99.86% of the
                  total mass of the Solar System.[18] Roughly three-quarters of
                  the Sun's mass consists of hydrogen (~73%); the rest is mostly
                  helium (~25%), with much smaller quantities of heavier
                  elements, including oxygen, carbon, neon, and iron.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Though it is special to us, there are billions of stars like
                  our Sun scattered across the Milky Way galaxy.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="over-ifrem-main">
        <iframe
          src="https://www.solarsystemscope.com/iframe"
          width="500"
          height="400"
          className="ifram-main-div"
        ></iframe>
      </div>
    </>
  );
};

export default SunOverview;
