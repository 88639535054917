import React, { useRef } from "react";
import "../AllPlanets/AllPlanets.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import left from "../../Images/left-arrow.png";
import Right from "../../Images/arrow-right.png";
import Plan1 from "../../Images/mercuryimages.avif";
import Plan2 from "../../Images/VenusImgs .webp";
import Plan3 from "../../Images/Earth Imgs.avif";
import Plan4 from "../../Images/MarsImg .webp";
import Plan5 from "../../Images/JupiterImgs .jpg";
import Plan6 from "../../Images/SaturnImgs.avif";
import Plan7 from "../../Images/UranusImgs.jpg";
import Plan8 from "../../Images/NeptuneImg.avif";
import Plan9 from "../../Images/PlutoMainimg.jpg";
import Plan10 from "../../Images/DwarfIMg.png";
import { BsSkipForwardCircleFill } from "react-icons/bs";
import { BsSkipBackwardCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
const AllPlanets = () => {
  const btnref = useRef(null);
  const data = [
    {
      img: Plan1,
      title: "	Mercury ",
      cardClass: "Card-1-index",
      link: "/Mercury",
    },
    {
      cardClass: "Card-2-index",
      img: Plan2,
      title: "Venus",
      link: "/Venus",
    },
    {
      cardClass: "Card-3-index",
      img: Plan3,
      title: "Earth ",
      link: "/Earth",
    },
    {
      cardClass: "Card-4-index",
      img: Plan4,
      title: "Mars ",
      link: "/Mars",
    },
    {
      cardClass: "Card-5-index",
      img: Plan5,
      title: "Jupiter",
      link: "/Jupiter",
    },
    {
      cardClass: "Card-6-index",
      img: Plan6,
      title: "Saturn ",
      link: "/Saturn",
    },
    {
      cardClass: "Card-7-index",
      img: Plan7,
      title: "Uranus",
      link: "/Uranus",
    },
    {
      cardClass: "Card-8-index",
      img: Plan8,
      title: "Neptune",
      link: "/Neptune",
    },
    {
      cardClass: "Card-8-index",
      img: Plan9,
      title: "Pluto ",
      link: "/Pluto",
    },
    {
      cardClass: "Card-8-index",
      img: Plan10,
      title: "Dwarf Planets  ",
      link: "/DwarfPlanets",
    },
  ];
  return (
    <>
      <section className="Section-Four-Main" id="Method-use-Scroll">
        <div className="Perent-Fours">
          <div className="Swiper-Button">
            <div onClick={() => btnref.current.swiper.slidePrev()}>
              <BsSkipBackwardCircleFill className="IoChevronBackCircle-main" />
            </div>

            <Swiper
              ref={btnref}
              slidesPerGroup={1}
              slidesPerView={3}
              loop={true}
              spaceBetween={0}
              // autoplay={{
              //   delay: 2000,
              // }}
              // modules={[Autoplay]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                770: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1358: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
                1920: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              <div
                style={{
                  width: "1200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {data.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      {item.link ? (
                        <Link className="Link-text-in" to={item.link}>
                          <div className="Our-methods-class">
                            <div className="Name-and-Head">
                              <img
                                src={item.img}
                                alt="None"
                                className="Img-section-four"
                              />
                              <div className="title-heading">
                                <span>{item.title}</span>
                              </div>
                              <div className="Para-heading">
                                <span>{item.Para}</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <Link className="Link-text-in" to={item.link}>
                          <div className="Our-methods-class">
                            <div className="Name-and-Head">
                              <img
                                src={item.img}
                                alt="None"
                                className="Img-section-four"
                              />
                              <div className="title-heading">
                                <span>{item.title}</span>
                              </div>
                              <div className="Para-heading">
                                <span>{item.Para}</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      )}
                    </SwiperSlide>
                  );
                })}
              </div>
            </Swiper>

            <div onClick={() => btnref.current.swiper.slideNext()}>
              <BsSkipForwardCircleFill className="IoChevronBackCircle-main" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllPlanets;
