import React from "react";
import planet from "../../Images/dwarfplanetanyway.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import AllDwarfPlanets from "../AllDwarfPlanets/AllDwarfPlanets";
import DwarfPlanetsOverView from "../DwarfPlanetsOverView/DwarfPlanetsOverView";
import Navbar from "../../NavBar/Navbar";
const DwarfPlanets = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Dwarf Planets</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  What is a dwarf planet anyway?
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AllDwarfPlanets />
      <DwarfPlanetsOverView />
    </>
  );
};

export default DwarfPlanets;
