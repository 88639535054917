import React from "react";
import "../Css/HomePage.css";
import Home from "./Home";
import homec from "../Images/Stretviweimg.avif";
import homec2 from "../Images/stretviweimg2.avif";
import homec3 from "../Images/volcan.avif";
import homec4 from "../Images/Allflagsavif.avif";
import Like from "../Images/Likelogo.png";
import { useNavigate } from "react-router-dom";
import Tweet from "../Images/Tweetlogo.png";
import homec5 from "../Images/Wondertaj.avif";
import homec6 from "../Images/CompleteListofAirport Codes.avif";
import Con1 from "../Images/OneCon.avif";
import Con2 from "../Images/TwoCon.avif";
import Con3 from "../Images/ThreCon.avif";
import Con4 from "../Images/Forthcon.avif";
import Con5 from "../Images/FiveCon.avif";
import Con6 from "../Images/SixCon.avif";
import Con7 from "../Images/SavenCon.avif";
import Con8 from "../Images/AitheCon.avif";
import Con9 from "../Images/NainthCon.avif";
import Con10 from "../Images/TanCon.avif";
import Con11 from "../Images/IlevoneCon.avif";
import Con12 from "../Images/TwalCon.avif";
import Con13 from "../Images/ThartinCon.avif";
import Con14 from "../Images/FortinCon.avif";
import Space from "../Images/SpaceMainimgs.jpg";
import Blogs from "../Images/blog.webp";
const HomePage = () => {
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <section className="Streetview-section">
        <div className="Streetview-div">
          <div className="buttn-logo-div">
            <div className="Pading-ka-liya">
              {/* <div className="logo-btndiv">
                <div className="dono-btn-kaliya">
                  <button className="like-img-bnt">
                    <img className="like-img" src={Like} alt="" />
                    Like
                  </button>
                  <button className="like-img-bnt">
                    <img className="like-img" src={Tweet} alt="" />
                    Tweet
                  </button>
                </div>
              </div> */}

              <div>
                <span className="Google-span-ha-Views">Welcome To </span>

                <span className="Sphere-span-ha-Photo">I-Geographics</span>
              </div>
            </div>
          </div>
          <div className="homec-landing-page">
            <div className="homec-landing-page-second">
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/ContriesOfThaWorld")}
                  className="main-home-stret-img"
                  src={Con1}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Countries of the World </span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/Flags")}
                  className="main-home-stret-img"
                  src={homec4}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Flags Of The World</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/Nationalanthemsoftheworld")}
                  className="main-home-stret-img"
                  src={Con2}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>National Anthems</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/StreetView")}
                  className="main-home-stret-img"
                  src={homec}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Street Views & Street Map </span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/CountryCodesList")}
                  className="main-home-stret-img"
                  src={Con3}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Countries codes </span>
                </div>
              </div>{" "}
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/Ports")}
                  className="main-home-stret-img"
                  src={homec3}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Port codes </span>
                </div>
              </div>{" "}
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/FREENauticalCharts")}
                  className="main-home-stret-img"
                  src={Con4}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Free Nautical Charts</span>
                </div>
              </div>{" "}
              <div className="Street-map-main-div">
                <img
                  onClick={() =>
                    Navigate("/ListoflargestairlinesinNorthAmerica")
                  }
                  className="main-home-stret-img"
                  src={Con5}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Greatest Airlines</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/Listofbusiest")}
                  className="main-home-stret-img"
                  src={Con6}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Busiest Airports</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/ListOfBusiestContainer")}
                  className="main-home-stret-img"
                  src={Con7}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Busiest Ports</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/Wondersoftheworld")}
                  className="main-home-stret-img"
                  src={homec5}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Wonders of the world</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/ListofHighestMountains")}
                  className="main-home-stret-img"
                  src={Con8}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>The highest Mountains on Earth</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/Listoflakesbyarea")}
                  className="main-home-stret-img"
                  src={Con11}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Greatest Lakes</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/ListOfRiverSystems")}
                  className="main-home-stret-img"
                  src={Con9}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Greatest Rivers</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/VolcanoBycountry")}
                  className="main-home-stret-img"
                  src={Con10}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Greatest Volcanos</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/ContinentsOfTheWorld")}
                  className="main-home-stret-img"
                  src={Con12}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Continents</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/WhatisOcean")}
                  className="main-home-stret-img"
                  src={Con13}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Oceans</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/GuidetoCountry")}
                  className="main-home-stret-img"
                  src={Con14}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>The World Fact Book or Country Rankings</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/SolarSystemExploration")}
                  className="main-home-stret-img"
                  src={Space}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Space</span>
                </div>
              </div>
              <div className="Street-map-main-div">
                <img
                  onClick={() => Navigate("/Blog")}
                  className="main-home-stret-img"
                  src={Blogs}
                  alt=""
                />
                <div className="span-map-Street">
                  <span>Blog</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
