import React, { useState, useEffect, useRef } from "react";
import ContriesOfLogo from "../Images/Worldimg.jpg";
import "../Css/CountryCodesList.css";
import axios from "../Schemas/Api";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";

import back from "../Images/back_arrow.png";
import { useNavigate } from "react-router-dom";

const CountryCodesList = () => {
  const [ContryCode, setContryCode] = useState([]);
  //   console.log(ContryData.country_name, "ContryData");
  const initialValue = "country_name";
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [loading, setloading] = useState(true);
  console.log(selectedValue, "selectedValue");
  const handleValueChange = (event) => {
    setSelectedValue(event.target.innerText);
  };

  const ContriesOf = async () => {
    setloading(true);
    try {
      let poly = {
        url: "/get_country_code",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons.data);
          setContryCode(respons.data);
          setloading(false);
        });
    } catch (error) {
      console.log("userap...", error);
      setloading(false);
    }
  };
  useEffect(() => {
    ContriesOf();
  }, []);

  const array = [];
  for (let i = 65; i <= 90; i++) {
    array.push(String.fromCharCode(i));
  }

  const remaining = 26 - array.length;
  for (let i = 0; i < remaining; i++) {
    array.push(""); // Fill with empty strings
  }
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views">
              {" "}
              Countries and Regions of the World from A to Z{" "}
            </span>
            <span className="Sphere-span-ha-Photo">Find by Country Name:</span>
          </div>
        </div>
      </div>
      <section className="Countries-of-tha-world-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Countries-of-tha-world-div">
          <div>
            {array.map((letter) => (
              <a
                key={letter}
                href={`#${letter}`}
                style={{ textDecoration: "none" }}
              >
                {""} {letter} {""}|
              </a>
            ))}
          </div>
          <div>
            <img className="ContriesOfLogo-img" src={ContriesOfLogo} alt="" />
          </div>
          <div className="Countries-of-tha-Thard-div mt-5">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="wasdwasdwasd-ka-bhi-wasd"
            >
              <div className="country-main-logo-flags-he">
                <span className="Flag-all-span">Flag</span>
              </div>
              <div className="country-name-logoc-img-2">
                <span className="Flag-all-span">Country</span>
              </div>

              <div className="country-name-logoc-img-3">
                <span className="Flag-all-span">Alpha2 Code</span>
              </div>

              <div className="country-name-logoc-img-4">
                <span className="Flag-all-span">Alpha3 Code</span>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              {array.map((letter) => (
                <div
                  key={letter}
                  id={letter}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <h2>{letter} </h2>
                  {ContryCode.filter(
                    (item) =>
                      item.country_name[0].toLowerCase() ===
                      letter.toLowerCase()
                  ).map((item) => (
                    <div
                      key={item.country_name}
                      n
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      className="wasdwasdwasd-ka-bhi-wasd"
                    >
                      <div className="country-main-logo-flags-he">
                        <img
                          src={item.flag}
                          alt=""
                          width="20px"
                          height="auto"
                        />
                      </div>
                      <div className="country-name-logoc-img-2">
                        <span>{item.country_name}</span>
                      </div>
                      <div className="country-name-logoc-img-3">
                        <span>{item.alpha2_code}</span>
                      </div>
                      <div className="country-name-logoc-img-4">
                        <span>{item.alpha3_code}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default CountryCodesList;
