import React from "react";
import planet from "../../../Images/EarthMoonMainimg.jpg";
import sedo from "../../../Images/dark_layer@3x.png";
import EarthMoonOverView from "../EarthMoonOverView/EarthMoonOverView";
import Navbar from "../../../NavBar/Navbar";
const EarthMoon = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Earth Moon</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  Our Moon is the only place beyond Earth where humans have set
                  foot, so far.
                </span>
              </div>
              <div className="our-solar-span">
                <span className="UR-SOLAR-SYSTEM-span">TODAY</span>
              </div>
              <div className="Dwarf-Planets-div-do-Missions">
                <div className="Planets-etha-div">
                  <span className="eath-span">03</span>
                  <span className="Planets-main-sapn">Active Missions</span>
                </div>
                <div className="Planets-etha-div">
                  <span className="eath-span">04</span>
                  <span className="Planets-main-sapn">Upcoming Missions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EarthMoonOverView />
    </>
  );
};

export default EarthMoon;
