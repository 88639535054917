import React, { useState, useEffect } from "react";
import "../Css/Blog.css";
import Bimg from "../Images/Ayaworld.jpg";
import { LiaGreaterThanSolid } from "react-icons/lia";
import axios from "../Schemas/Api";
import Carousel from "react-bootstrap/Carousel";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import back from "../Images/back_arrow.png";
import Home from "./Home";
// import ExampleCarouselImage from "components/ExampleCarouselImage";

const Blog = () => {
  const Navigate = useNavigate();
  const [Values, setValues] = useState("All");
  const [loading, setloading] = useState(true);
  const [Categary, setCategary] = useState([]);
  const [Categaryid, setCategaryid] = useState(null);
  const [featured, setfeatured] = useState([]);
  const [DataCategary, setDataCategary] = useState([]);

  console.log(DataCategary);

  console.log(Categary, "Categary");
  const countrydata = async () => {
    setloading(true);
    try {
      let Flagyy = {
        url: `/blogs?page=1&per_page=10&category_id=${Categaryid}`, //192.168.1.10:3223///countries_by_continent?continent=Europe
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons);
          setCategary(respons.data.categories);
          setfeatured(respons.data.featured_blogs);
          setDataCategary(respons.data.data);
          setloading(false);
        });
    } catch (error) {
      console.log("userap...", error);
      setloading(false);
    }
  };
  useEffect(() => {
    countrydata(Categaryid);
  }, [Categaryid]);

  const hendelClick = (itm) => {
    setCategaryid(itm.id);
  };

  const hendelAll = () => {
    setCategaryid(null);
  };

  const hendelDatail = (itm) => {
    Navigate("/BlogDetail", {
      state: {
        itm,
      },
    });
  };

  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          <div>
            <span className="Google-span-ha-Views"> Blog </span>
          </div>
        </div>
      </div>
      <div className="blog-main-div">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="main-section-div-blog">
          <Carousel fade>
            {featured.map((itm, index) => {
              return (
                <Carousel.Item className="main-Carousel-atem">
                  <div className="main_image_div_blog">
                    <div>
                      <span className="Main-titel-span">Title :- </span>
                      {""} <span className="itam-and-title"> {itm.title}</span>
                    </div>
                    <div className="main-img-div">
                      <img className="Bimg-main-img" src={itm.image} alt="" />
                    </div>
                    <div className="Content-main-div-and-text">
                      <span className="Main-titel-span">Content :-</span>
                      <span className="itam-and-title">{itm.content}</span>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>

          {/* {featured.map((itm, index) => {
            return (
              <div className="main-img-div">
                <img className="Bimg-main-img" src={itm.image} alt="" />
              </div>
            );
          })} */}
        </div>
      </div>
      <div className="dasbord_inner_box mt-3">
        <div className="main_btn_box">
          <div className="heading_btn_div">
            <span className="das_btn" onClick={hendelAll}>
              All
            </span>
            {Categary.map((itm, index) => {
              return (
                <span className="das_btn" onClick={() => hendelClick(itm)}>
                  {itm.title}
                </span>
              );
            })}
          </div>
        </div>
      </div>

      <div className="blog-main-div">
        <div className="main-data-of-Categary">
          {DataCategary.map((itm, index) => {
            return (
              <div
                onClick={() => hendelDatail(itm)}
                className="img-and-title-div"
              >
                <img className="Main-DataCategary-img" src={itm.image} alt="" />
                <span> {itm.title}</span>
              </div>
            );
          })}
        </div>
      </div>

      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Blog;
