import React from "react";

const CometsOverview = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">What is a comet? </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Comets are celestial bodies of profound intrigue, represent
                  dynamic remnants from the outer precincts of our solar system.
                  Comprising a heterogeneous amalgamation of ices, gases, dust,
                  and organic compounds, comets, when summoned by gravitational
                  perturbations, embark on compelling journeys towards the Sun,
                  unveiling unique insights into the cosmos.
                </span>
              </div>
              <div className="mt-4 mb-5">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    Composition of Comets
                  </span>
                </div>
                <span className="asteroids-main-span">
                  Comets are predominantly composed of a nucleus, a solid core
                  featuring water ice, frozen gases (carbon dioxide, methane,
                  ammonia), and particulate matter. The nomenclature "dirty
                  snowball" aptly encapsulates the heterogeneity of these
                  nuclei, underscoring the amalgamation of volatile ices and
                  non-volatile materials.
                </span>
              </div>
              <div className="mt-4 mb-5">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">Orbital Origins</span>
                </div>
                <span className="asteroids-main-span">
                  The provenance of comets lies in the Kuiper Belt and the Oort
                  Cloud, distant reservoirs located beyond the orbit of Pluto.
                  Disturbances in their trajectories, driven by gravitational
                  interactions or other celestial forces, propel these icy
                  bodies towards the inner solar system, heralding their
                  temporary emergence into visibility.
                </span>
              </div>
              <div className="mt-4 mb-5">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    Categories of comets
                  </span>
                </div>
                <span className="asteroids-main-span">
                  Comets come in two primary categories: periodic and
                  non-periodic. Periodic comets, like Halley's Comet, follow
                  predictable orbits and return to the inner solar system at
                  regular intervals. Non-periodic comets, on the other hand,
                  make a one-time appearance, their trajectories influenced by
                  various factors that determine their unique and often
                  unpredictable paths.
                </span>
              </div>

              <div className="mt-4 mb-5">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    Solar Interaction and Cometary Transformation
                  </span>
                </div>
                <span className="asteroids-main-span">
                  As comets approach the Sun, the increasing solar radiation
                  heats their nucleus. This process leads to the sublimation of
                  the ices, transforming them into a glowing coma—a nebulous
                  cloud of gas and dust that envelops the nucleus. Solar wind
                  and radiation pressure then shape this coma into the iconic
                  tail that always points away from the Sun.
                </span>
              </div>

              <div className="mt-4 mb-5">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    Notable Comets in History
                  </span>
                </div>
                <span className="asteroids-main-span">
                  Throughout history, certain comets have left an indelible mark
                  on human consciousness. Halley's Comet, with its regular
                  appearances every 76 years, has been observed and documented
                  since ancient times. Comets have been viewed as omens,
                  portents, and even as messengers from the cosmos, contributing
                  to their significance in various cultures.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CometsOverview;
