import React, { useState, useEffect, useRef } from "react";
import axios from "../Schemas/Api";
import "../Css/LargeEruptions.css";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import { useNavigate } from "react-router-dom";
import back from "../Images/back_arrow.png";
const LargeEruptions = () => {
  const [LargeEru, setLargeEru] = useState([]);

  const LargeEruptions = async () => {
    try {
      let poly = {
        url: "/List_of_largest_volcanic_eruptions",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons.data);
          setLargeEru(respons.data);
          //   setloading(false);
        });
    } catch (error) {
      console.log("userap...", error);
    }
  };
  useEffect(() => {
    LargeEruptions();
  }, []);
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views">
              {" "}
              List of volcanic eruptions{" "}
            </span>

            <span className="Sphere-span-ha-Photo">in the 21st century</span>
          </div>

          <div className="main-volconec-div">
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/VolcanoBycountry")}
            >
              Volcanoes by country
            </span>
            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/LargeEruptions")}
            >
              Recent Volcanic eruptions
            </span>

            <span
              className="Volcanic-Recent-class"
              onClick={() => Navigate("/ListOfVolcanic")}
            >
              Largest volcanic eruptions
            </span>
          </div>
        </div>
      </div>

      <section className="Countries-of-tha-world-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Volcanic-of-tha-world-div">
          <div className="Countries-of-tha-Thard-div">
            <div className="Florida-Coast-Keys">
              <span className="Countries-Regions-az">
                List of volcanic eruptions in the 21st century
              </span>
            </div>
            <table className="Alfreds-tebal">
              <tr className="Alfreds-tr">
                <td className="eruption-td-1-LargeEru"> Volume</td>
                <td className="age-td-spam-2-LargeEru"> Country</td>
                <td className="age-td-spam-2-LargeEru">Year</td>
                <td className="age-td-spam-2-LargeEru">
                  max Plume Height (km){" "}
                </td>
                <td className="age-td-spam-2-LargeEru">Falalities </td>
                <td className="Notes-aa-gai-LargeEru">Notes</td>
              </tr>{" "}
            </table>

            {LargeEru.map((itm, index) => {
              return (
                <table className="Alfreds-tebal">
                  <tr className="Alfreds-tr">
                    <td className="eruption-td-LargeEru">{itm.volcano}</td>
                    <td className="age-td-spam-LargeEru ">{itm.country}</td>
                    <td className="age-td-spam-LargeEru ">{itm.year}</td>
                    <td className="age-td-spam-LargeEru ">
                      {itm.max_pump_height}
                    </td>
                    <td className="age-td-spam-LargeEru ">{itm.Fatalities} </td>
                    <td className="fir-se-notes-LargeEru"> {itm.notes}</td>
                  </tr>{" "}
                </table>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default LargeEruptions;
