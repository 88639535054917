import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "../Css/SearchBar.css";
import { useNavigate } from "react-router-dom";

const SearchBar = ({ handleSearch }) => {
  const [textboxValue, setTextboxValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const naviagte = useNavigate();

  const handleChange = (value) => {
    setSearchTerm(value);
  };

  const clearTextbox = () => {
    setTextboxValue("");
  };
  const handleSelect = async (address) => {
    try {
      setSearchTerm(address);
      handleSearch(address);

      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      localStorage.setItem("address", JSON.stringify(address));
      localStorage.setItem("latlng", JSON.stringify(latLng));

      // Redirect to street view page passing the latLng object as a query parameter
      // window.location.href = `/streetview?lat=${latLng.lat}&lng=${latLng.lng}`;
      naviagte("/StreetViewPage", {
        state: {
          latLng,
        },
      });
    } catch (error) {
      console.error("Error retrieving latitude and longitude:", error);
    }
  };

  return (
    <div className="SearchBar">
      <PlacesAutocomplete
        value={searchTerm}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              value={textboxValue}
              onChange={(e) => setTextboxValue(e.target.value)}
              {...getInputProps({
                placeholder: "Search...",
                className: "SearchBar-input",
              })}
            />
            {textboxValue && (
              <button className="clear-button" onClick={clearTextbox}>
                &#x2715;
              </button>
            )}
            <div
              className={`SearchBar-suggestions suggestion-box ${
                !textboxValue && "hidden"
              }`}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "SearchBar-suggestion-active"
                  : "SearchBar-suggestion";

                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, { className })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default SearchBar;
