import React from "react";
import OortClou2 from "../../../Images/NeptuneMoons1.jpg";
import OortClou3 from "../../../Images/NeptuneMoon2.jpg";
import OortClou4 from "../../../Images/NeptuneMoon3.webp";

const NeptuneMoonOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Neptune Moon Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  The planet Neptune has 14 known moons, which are named for
                  minor water deities in Greek mythology.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  By far the largest of them is Triton, discovered by William
                  Lassell on October 10, 1846, 17 days after the discovery of
                  Neptune itself.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Triton is unique among moons of planetary mass in that its
                  orbit is retrograde to Neptune's rotation and inclined
                  relative to Neptune's equator, which suggests that it did not
                  form in orbit around Neptune but was instead gravitationally
                  captured by it.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  The capture of Triton was a catastrophic event for Neptune's
                  original satellites, disrupting their orbits so that they
                  collided to form a rubble disc. Triton is massive enough to
                  have achieved hydrostatic equilibrium and to retain a thin
                  atmosphere capable of forming clouds and hazes.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Inward of Triton are seven small regular satellites, all of
                  which have prograde orbits in planes that lie close to
                  Neptune's equatorial plane. Some of these orbit among
                  Neptune's rings. The largest of them is Proteus.
                </span>
              </div>

              <div className="mt-4">
                <span className="asteroids-main-span">
                  Neptune also has six more outer irregular satellites other
                  than Triton, including Nereid, whose orbits are much farther
                  from Neptune and at high inclination: three of these have
                  prograde orbits, while the remainder have retrograde orbits.
                </span>
              </div>

              <div className="mt-4">
                <span className="asteroids-main-span">
                  In particular, Nereid has an unusually close and eccentric
                  orbit for an irregular satellite, suggesting that it may have
                  once been a regular satellite that was significantly perturbed
                  to its current position when Triton was captured. The two
                  outermost Neptunian irregular satellites, Psamathe and Neso,
                  have the largest orbits of any natural satellites discovered
                  in the Solar System to date.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">
                Names of Neptune Moons
              </span>
            </div>
            <span className="asteroids-main-span">
              Despina, Galatea, Halimede, Hippocamp, Laomedeia, Larissa, Naiad,
              Nereid, Neso, Proteus, Psamathe, Sao, Thalassa, Triton.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default NeptuneMoonOverView;
