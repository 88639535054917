import React from "react";

const AsteroidsOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Asteroids comets, and meteors Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Asteroids, comets, and meteors are rocky object, ice, metal
                  left over and dust from the formation of our solar system 4.6
                  billion years ago.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  There are currently about 1.3 million known asteroids, and
                  more than 3,800 known comets.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AsteroidsOverView;
