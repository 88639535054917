import React, { useState, useEffect, useRef } from "react";
import "../Css/Flags.css";
import logo1 from "../Images/Countrylogo.avif";
import logo2 from "../Images/Countrylogo2.avif";
import logo3 from "../Images/Countrylogo3.avif";
import logo4 from "../Images/Countrylogo4.avif";
import close from "../Images/Close.png";
import logo5 from "../Images/Countrylogo5.avif";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import back from "../Images/back_arrow.png";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const Flags = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const [image, setimage] = useState("");
  const [show, setshow] = useState("");
  const [Flags, setFlags] = useState([]);
  console.log(image, "image");
  const Imagelink = (e) => {
    setimage(e.target.src);
    setshow(true);
  };

  const countrydata = async () => {
    setloading(true);

    try {
      let Flagyy = {
        url: "/list_of_flags",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(Flagyy)
        .then((res) => res.data)
        .then((respons) => {
          setFlags(respons.data);
          console.log(respons);
          console.log(Flags);
          setloading(false);
        });
    } catch (error) {
      setloading(false);
      console.log("userap...", error);
    }
  };
  useEffect(() => {
    countrydata();
  }, []);

  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views"> Flags Of </span>

            <span className="Sphere-span-ha-Photo"> The World</span>
          </div>
        </div>
      </div>
      {show && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshow(false)} />
            <img src={image} alt="" />
          </div>
        </div>
      )}
      <div>
        <div className="street-img-loc">
          <div onClick={() => navigate(-1)} className="back-ka-div">
            <img src={back} className="Back-btn-ok" alt="" />
            <span>Back</span>
          </div>
          <div className="Flags-img-loc-div">
            {Flags.map((itm, index) => {
              return (
                <>
                  <div className="Flags-all-contry">
                    <div className="span-flangsss-img">
                      <img
                        key={index}
                        className="flags-alll-off-img"
                        onClick={Imagelink}
                        src={itm.flag_path}
                        alt=""
                      />
                    </div>
                    <span className="country-flag_path-span">
                      {itm.country}
                    </span>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Flags;
