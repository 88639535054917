import React from "react";
import "../Jupiter/Jupiter.css";
import planet from "../../Images/jupiterMainimg1.jpg";
import sedo from "../../Images/dark_layer@3x.png";
import Navbar from "../../NavBar/Navbar";
import JupiterOverveiwe from "../JupiterOverveiwe/JupiterOverveiwe";
const Jupiter = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">Jupiter</h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  Jupiter is, by far, the largest planet in the solar system –
                  more than twice as massive as all the other planets combined.
                </span>
              </div>
              <div className="our-solar-span">
                <span className="UR-SOLAR-SYSTEM-span">QUICK STATS</span>
              </div>
              <div className="Dwarf-Planets-div-do-Missions">
                <div className="Planets-etha-div">
                  <span className="eath-span">95</span>
                  <span className="Planets-main-sapn">Moons</span>
                </div>
                <div className="Planets-etha-div">
                  <span className="eath-span">03</span>
                  <span className="Planets-main-sapn">Missions on Deck</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <JupiterOverveiwe />
    </>
  );
};

export default Jupiter;
