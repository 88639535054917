import React from "react";
import planet from "../../../Images/JupiterMoonImg.jpg";
import sedo from "../../../Images/dark_layer@3x.png";
import "../JupiterMoon/JupiterMoon.css";
import JupiterMoonOverview from "../JupiterMoonOverview/JupiterMoonOverview";
import Navbar from "../../../NavBar/Navbar";
const JupiterMoon = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Ceres-main-span-div">
              <div className="Jupiter-Moon-w-div">
                <h1 className="Solar-span-System">Moon Of Jupiter</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <JupiterMoonOverview />
    </>
  );
};

export default JupiterMoon;
