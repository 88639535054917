import React from "react";
import OortClou2 from "../../Images/Earthhas1.jpg";
import OortClou3 from "../../Images/Earthhas2.jpg";
import OortClou4 from "../../Images/Earthhas3.jpg";

const EarthOverViwe = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Earth Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Earth —our home planet— is the third planet from the Sun, and
                  the only place we know of so far that is inhabited by living
                  things. It is also the only planet in our solar system with
                  liquid water on the surface.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Almost all of Earth's water is contained in its global ocean,
                  covering 70.8% of Earth's crust. The remaining 29.2% of
                  Earth's crust is land, most of which is located in the form of
                  continental landmasses within one hemisphere, Earth's land
                  hemisphere.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Most of Earth's land is somewhat humid and covered by
                  vegetation, while large sheets of ice at Earth's polar deserts
                  retain more water than Earth's groundwater, lakes, rivers and
                  atmospheric water combined.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Earth's crust consists of slowly moving tectonic plates, which
                  interact to produce mountain ranges, volcanoes, and
                  earthquakes. Earth has a liquid outer core that generates a
                  magnetosphere capable of deflecting most of the destructive
                  solar winds and cosmic radiation.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Earth has a dynamic atmosphere, which sustains Earth's surface
                  conditions and protects it from most meteoroids and UV-light
                  at entry. It has a composition of primarily nitrogen and
                  oxygen. Water vapor is widely present in the atmosphere,
                  forming clouds that cover most of the planet. The water vapor
                  acts as a greenhouse gas and, together with other greenhouse
                  gases in the atmosphere, particularly carbon dioxide (CO2),
                  creates the conditions for both liquid surface water and water
                  vapor to persist via the capturing of energy from the Sun's
                  light.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  This process maintains the current average surface temperature
                  of 14.76 °C, at which water is liquid under atmospheric
                  pressure. Differences in the amount of captured energy between
                  geographic regions (as with the equatorial region receiving
                  more sunlight than the polar regions) drive atmospheric and
                  ocean currents, producing a global climate system with
                  different climate regions, and a range of weather phenomena
                  such as precipitation, allowing components such as nitrogen to
                  cycle.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Earth is rounded into an ellipsoid with a circumference of
                  about 40,000 km. It is the densest planet in the Solar System.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Earth is the largest of the four rocky planets and the most
                  massive one.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Moreover, Earth is about eight light-minutes away from the
                  Sun. It orbits Sun, taking a year (about 365.25 days) to
                  complete one rotation.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Earth rotates around its own axis in slightly less than a day
                  (in about 23 hours and 56 minutes). Earth's axis of rotation
                  is tilted with respect to the perpendicular to its orbital
                  plane around the Sun, producing seasons.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Earth is orbited by one permanent natural satellite, the Moon,
                  which orbits Earth at 384,400 km (1.28 light seconds) and is
                  roughly a quarter as wide as Earth. Through tidal locking, the
                  Moon always faces Earth with the same side, which causes
                  tides, stabilizes Earth's axis, and gradually slows its
                  rotation.
                </span>
              </div>
              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Earth Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  Although all of the planets were named after Greek and Roman
                  gods and goddesses, the name Earth is a Germanic word, which
                  means “the ground.”
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              Earth has a radius of 3,959 miles (6,371 kilometers) and it is the
              biggest of the terrestrial planets and the fifth largest planet
              overall.
            </span>
            <span className="asteroids-main-span">
              From an average distance of 93 million miles (150 million
              kilometers), Earth is exactly one astronomical unit away from the
              Sun because one astronomical unit (abbreviated as AU), is the
              distance from the Sun to Earth.
            </span>
            <span className="asteroids-main-span">
              It takes about eight minutes for light from the Sun to reach our
              planet.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default EarthOverViwe;
