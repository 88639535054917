import React, { useState, useEffect } from "react";
import "../Css/Capitalcitiescountries.css";
import axios from "../Schemas/Api";
import Home from "./Home";
import Tweet from "../Images/Tweetlogo.png";
import back from "../Images/back_arrow.png";
import Like from "../Images/Likelogo.png";
import { useNavigate } from "react-router-dom";
const Capitalcitiescountries = () => {
  const FilterData = [
    {
      Contrys: "All",
    },
    {
      Contrys: "Asia",
    },
    {
      Contrys: "Europe",
    },
    {
      Contrys: "Africa",
    },
    {
      Contrys: "Oceania",
    },
    {
      Contrys: "North America",
    },
    {
      Contrys: "South America",
    },
    {
      Contrys: "Antarctica",
    },
  ];

  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("All");
  const [selected, setSelected] = useState(""); // State to store the selected region
  const [original, setOriginal] = useState([]); // State to store the original data
  // const [filteredData, setFilteredData] = useState([]);
  console.log(selected, " selectedRegion  ");
  const fetchData = async () => {
    try {
      const response = await axios.get("/capital_cities_by_continent");

      setOriginalData(response.data);
      setFilteredData(response.data);
      setOriginal(response.data);

      console.log(response.data, "response.data.data");
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedRegion === "All") {
      setFilteredData(originalData);
    } else {
      const filtered = originalData.filter(
        (item) => item.region === selectedRegion
      );
      setFilteredData(filtered);
    }
  }, [selectedRegion, originalData]);

  const handleRegionSelect = (region) => {
    setSelectedRegion(region);
  };
  useEffect(() => {
    if (selected === "") {
      // Show all data when nothing is selected
      setFilteredData(original);
    } else {
      // Filter data based on the selected region
      const filtered = original.filter((item) => item.region === selected);
      setFilteredData(filtered);
    }
  }, [selected, original]);

  const handleRegionChange = (event) => {
    const selectedValue = event.target.value;
    setSelected(selectedValue); // Update the selected state
  };
  const Navigate = useNavigate();

  return (
    <>
      <section className="Area-of-tha-Area-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Area-of-tha-world-div">
          <div>
            <div className="filter-span-all">
              <span>Regions</span>
              {FilterData.map((itm, index) => (
                <div
                  className={`all-raunder-div ${
                    itm.Contrys === selectedRegion ? "active" : ""
                  }`}
                  onClick={() => handleRegionSelect(itm.Contrys)}
                  key={index}
                >
                  <span className="mb-4 contrys-all-name-span">
                    {itm.Contrys}
                  </span>
                  <div className="raund-hover-active-sab">
                    <div className="red-line-div"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="selact-nhi-ho-ga-ya-ho-ga">
            <select onChange={handleRegionChange} value={selected}>
              {FilterData.map((itm, index) => (
                <option key={index} value={itm.Contrys}>
                  {itm.Contrys}
                </option>
              ))}
            </select>
          </div>
          <div className="tebale-main-section-area">
            <span className="busiest-btn-one">Capital Cities by Continent</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="Capital-main-countries"
            >
              <div className="contry-by-cunt">
                <span className="airport-all-span"> Name</span>
              </div>
              <div className="contry-by-cunt">
                <span className="airport-all-span">SQ KM</span>
              </div>
            </div>
            {filteredData.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="Capital-main-countries"
                >
                  <div className="contry-by-cunt">
                    <span className="main-airpot-span">{itm.name}</span>
                  </div>
                  <div className="contry-by-cunt">
                    <span className="main-airpot-span">{itm.capital}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Capitalcitiescountries;
