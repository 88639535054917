import React, { useState, useEffect, useRef } from "react";
import "../Css/AirportCode.css";
import ContriesOfLogo from "../Images/ContriesOfThaWorld.jpg";
// import logoc from "../Images/Countrylogo10.avif";
import axios from "../Schemas/Api";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import { useNavigate } from "react-router-dom";
import back from "../Images/back_arrow.png";
const AirportCode = () => {
  const [ContryData, setContryData] = useState([]);
  const [ContryDataSec, setContryDataSec] = useState(false);
  console.log(ContryDataSec, "ContryDataSec");
  //   console.log(ContryData.country_name, "ContryData");
  const [sortOrder, setSortOrder] = useState("");
  const initialValue = "country_name";
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [clickCount, setClickCount] = useState(0);
  const [loading, setloading] = useState(true);
  // console.log(selectedValue, "selectedValue");
  const handleValueChange = (event) => {
    setSelectedValue(event.target.innerText);
    setClickCount(clickCount + 1);
  };
  const ContriesOf = async () => {
    setloading(true);
    try {
      let poly = {
        url: "/get_geocountry",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons.data);
          setContryData(respons.data);
          setContryDataSec(false);
          setloading(false);
        });
    } catch (error) {
      console.log("userap...", error);
      setloading(false);
    }
  };
  useEffect(() => {
    ContriesOf();
  }, []);

  const handleSortingClick = (value) => {
    if (selectedValue === value) {
      setSortOrder(sortOrder === "" ? "desc" : "");
    } else {
      setSelectedValue(value);
      setSortOrder(""); // Reset sorting order when a new span is clicked
      setContryDataSec(true);
    }
  };
  console.log(sortOrder, "sortOrder");

  const array = [];
  for (let i = 65; i <= 90; i++) {
    array.push(String.fromCharCode(i));
  }

  const remaining = 26 - array.length;
  for (let i = 0; i < remaining; i++) {
    array.push(""); // Fill with empty strings
  }

  const SortC = async () => {
    setloading(true);
    try {
      const respons = await axios.post(
        "/sort_geocountry",
        {
          field: selectedValue,
          order: sortOrder,
        },
        {
          Headers: { "Content-Type": "application/json" },
        }
      );

      // console.log("res..... momomommm", respons);
      setContryData(respons.data.data);
      console.log("this is the response", respons.data.data);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    SortC();
  }, [selectedValue, sortOrder]);
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          <div className="logo-btndiv">
            {/* <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div> */}
          </div>

          <div>
            <span className="Google-span-ha-Views"> Complete List </span>

            <span className="Sphere-span-ha-Photo">of Airport Codes</span>
          </div>
        </div>
      </div>
      <section className="Countries-of-tha-world-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <spa>Back</spa>
        </div>
        <div className="Countries-of-tha-world-div">
          <div className="Countries-of-tha-Thard-div">
            <span className="Countries-Regions-az">
              Complete List of Airport Codes
            </span>
            {ContryDataSec ? null : (
              <>
                <div>
                  <div></div>
                </div>
              </>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="wasdwasdwasd-City-Contry"
            >
              <div className="country-name-logoc">
                <span className="Flag-all-span">Flag</span>
              </div>
              <div
                className="country-name-logoc"
                onClick={() => handleSortingClick("country_name")}
              >
                <span className="Flag-all-span">City</span>
                {selectedValue === "country_name" && sortOrder === "" && "▼"}
                {selectedValue === "country_name" &&
                  sortOrder === "desc" &&
                  "▲"}
              </div>
              <div
                className="country-name-logoc"
                onClick={() => handleSortingClick("land_area")}
              >
                <span className="Flag-all-span"> Contry</span>
                {selectedValue === "land_area" && sortOrder === "" && "▼"}
                {selectedValue === "land_area" && sortOrder === "desc" && "▲"}
              </div>
              <div
                className="country-name-logoc"
                onClick={() => handleSortingClick("population")}
              >
                <span className="Flag-all-span">Code</span>
                {selectedValue === "population" && sortOrder === "" && "▼"}
                {selectedValue === "population" && sortOrder === "desc" && "▲"}
              </div>
            </div>

            <>
              {ContryData.map((item) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="wasdwasdwasd"
                >
                  <div className="country-name-logoc-img">
                    <img
                      src={"http://192.168.1.29:3333/" + item.flag}
                      alt=""
                      width="20px"
                      height="auto"
                    />
                  </div>
                  <div className="country-name-logoc-img">
                    <span>{item.country_name}</span>
                  </div>
                  <div className="country-name-logoc-img">
                    <span>{item.land_area}</span>
                  </div>
                  <div className="country-name-logoc-img">
                    <span>{item.population}</span>
                  </div>
                  <div className="country-name-logoc-img">
                    <span>{item.population_percentage}</span>
                  </div>
                </div>
              ))}
            </>

            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {array.map((letter) => (
                <div
                  key={letter}
                  id={letter}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {ContryData.filter(
                    (item) =>
                      item.country_name[0].toLowerCase() ===
                      letter.toLowerCase()
                  ).map((item) => (
                    <div
                      key={item.country_name}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      className="wasdwasdwasd"
                    >
                      <div className="country-name-logoc-img">
                        <img
                          src={"http://192.168.1.14:3223/" + item.flag}
                          alt=""
                          width="20px"
                          height="auto"
                        />
                      </div>
                      <div className="country-name-logoc-img">
                        <span>{item.country_name}</span>
                      </div>
                      <div className="country-name-logoc-img">
                        <span>{item.land_area}</span>
                      </div>
                      <div className="country-name-logoc-img">
                        <span>{item.population}</span>
                      </div>
                      <div className="country-name-logoc-img">
                        <span>{item.population_percentage}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default AirportCode;
