import React from "react";
import OortClou2 from "../../Images/HaumeaDistance1.webp";
import OortClou3 from "../../Images/HaumeaDistance2.webp";
import OortClou4 from "../../Images/HaumeaDistance3.webp";

const HaumeaOverView = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Haumea Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Haumea is a dwarf planet located beyond Neptune's orbit.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Nominal estimates make it the third-largest known
                  trans-Neptunian object, after Eris and Pluto, and
                  approximately the size of Uranus's moon Titania.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Haumea was discovered in 2004 by a team headed by Mike Brown
                  of Caltech at the Palomar Observatory in the United States and
                  disputably also in 2005 by a team headed by José Luis Ortiz
                  Moreno at the Sierra Nevada Observatory in Spain.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Haumea's mass is about one-third that of Pluto, and 1/1400
                  that of Earth.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Although its shape has not been directly observed,
                  calculations from its light curve are consistent with it being
                  a Jacobi ellipsoid with its major axis twice as long as its
                  minor.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  In October 2017, astronomers announced the discovery of a ring
                  system around Haumea, representing the first ring system
                  discovered for a trans-Neptunian object.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Haumea's elongated shape together with its rapid rotation,
                  rings, and high albedo are thought to be the consequences of a
                  giant collision, which left Haumea the largest member of a
                  collisional family that includes several large trans-Neptunian
                  objects and Haumea's two known moons, Hiʻiaka and Namaka.
                </span>
              </div>

              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Haumea Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  On September 17, 2008, it was named after Haumea, the Hawaiian
                  goddess of fertility, under the expectation by the
                  International Astronomical Union (IAU) that it would prove to
                  be a dwarf planet.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              With a radius of about 385 miles (620 kilometers), Haumea is about
              1/14 the radius of Earth.
            </span>
            <span className="asteroids-main-span">
              From an average distance of 4,010,000,000 miles (6,452,000,000
              kilometers), Haumea is 43 astronomical units away from the Sun
              (One astronomical unit is the distance from the Sun to Earth).
              From this distance, it takes sunlight 6 hours to travel from the
              Sun to Haumea.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default HaumeaOverView;
