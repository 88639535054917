import React, { useState, useEffect } from "react";
import "../Css/Birthrate.css";
import axios from "../Schemas/Api";
import Home from "./Home";
import Tweet from "../Images/Tweetlogo.png";
import back from "../Images/back_arrow.png";
import Like from "../Images/Likelogo.png";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Childrenunder = () => {
  const FilterData = [
    {
      Contrys: "All",
    },
    {
      Contrys: "Central Asia",
    },
    {
      Contrys: "North America",
    },
    {
      Contrys: "East and Southeast Asia",
    },
    {
      Contrys: "South America",
    },
    {
      Contrys: "Australia and Oceania",
    },
    {
      Contrys: "South Asia",
    },
    {
      Contrys: "Africa",
    },
    {
      Contrys: "Middle East",
    },
    {
      Contrys: "Europe",
    },
    {
      Contrys: "Central America and the Caribbean",
    },
  ];
  const [loading, setloading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("All");
  const [selected, setSelected] = useState(""); // State to store the selected region
  const [original, setOriginal] = useState([]); // State to store the original data
  // const [filteredData, setFilteredData] = useState([]);
  console.log(selected, " selectedRegion  ");
  const fetchData = async () => {
    setloading(true);
    try {
      const response = await axios.get("/fact_children_underweight");

      setOriginalData(response.data);
      setFilteredData(response.data);
      setOriginal(response.data);
      setloading(false);
      console.log(response.data, "response.data.data");
    } catch (error) {
      console.log("Error fetching data:", error);
      setloading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedRegion === "All") {
      setFilteredData(originalData);
    } else {
      const filtered = originalData.filter(
        (item) => item.region === selectedRegion
      );
      setFilteredData(filtered);
    }
  }, [selectedRegion, originalData]);

  const handleRegionSelect = (region) => {
    setSelectedRegion(region);
  };
  useEffect(() => {
    if (selected === "") {
      // Show all data when nothing is selected
      setFilteredData(original);
    } else {
      // Filter data based on the selected region
      const filtered = original.filter((item) => item.region === selected);
      setFilteredData(filtered);
    }
  }, [selected, original]);

  const handleRegionChange = (event) => {
    const selectedValue = event.target.value;
    setSelected(selectedValue); // Update the selected state
  };
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}
          <div>
            <span className="Google-span-ha-Views">
              {" "}
              Children under the age of 5{" "}
            </span>
            <span className="Sphere-span-ha-Photo"> years underweight</span>
          </div>
        </div>
      </div>
      <section className="Area-of-tha-Area-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Area-of-tha-world-div">
          <div>
            <div className="filter-span-all">
              <span>Regions</span>
              {FilterData.map((itm, index) => (
                <div
                  className={`all-raunder-div ${
                    itm.Contrys === selectedRegion ? "active" : ""
                  }`}
                  onClick={() => handleRegionSelect(itm.Contrys)}
                  key={index}
                >
                  <span className="mb-4 contrys-all-name-span">
                    {itm.Contrys}
                  </span>
                  <div className="raund-hover-active-sab">
                    <div className="red-line-div"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="selact-nhi-ho-ga-ya-ho-ga">
            <select onChange={handleRegionChange} value={selected}>
              {FilterData.map((itm, index) => (
                <option key={index} value={itm.Contrys}>
                  {itm.Contrys}
                </option>
              ))}
            </select>
          </div>
          <div className="tebale-main-section-area">
            <span className="busiest-btn-one">
              Children under the age of 5 years underweight
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="Area-gografhiy-div"
            >
              <div className="Area-name-logoc-img-2">
                <span className="airport-all-span">Rank</span>
              </div>
              <div className="KM-main-div-sq">
                <span className="airport-all-span"> Country</span>
              </div>
              <div className="KM-main-div-sq">
                <span className="airport-all-span">%</span>
              </div>
              <div className="KM-main-div-sq">
                <span className="airport-all-span">Date of Information</span>
              </div>
            </div>
            {filteredData.map((itm, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  className="Area-gografhiy-div"
                >
                  <div className="areayy-system-logoc-img-1">
                    <span className="main-airpot-span">{itm.ranking}</span>
                  </div>
                  <div className="lakes-ary-logoc-img-1">
                    <span className="main-airpot-span">{itm.name}</span>
                  </div>
                  <div className="lakes-ary-logoc-img-1">
                    <span className="main-airpot-span">{itm.value}</span>
                  </div>
                  <div className="lakes-ary-logoc-img-1">
                    <span className="main-airpot-span">
                      {" "}
                      {itm.date_of_information} est.
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Childrenunder;
