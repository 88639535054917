import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Css/BlogDetail.css";
import Home from "./Home";
import back from "../Images/back_arrow.png";
const BlogDetail = () => {
  const Navigate = useNavigate();
  let router = useLocation();
  const CategaryCard = router.state.itm;
  console.log(CategaryCard, "CategaryCard");

  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          <div>
            <span className="Google-span-ha-Views"> Blog </span>
            <span className="Sphere-span-ha-Photo">Details</span>
          </div>
        </div>
      </div>

      <div className="blog-main-div">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="main-section-div-blog">
          <div className="CategaryCard-main-naviget">
            <span className="itam-and-title">{CategaryCard.title}</span>
            <img className="useLocation-img" src={CategaryCard.image} alt="" />
            <span className="itam-and-title">{CategaryCard.content}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
