import React from "react";
import planet from "../../Images/Asteroidmainimg1.png";
import sedo from "../../Images/dark_layer@3x.png";
import AsteroidsOverView from "../AsteroidsOverView/AsteroidsOverView";
import Navbar from "../../NavBar/Navbar";
const Asteroids = () => {
  return (
    <>
      <Navbar />
      <section className="SolarSystemExploration-main-sec">
        <div className="SolarSystemExploration-main-div">
          <div className="SolarImg-main-div">
            <img className="SolarImg-main-img" src={planet} alt="" />
            <img className="sedoImg-main-img" src={sedo} alt="" />
            <div className="Exploration-main-span-div">
              <div className="System-solar-w-div">
                <h1 className="Solar-span-System">
                  {" "}
                  Asteroids, Comets and Meteors
                </h1>
              </div>
              <div className="planetary-main-span">
                <span className="neighborhood-span">
                  Our solar system's small bodies pack big surprises.
                </span>
              </div>
              <div className="our-solar-span">
                <span className="UR-SOLAR-SYSTEM-span">EXPLORING ASTEROID</span>
              </div>
              <div className="Dwarf-Planets-div-do-Missions">
                <div className="Planets-etha-div">
                  <span className="eath-span">02</span>
                  <span className="Planets-main-sapn">ctive Missions</span>
                </div>
                <div className="Planets-etha-div">
                  <span className="eath-span">01</span>
                  <span className="Planets-main-sapn">Mission On Deck</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AsteroidsOverView />
    </>
  );
};

export default Asteroids;
