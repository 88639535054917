import React, { useState, useEffect } from "react";
import "../Css/ListOfRiverSystems.css";
import axios from "../Schemas/Api";
import Home from "./Home";
import Like from "../Images/Likelogo.png";
import Tweet from "../Images/Tweetlogo.png";
import back from "../Images/back_arrow.png";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ListOfRiverSystems = () => {
  const [ContryData, setContryData] = useState([]);
  const [ContryDataSec, setContryDataSec] = useState(false);
  const [sortOrder, setSortOrder] = useState("");
  const initialValue = "river_system";
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [clickCount, setClickCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortedData, setSortedData] = useState([]);

  const handleValueChange = (event) => {
    setSelectedValue(event.target.innerText);
    setClickCount(clickCount + 1);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/List_of_river_systems_by_length");
      setContryData(response.data.data);
      setLoading(false);
      console.log(response.data.data, "response.data.data");
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchSortedData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/List_of_river_systems_by_length",
        {
          field: selectedValue,
          order: sortOrder,
        },
        {
          Headers: { "Content-Type": "application/json" },
        }
      );
      setSortedData(response.data.data);
      console.log(response.data.data, "ban na swar na");
      setLoading(false);
    } catch (error) {
      console.log("Error fetching sorted data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Initial data fetch
  }, []);

  useEffect(() => {
    fetchSortedData(); // Fetch sorted data when selectedValue or sortOrder changes
  }, [selectedValue, sortOrder]);

  const handleSortingClick = (value) => {
    setSelectedValue(value);

    // Check if the "airport" button was clicked three times
    if (value === "river_system" && clickCount === 2) {
      // Perform the data fetch here
      fetchData();
      setContryDataSec(false); // Reset sorting
      setClickCount(0); // Reset click count
    } else {
      // For other buttons, continue with sorting logic
      setSortOrder(sortOrder === "" ? "desc" : "");
      setContryDataSec(true);
      setClickCount(clickCount + 1);
    }
  };
  const array = [];
  for (let i = 65; i <= 90; i++) {
    array.push(String.fromCharCode(i));
  }

  const remaining = 26 - array.length;
  for (let i = 0; i < remaining; i++) {
    array.push(""); // Fill with empty strings
  }
  const Navigate = useNavigate();
  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          {/* <div className="logo-btndiv">
            <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div>
          </div> */}

          <div>
            <span className="Google-span-ha-Views">
              {" "}
              List Of River Systems{" "}
            </span>
            <span className="Sphere-span-ha-Photo"> By Length</span>
          </div>
        </div>
      </div>
      <section className="Countries-of-tha-world-sec">
        <div onClick={() => Navigate(-1)} className="back-ka-div">
          <img src={back} className="Back-btn-ok" alt="" />
          <span>Back</span>
        </div>
        <div className="Countries-of-tha-world-div">
          <div className="Countries-of-tha-Thard-div">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="river_system-volum"
            >
              <div
                className="lakes-name-logoc-img-2"
                onClick={() => handleSortingClick("river_system")}
              >
                <span className="airport-all-span">River System</span>
                {selectedValue === "river_system" && sortOrder === "" && "▼"}
                {selectedValue === "river_system" &&
                  sortOrder === "desc" &&
                  "▲"}
              </div>
              <div
                className="lakes-name-logoc-img-2"
                onClick={() => handleSortingClick("length_km")}
              >
                <span className="airport-all-span"> Length km</span>
                {selectedValue === "length_km" && sortOrder === "" && "▼"}
                {selectedValue === "length_km" && sortOrder === "desc" && "▲"}
              </div>
              <div
                className="lakes-name-logoc-img-2"
                onClick={() => handleSortingClick("length_miles")}
              >
                <span className="airport-all-span">Length Miles</span>
                {selectedValue === "length_miles" && sortOrder === "" && "▼"}
                {selectedValue === "length_miles" &&
                  sortOrder === "desc" &&
                  "▲"}
              </div>
              <div
                className="lakes-name-logoc-img-2"
                onClick={() => handleSortingClick("drainage_area")}
              >
                <span className="airport-all-span">Drainage Area</span>
                {selectedValue === "drainage_area" && sortOrder === "" && "▼"}
                {selectedValue === "drainage_area" &&
                  sortOrder === "desc" &&
                  "▲"}
              </div>

              <div
                className="lakes-name-logoc-img-2"
                onClick={() => handleSortingClick("average_discharge")}
              >
                <span className="airport-all-span">Average Discharge</span>
                {selectedValue === "average_discharge" &&
                  sortOrder === "" &&
                  " ▼"}
                {selectedValue === "average_discharge" &&
                  sortOrder === "desc" &&
                  "▲"}
              </div>
              <div
                className="lakes-name-logoc-img-2"
                onClick={() => handleSortingClick("outflow")}
              >
                <span className="airport-all-span">Outflow</span>
                {selectedValue === "outflow" && sortOrder === "" && " ▼"}
                {selectedValue === "outflow" && sortOrder === "desc" && "▲"}
              </div>
              <div
                className="lakes-name-logoc-img-2"
                onClick={() => handleSortingClick("basin")}
              >
                <span className="airport-all-span">Basin</span>
                {selectedValue === "basin" && sortOrder === "" && " ▼"}
                {selectedValue === "basin" && sortOrder === "desc" && "▲"}
              </div>
            </div>
            {ContryDataSec ? (
              <>
                {sortedData.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="river_system-volum"
                  >
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">
                        {item.river_system}
                      </span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">{item.length_km}</span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">
                        {item.length_miles}
                      </span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">
                        {item.drainage_area}
                      </span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">
                        {item.average_discharge}
                      </span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">{item.outflow}</span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">{item.basin}</span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {ContryData.map((item) => (
                  <div
                    key={item.airport}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className="river_system-volum"
                  >
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">
                        {item.river_system}
                      </span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">{item.length_km}</span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">
                        {item.length_miles}
                      </span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">
                        {item.drainage_area}
                      </span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">
                        {item.average_discharge}
                      </span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">{item.outflow}</span>
                    </div>
                    <div className="lakes-system-logoc-img-1">
                      <span className="main-airpot-span">{item.basin}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default ListOfRiverSystems;
