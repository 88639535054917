import React from "react";
import "../MercuryOveviwe/MercuryOveviwe.css";
import OortClou2 from "../../Images/Mercuryhas2.jpg";
import OortClou3 from "../../Images/Mercuryhas1.webp";
import OortClou4 from "../../Images/Mercuryhas3.jpg";
const MercuryOveviwe = () => {
  return (
    <>
      <section className="SolarSystemOverview-main-sec">
        <div className="SolarSystemOverview-main-div mt-5">
          <div className="Overview-contain-main-div mb-5">
            <div>
              <span className="Overview-soler-span">
                Planet Mercury Overview{" "}
              </span>
            </div>
            <div className="text-justyfi-ka-liya">
              <div className="">
                <span className="asteroids-main-span">
                  Mercury is the first planet from the Sun and the smallest in
                  the Solar System. It is a terrestrial planet with a heavily
                  cratered surface due to overlapping impact events.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  These features are well preserved since the planet has no
                  geological activity and an extremely tenuous atmosphere called
                  an exosphere.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Despite being the smallest planet in the Solar System, Mercury
                  is dense enough to have roughly the same surface gravity as
                  Mars. Mercury has a dynamic magnetic field with a strength
                  about 1% of that of Earth's and has no natural satellites.
                </span>
              </div>
              <div className="mt-4">
                <span className="asteroids-main-span">
                  Mercury orbits the Sun in a 3:2 spin–orbit resonance, meaning
                  that relative to the background stars, it rotates on its axis
                  exactly three times for every two revolutions it makes around
                  the Sun.
                </span>
              </div>
              <div className="mt-4">
                <div className="mb-3">
                  <span className="Neptunian-soler-span">
                    How Mercury Got Its Name
                  </span>
                </div>
                <span className="asteroids-main-span">
                  The ancients knew Mercury by different names depending on
                  whether it was an evening star or a morning star. The ancient
                  Greeks had realized the two stars were one. They knew the
                  planet as Stilbon and Hermes, for its fleeting motion. The
                  Romans named the planet after the swift-footed Roman messenger
                  god, Mercury whom they equated with the Greek Hermes, because
                  it moves across the sky faster than any other planet.
                </span>
              </div>
            </div>
          </div>
          <div className="OortClou3-main-div">
            <img className="OortClou3-aminimg" src={OortClou2} alt="" />
            <img className="OortClou3-aminimg" src={OortClou3} alt="" />
            <img className="OortClou3-aminimg" src={OortClou4} alt="" />
          </div>
          <div className="mt-4 mb-5">
            <div className="mb-3">
              <span className="Neptunian-soler-span">Size and Distance</span>
            </div>
            <span className="asteroids-main-span">
              Mercury has a radius of 1,516 miles (2,440 kilometers), and it is
              a little more than 1/3 the width of Earth.
            </span>
            <span className="asteroids-main-span">
              From an average distance of 36 million miles (58 million
              kilometers), Mercury is 0.4 astronomical units away from the Sun.
              One astronomical unit (abbreviated as AU), is the distance from
              the Sun to Earth. From this distance, it takes sunlight 3.2
              minutes to travel from the Sun to Mercury.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default MercuryOveviwe;
